.hero-img-container {
    margin-top: -135px;
    padding: 20px;
}

.hero-img {
    height: 100%;
    width: 100%;
}

.hero-caption {
    position: absolute;
    top: 35%;
    left: 5%;
    z-index: 8;
}

.hero-caption h1 {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}

.hero-caption p {
    color: white;
    font-size: 24px;
}

.video-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: fit-content;
}

.masked {
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1853' height='825' viewBox='0 0 1853 825'%3E%3Cdefs%3E%3Cstyle%3E.cls-1 %7B fill-rule: evenodd; %7D%3C/style%3E%3C/defs%3E%3Cpath id='Color_Fill_1' data-name='Color Fill 1' class='cls-1' d='M394.674,0H1517.55c14.16,0,30.3-1.11,41.48,2.137,24.69,7.173,32.72,21.8,45.75,40.181q11.76,16.242,23.52,32.487c8.47,11.948,15.39,23.336,29.71,29.5,9.59,4.12,30.14,2.137,43.41,2.137h94.07c22.23,0,43.24-2.886,52.59,10.473,5.23,7.463,4.92,18.876,4.92,31.845V722.2c0,11.174.77,23.007-3.42,29.922-8.07,13.292-29.35,10.046-50.24,10.045H1016.4c-15.45,0-33.3-1.442-43.185,4.275-5.357,3.1-9.068,7.913-12.828,12.61l-17.1,19.022c-8.729,10.877-17.71,23.727-34.421,26.5H38.7c-11.259,0-21.42,1.1-28.649-3.42C-1.868,813.7,0,795.222,0,775.842V161.367c0-19.739-1.707-36.306,7.269-47.235,9.432-11.485,49.552-7.7,71.2-7.694l196.482-.214c21.417-4.017,29.111-17.342,39.553-32.059L339.514,39.54,353.2,19.663c6.255-8.057,17.319-14.013,28.221-17.312Z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1688.03 751.66'%3E%3Cpath class='fil0' d='M0.09 195.81l0 -70.4c0,0 -2.99,-28.47 26.3,-28.35 18.08,0.07 117.28,0.06 190.6,0.03l22.79 0c0,0 22.77,3.09 36.43,-15.12 13.66,-18.22 45.54,-63.75 45.54,-63.75 0,0 14.34,-18.21 42.56,-18.21l435.65 0 88.12 0 437.08 0 76.19 0 4.54 0c9.37,0.4 31.09,3.43 44.31,22.15l40.07 56.17 3.66 4.87c4.37,5.81 18.06,14.65 25.72,13.87l7.93 0c0.79,0.02 1.6,0.03 2.43,0.03l6.17 0 0 -0.03c19,0.02 98.77,0.1 125.46,-0 29.29,-0.12 26.3,28.35 26.3,28.35l0 70.4 0 475.9c0,27.32 -27.32,22.77 -27.32,22.77l-386.27 0 -369.71 0c-14.25,0 -21.18,5.53 -23.45,7.84l-31.94 36.36 -0 -0c-9.29,10.58 -18.71,12.43 -22.84,12.69l-2.16 0 -24.28 0 -25.81 0 -746.74 0c0,0 -27.32,4.55 -27.32,-22.77l0 -532.79z'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 100%;
}

.video-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #05d9ff99, #000000);
    z-index: 2;
    opacity: 0.8;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1853' height='825' viewBox='0 0 1853 825'%3E%3Cdefs%3E%3Cstyle%3E.cls-1 %7B fill-rule: evenodd; %7D%3C/style%3E%3C/defs%3E%3Cpath id='Color_Fill_1' data-name='Color Fill 1' class='cls-1' d='M394.674,0H1517.55c14.16,0,30.3-1.11,41.48,2.137,24.69,7.173,32.72,21.8,45.75,40.181q11.76,16.242,23.52,32.487c8.47,11.948,15.39,23.336,29.71,29.5,9.59,4.12,30.14,2.137,43.41,2.137h94.07c22.23,0,43.24-2.886,52.59,10.473,5.23,7.463,4.92,18.876,4.92,31.845V722.2c0,11.174.77,23.007-3.42,29.922-8.07,13.292-29.35,10.046-50.24,10.045H1016.4c-15.45,0-33.3-1.442-43.185,4.275-5.357,3.1-9.068,7.913-12.828,12.61l-17.1,19.022c-8.729,10.877-17.71,23.727-34.421,26.5H38.7c-11.259,0-21.42,1.1-28.649-3.42C-1.868,813.7,0,795.222,0,775.842V161.367c0-19.739-1.707-36.306,7.269-47.235,9.432-11.485,49.552-7.7,71.2-7.694l196.482-.214c21.417-4.017,29.111-17.342,39.553-32.059L339.514,39.54,353.2,19.663c6.255-8.057,17.319-14.013,28.221-17.312Z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1688.03 751.66'%3E%3Cpath class='fil0' d='M0.09 195.81l0 -70.4c0,0 -2.99,-28.47 26.3,-28.35 18.08,0.07 117.28,0.06 190.6,0.03l22.79 0c0,0 22.77,3.09 36.43,-15.12 13.66,-18.22 45.54,-63.75 45.54,-63.75 0,0 14.34,-18.21 42.56,-18.21l435.65 0 88.12 0 437.08 0 76.19 0 4.54 0c9.37,0.4 31.09,3.43 44.31,22.15l40.07 56.17 3.66 4.87c4.37,5.81 18.06,14.65 25.72,13.87l7.93 0c0.79,0.02 1.6,0.03 2.43,0.03l6.17 0 0 -0.03c19,0.02 98.77,0.1 125.46,-0 29.29,-0.12 26.3,28.35 26.3,28.35l0 70.4 0 475.9c0,27.32 -27.32,22.77 -27.32,22.77l-386.27 0 -369.71 0c-14.25,0 -21.18,5.53 -23.45,7.84l-31.94 36.36 -0 -0c-9.29,10.58 -18.71,12.43 -22.84,12.69l-2.16 0 -24.28 0 -25.81 0 -746.74 0c0,0 -27.32,4.55 -27.32,-22.77l0 -532.79z'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    z-index: 2;
}

.static-container {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    width: 98%;
    height: 100%;
    background: linear-gradient(to bottom right, #05d9ff99, #000000);
    z-index: 2;
    opacity: 0.8;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1853' height='825' viewBox='0 0 1853 825'%3E%3Cdefs%3E%3Cstyle%3E.cls-1 %7B fill-rule: evenodd; %7D%3C/style%3E%3C/defs%3E%3Cpath id='Color_Fill_1' data-name='Color Fill 1' class='cls-1' d='M394.674,0H1517.55c14.16,0,30.3-1.11,41.48,2.137,24.69,7.173,32.72,21.8,45.75,40.181q11.76,16.242,23.52,32.487c8.47,11.948,15.39,23.336,29.71,29.5,9.59,4.12,30.14,2.137,43.41,2.137h94.07c22.23,0,43.24-2.886,52.59,10.473,5.23,7.463,4.92,18.876,4.92,31.845V722.2c0,11.174.77,23.007-3.42,29.922-8.07,13.292-29.35,10.046-50.24,10.045H1016.4c-15.45,0-33.3-1.442-43.185,4.275-5.357,3.1-9.068,7.913-12.828,12.61l-17.1,19.022c-8.729,10.877-17.71,23.727-34.421,26.5H38.7c-11.259,0-21.42,1.1-28.649-3.42C-1.868,813.7,0,795.222,0,775.842V161.367c0-19.739-1.707-36.306,7.269-47.235,9.432-11.485,49.552-7.7,71.2-7.694l196.482-.214c21.417-4.017,29.111-17.342,39.553-32.059L339.514,39.54,353.2,19.663c6.255-8.057,17.319-14.013,28.221-17.312Z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1688.03 751.66'%3E%3Cpath class='fil0' d='M0.09 195.81l0 -70.4c0,0 -2.99,-28.47 26.3,-28.35 18.08,0.07 117.28,0.06 190.6,0.03l22.79 0c0,0 22.77,3.09 36.43,-15.12 13.66,-18.22 45.54,-63.75 45.54,-63.75 0,0 14.34,-18.21 42.56,-18.21l435.65 0 88.12 0 437.08 0 76.19 0 4.54 0c9.37,0.4 31.09,3.43 44.31,22.15l40.07 56.17 3.66 4.87c4.37,5.81 18.06,14.65 25.72,13.87l7.93 0c0.79,0.02 1.6,0.03 2.43,0.03l6.17 0 0 -0.03c19,0.02 98.77,0.1 125.46,-0 29.29,-0.12 26.3,28.35 26.3,28.35l0 70.4 0 475.9c0,27.32 -27.32,22.77 -27.32,22.77l-386.27 0 -369.71 0c-14.25,0 -21.18,5.53 -23.45,7.84l-31.94 36.36 -0 -0c-9.29,10.58 -18.71,12.43 -22.84,12.69l-2.16 0 -24.28 0 -25.81 0 -746.74 0c0,0 -27.32,4.55 -27.32,-22.77l0 -532.79z'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    z-index: 2;
}

.video-container-small {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 30px;
    /* Set the desired border radius */
}

.mobileview-mask {
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    /* Ensure the mask covers the entire container */
    mask-size: cover;
    /* Ensure the mask covers the entire container */
    -webkit-mask-position: center;
    /* Center the mask within the container */
    mask-position: center;
    /* Center the mask within the container */
    width: 100%;
    height: 100%;
    border-radius: 30px;
    /* Match the border radius of the container */
    overflow: hidden;
    /* Ensure the video respects the border radius */
}

.video-container-small::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #05d9ff99, #000000);
    z-index: 1;
    /* Lower the z-index to be behind the video */
    opacity: 0.8;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    /* Ensure the mask covers the entire container */
    mask-size: cover;
    /* Ensure the mask covers the entire container */
    -webkit-mask-position: center;
    /* Center the mask within the container */
    mask-position: center;
    /* Center the mask within the container */
    border-radius: 30px;
    /* Match the border radius of the container */
}

.video-container-small video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensure the video covers the entire masked area */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    /* Ensure the video is on top of the gradient overlay */
    border-radius: 30px;
    /* Match the border radius of the container */
}

.animated-bic {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.fadeInUp-bic {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
.masked iframe {
    width: 1880px;
    height: 1060px;
}
@keyframes fadeInUp {
    from {
        transform: translate3d(0, 70px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 70px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@media (min-width: 1400px) and (max-width: 1440px) {
    .hero-caption {
        top: 27%;
        left: 5%;
        z-index: 8;
    }
    .masked iframe {
        width: 1430px;
        height: 810px;
    }
}
@media (min-width: 1366px) and (max-width: 1399px) {
    .hero-caption {
        top: 27%;
        left: 5%;
    }
    .masked iframe {
        width: 1430px;
        height: 810px;
    }
}
@media (min-width: 1300px) and (max-width: 1365px) {
    .hero-caption {
        top: 27%;
        left: 5%;
    }
    .masked iframe {
        width: 1430px;
        height: 810px;
    }
}
@media (min-width: 1280px) and (max-width: 1299px) {
    .hero-caption {
        top: 27%;
        left: 5%;
    }
    .masked iframe {
        width: 1430px;
        height: 810px;
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .hero-caption {
        top: 22%;
        left: 5%;
        z-index: 9;
    }

    .hero-caption h1 {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .hero-img-container {
        margin-top: -125px;
        padding: 20px;
    }
    .masked iframe {
        width: 1250px;
        height: 710px;
    }
}

@media (min-width: 1024px) and (max-width: 1079px) {
    .hero-img-container {
        margin-top: -125px;
        padding: 20px;
    }
    .hero-caption {
        top: 20%;
        left: 5%;
        z-index: 9;
    }

    .hero-caption h1 {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .masked iframe {
        width: 1060px;
        height: 600px;
    }
}

@media (min-width: 810px) and (max-width: 1023px) {
    .hero-caption {
        top: 13%;
        left: 5%;
        z-index: 9;
    }

    .hero-caption h1 {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .hero-img-container {
        margin-top: -95px;
        padding: 20px;
    }
    .masked iframe {
        width: 800px;
        height: 450px;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .hero-caption {
        top: 13%;
        left: 5%;
        z-index: 9;
    }

    .hero-caption h1 {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .hero-img-container {
        margin-top: -95px;
        padding: 20px;
    }
    .masked iframe {
        width: 800px;
        height: 450px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {

    .video-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom right, #05d9ff99, #000000);
        z-index: 2;
        opacity: 0.8;
        mask-repeat: no-repeat;
        z-index: 2;
        display: none;
    }

    .hero-caption {
        top: 11%;
        left: 5%;
        z-index: 9;
        width: fit-content;
    }

    .video-container-small {
        margin-bottom: 200px;
    }

    .hero-img-container {
        padding: 20px;
        position: relative;
        top: 200px;
        margin-bottom: 100px;
        margin-top: -114px;
    }

    .hero-caption h1 {
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 15px;
        width: 250px;
    }

    .hero-caption p {
        color: white;
        font-size: 17px;
        width: 240px;
        position: relative;
        left: 15px;
    }

    .video-container-small {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 30px;
    }

    .mobileview-mask {
        -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-position: center;
        mask-position: center;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
    }

    .video-container-small::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom right, #05d9ff99, #000000);
        z-index: 1;
        opacity: 0.8;
        -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='162.56mm' height='67.7334mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 11963.74 4984.9' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cstyle type='text/css'%3E .fil0 %7Bfill:black%7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_x0020_1'%3E%3Cmetadata id='CorelCorpID_0Corel-Layer'/%3E%3Crect class='fil0' x='0' y='0' width='11963.74' height='4984.89' rx='578.79' ry='350.49'/%3E%3C/g%3E%3C/svg%3E");
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-position: center;
        mask-position: center;
        border-radius: 30px;
    }

    .video-container-small video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-radius: 30px;
    }

    iframe#youtube-player {
        width: 584px;
        height: 330px;
    }
    .masked {
        mask-image: none;
        mask-repeat: no-repeat;
        width: 100%;
    }
    .mobilevview-mask iframe {
        width: 450px;
        height: 260px;
    }
}