.read-more-container {
  padding-bottom: 100px;
  overflow-x: hidden;
  background-color: #31a6c4;
}

.liniear-gradiant {
  background-image: linear-gradient(rgba(25, 45, 56, 0.2) 0, rgba(25, 45, 56, 0.2) 10%, rgba(25, 45, 56, 0.4) 10%, rgba(25, 45, 56, 0.4) 20%, rgba(25, 45, 56, 0.6) 20%, rgba(25, 45, 56, 0.6) 30%, rgba(25, 45, 56, 0.8) 30%, rgba(25, 45, 56, 0.8) 40%, #192d38 40%, #192d38 100%);
  position: relative;
  bottom: -525px;
  height: 220px;
  width: 100%;
  z-index: 1;
  left: 0;
  display: block;
}

.lower-swiper-content-heading {
  text-align: center;
  -webkit-text-stroke: 1px #000;
  color: #fff;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}

.lower-swiper-content {
  background-color: #192d38;
  padding-top: 100px;
  padding-bottom: 110px;
  margin-top: -90px;
  margin-bottom: -100px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
  opacity: 0;
  transition: opacity 0.3s ease;
  align-items: end;
  display: flex;
}

.card:hover .overlay {
  opacity: 1;
}

.overlay-txt {
  color: white;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  transition: all ease 0.8s;
  padding: 18px;
  width: 440px;
  padding-bottom: 0;
}

.image-title {
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.title-arrow-wrapper {
  position: absolute;
  bottom: 5px;
  left: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.right-arrow-spotimg-swiper {
  width: 70px;
  margin-right: 30px;
}

.card:hover .image-title {
  margin-bottom: 90px;
  z-index: 11;
  transition: all ease 0.8s;
}

.card:hover .right-arrow-spotimg-swiper {
  z-index: 11;
}

.custom-prev {
  width: 35px;
  position: relative;
  top: -216px;
  z-index: 1111;
  left: 230px;
  cursor: pointer;
}

.custom-next {
  width: 35px;
  position: relative;
  top: -246px;
  z-index: 1111;
  left: 1630px;
  cursor: pointer;
}

.custom-swiper {
  height: 322px;
}

.custom-card {
  border: none;
  cursor: pointer;
}
.custom-card:hover {
  cursor: url("../../images/Read-More-Button.webp"),auto;
}

.read-more-img-wrapper-change {
  height: 352px;
  width: auto;
}

.title-arrow-wrapper-image {
  position: absolute;
  bottom: 23px;
  left: 15px;
  display: flex;
  justify-content: end;
  width: 100%;
}

.upper-swiper-content {
  text-align: center;
  margin-top: -130px;
}

.first-heading-upper {
  text-transform: uppercase;
  line-height: 1.7rem;
  margin-bottom: 24px;
  color: #bedae5;
  font-weight: bolder;
}

.second-heading-upper {
  line-height: 2.7rem;
  margin-bottom: 24px;
  color: #bedae5;
  font-weight: bolder;
  font-size: 45px;
  line-height: 1.2;
}

.btn-upper-swiper {
  padding: 10px;
  border: 2px solid #bedae5;
  background-color: transparent;
  border-radius: 4px;
  color: #bedae5;
  width: 190px;
}
.under-btn-txt-upper-swiper {
  line-height: 1.2;
  margin-bottom: 50px;
  color: #bedae5;
  font-weight: bolder;
  font-size: 33px;
  margin-top: 50px;
}

.small-left {
  width: 350px !important;
  margin-top: 60px;
}

.small-right {
  width: 350px !important;
  margin-top: 60px;
}

.large-slide {
  width: 572px !important;
}

.no-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  align-items: end;
  display: flex;
}

.no-image-title {
  display: none;
}

/* ---------------------- BEER IN CAN READMORE SWIPER SECTION ------------------------ */

@media (min-width: 1400px) and (max-width: 1440px) {
  .large-slide {
    width: 380px !important;
    margin-top: 15px;
  }

  .small-left {
    width: 300px !important;
    margin-top: 60px;
  }

  .small-right {
    width: 300px !important;
    margin-top: 60px;
  }

  .read-more-img-wrapper-change {
    height: 300px;
    width: auto;
  }

  .custom-prev {
    width: 35px;
    position: relative;
    top: -230px;
    z-index: 111;
    left: 230px;
    cursor: pointer;
  }

  .custom-next {
    width: 35px;
    position: relative;
    top: -265px;
    z-index: 491;
    left: 1200px;
    cursor: pointer;
  }

  .liniear-gradiant {
    bottom: -479px;
}

  .lower-swiper-content {
    background-color: #192d38;
    padding-top: 100px;
    padding-bottom: 110px;
    margin-top: -130px;
    margin-bottom: -100px;
}

.upper-swiper-content {
  text-align: center;
  margin-top: -150px;
}
}
@media (min-width: 1280px) and (max-width: 1399px) {
  .large-slide {
    width: 400px !important;
    margin-top: 15px;
}

  .small-left {
    width: 230px !important;
    margin-top: 60px;
  }

  .small-right {
    width: 230px !important;
    margin-top: 60px;
  }

  .read-more-img-wrapper-change {
    height: 300px;
    width: auto;
  }

  .custom-prev {
    width: 35px;
    position: relative;
    top: -254px;
    z-index: 111;
    left: 170px;
    cursor: pointer;
  }

  .custom-next {
    width: 35px;
    position: relative;
    top: -290px;
    z-index: 491;
    left: 1140px;
    cursor: pointer;
}

.liniear-gradiant {
  bottom: -522px;
  height: 150px;
}

  .lower-swiper-content {
    background-color: #192d38;
    padding-top: 100px;
    padding-bottom: 110px;
    margin-top: -200px;
    margin-bottom: -100px;
  }

  .upper-swiper-content {
    text-align: center;
    margin-top: -70px;
  }
}

@media (min-width: 1080px) and (max-width: 1279px) {
  .large-slide {
    width: 520px !important;
    margin-top: 15px;
  }

  .small-left {
    width: 250px !important;
    margin-top: 60px;
  }

  .small-right {
    width: 250px !important;
    margin-top: 60px;
  }

  .read-more-img-wrapper-change {
    height: 300px;
    width: auto;
  }

  .custom-prev {
    width: 35px;
    position: relative;
    top: -247px;
    z-index: 111;
    left: 170px;
    cursor: pointer;
  }

  .custom-next {
    width: 35px;
    position: relative;
    top: -280px;
    z-index: 491;
    left: 860px;
    cursor: pointer;
  }

  .liniear-gradiant {
    bottom: -512px;
    height: 150px;
}

  .lower-swiper-content {
    background-color: #192d38;
    padding-top: 100px;
    padding-bottom: 110px;
    margin-top: -200px;
    margin-bottom: -100px;
  }

  .upper-swiper-content {
    text-align: center;
    margin-top: -70px;
  }
  .second-heading-upper {
    line-height: 40px;
    margin-bottom: 24px;
    color: #bedae5;
    font-weight: bolder;
    font-size: 40px;
}
}
@media (min-width: 1024px) and (max-width: 1079px) {
  .large-slide {
    width: 520px !important;
    margin-top: 15px;
  }

  .small-left {
    width: 230px !important;
    margin-top: 60px;
  }

  .small-right {
    width: 230px !important;
    margin-top: 60px;
  }

  .read-more-img-wrapper-change {
    height: 300px;
    width: auto;
  }

  .custom-prev {
    width: 35px;
    position: relative;
    top: -254px;
    z-index: 111;
    left: 170px;
    cursor: pointer;
  }

  .custom-next {
    width: 35px;
    position: relative;
    top: -290px;
    z-index: 491;
    left: 840px;
    cursor: pointer;
  }

  .liniear-gradiant {
    bottom: -522px;
    height: 150px;
}

  .lower-swiper-content {
    background-color: #192d38;
    padding-top: 100px;
    padding-bottom: 110px;
    margin-top: -200px;
    margin-bottom: -100px;
  }

  .upper-swiper-content {
    text-align: center;
    margin-top: -70px;
  }
}

@media (min-width: 810px) and (max-width: 1023px) {
  .large-slide {
    width: 470px !important;
    margin-top: 15px;
  }

  .small-left {
    width: 190px !important;
    margin-top: 90px;
    margin-left: -44px;
}

  .small-right {
    width: 190px !important;
    margin-top: 90px;
  }

  .read-more-img-wrapper-change {
    height: 300px;
    width: auto;
  }

  .custom-prev {
    width: 35px;
    position: relative;
    top: -268px;
    z-index: 111;
    left: 60px;
    cursor: pointer;
  }

  .custom-next {
    width: 35px;
    position: relative;
    top: -303px;
    z-index: 491;
    left: 713px;
    cursor: pointer;
  }

  .liniear-gradiant {
    bottom: -601px;
    height: 150px;
}
.lower-swiper-content {
  background-color: #192d38;
  padding-top: 100px;
  padding-bottom: 110px;
  margin-top: -200px;
  margin-bottom: -160px;
}

  .upper-swiper-content {
    text-align: center;
    margin-top: -70px;
    padding: 15px;
  }
  .lower-swiper-content-heading {
    text-align: center;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.read-more-container {
  padding-bottom: 100px;
  overflow-x: hidden;
  background-color: #31a6c4;
  overflow-y: hidden;
}
.second-heading-upper {
  line-height: 2.7rem;
  margin-bottom: 24px;
  color: #bedae5;
  font-weight: bolder;
  font-size: 40px;
  line-height: 1.2;
}
}


@media (min-width: 768px) and (max-width: 809px) {
  .large-slide {
    width: 470px !important;
    margin-top: 15px;
  }

  .small-left {
    width: 190px !important;
    margin-top: 90px;
    margin-left: -44px;
}

  .small-right {
    width: 190px !important;
    margin-top: 90px;
  }

  .read-more-img-wrapper-change {
    height: 300px;
    width: auto;
  }

  .custom-prev {
    width: 35px;
    position: relative;
    top: -268px;
    z-index: 111;
    left: 60px;
    cursor: pointer;
  }

  .custom-next {
    width: 35px;
    position: relative;
    top: -303px;
    z-index: 491;
    left: 713px;
    cursor: pointer;
  }

  .liniear-gradiant {
    bottom: -598px;
    height: 150px;
}
.lower-swiper-content {
  background-color: #192d38;
  padding-top: 100px;
  padding-bottom: 110px;
  margin-top: -200px;
  margin-bottom: -160px;
}

  .upper-swiper-content {
    text-align: center;
    margin-top: -70px;
    padding: 15px;
  }
  .lower-swiper-content-heading {
    text-align: center;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.read-more-container {
  padding-bottom: 100px;
  overflow-x: hidden;
  background-color: #31a6c4;
  overflow-y: hidden;
}
.second-heading-upper {
  line-height: 2.7rem;
  margin-bottom: 24px;
  color: #bedae5;
  font-weight: bolder;
  font-size: 40px;
  line-height: 1.2;
}
}
@media (min-width: 600px) and (max-width: 767px){
  .upper-swiper-content {
    text-align: center;
    margin-top: -70px;
    padding: 20px;
}
.read-more-container {
  padding-bottom: 100px;
  overflow-x: hidden;
  background-color: #31a6c4;
  overflow: hidden;
  display: none;
}
}
@media (min-width: 425px) and (max-width: 599px){
  .read-more-container {
    padding-bottom: 100px;
    overflow-x: hidden;
    background-color: #31a6c4;
    overflow: hidden;
    display: none;
}
  .upper-swiper-content {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
}
.large-slide {
  width: 370px !important;
  margin-top: 15px;
  margin-left: 21px;
}
.liniear-gradiant {
  bottom: -642px;
  height: 150px;
  display: none;
}
.custom-prev{
  display: none;
}
.custom-next{
  display: none;
}
.lower-swiper-content-heading {
  text-align: center;
  -webkit-text-stroke: 1px #000;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
  top: 75px;
}
.read-more-container {
  padding-bottom: 100px;
  overflow-x: hidden;
  background-color: #31a6c4;
  overflow: hidden;
}
.image-title {
  color: white;
  font-size: 28px;
  font-weight: bold;
}
.right-arrow-spotimg-swiper {
  width: 45px;
  margin-right: 35px;
}
}
@media (min-width: 375px) and (max-width: 424px){
  .read-more-container {
    padding-bottom: 100px;
    overflow-x: hidden;
    background-color: #31a6c4;
    overflow: hidden;
    display: none;
}
  .upper-swiper-content {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
}
.large-slide {
  width: 326px !important;
  margin-top: 15px;
  margin-left: 21px;
}
.liniear-gradiant {
  bottom: -642px;
  height: 150px;
  display: none;
}
.custom-prev{
  display: none;
}
.custom-next{
  display: none;
}
.lower-swiper-content-heading {
  text-align: center;
  -webkit-text-stroke: 1px #000;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
  top: 75px;
}
.read-more-container {
  padding-bottom: 100px;
  overflow-x: hidden;
  background-color: #31a6c4;
  overflow: hidden;
}
.image-title {
  color: white;
  font-size: 28px;
  font-weight: bold;
}
.right-arrow-spotimg-swiper {
  width: 45px;
  margin-right: 35px;
}
}
@media (min-width: 320px) and (max-width: 374px) {
  .read-more-container {
    padding-bottom: 100px;
    overflow-x: hidden;
    background-color: #31a6c4;
    overflow: hidden;
    display: none;
}
  .upper-swiper-content {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
}
.large-slide {
  width: 270px !important;
  margin-top: 15px;
  margin-left: 21px;
}
.liniear-gradiant {
  bottom: -642px;
  height: 150px;
  display: none;
}
.custom-prev{
  display: none;
}
.custom-next{
  display: none;
}
.lower-swiper-content-heading {
  text-align: center;
  -webkit-text-stroke: 1px #000;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
  top: 75px;
}
.read-more-container {
  padding-bottom: 100px;
  overflow-x: hidden;
  background-color: #31a6c4;
  overflow: hidden;
}
.image-title {
  color: white;
  font-size: 28px;
  font-weight: bold;
}
.right-arrow-spotimg-swiper {
  width: 45px;
  margin-right: 35px;
}
}