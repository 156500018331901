.plusmasking-container{
    max-width: 1600px;
    margin-top: -122px;
}

.plus-masking-container{
    padding: 20px;
}
.plus-video-container {
    position: relative;
    width: 814px !important;
    overflow: hidden;
}
.masked-plus {
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9574.7 5390.35'%3E%3Cpath class='fil0' d='M-0 3837.87l1958.92 0 0 -2485.28 -1958.92 0 0 2485.28zm7615.78 -193.22l1958.92 0 0 -1872.3 -1958.92 0 0 1872.3zm-1997.23 786.23l1958.92 0 0 -3431.44 -1958.92 0 0 3431.44zm-1997.22 959.47l1958.92 0 0 -2352.04 -1958.92 0 0 2352.04zm0 -2392.01l1958.92 0 0 -2998.34 -1958.92 0 0 2998.34zm-1624.1 1645.76l1585.79 0 0 -2232.09 0 -2078.85 -1585.79 0 0 2078.85 0 2232.09z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9574.7 5390.35'%3E%3Cpath class='fil0' d='M-0 3837.87l1958.92 0 0 -2485.28 -1958.92 0 0 2485.28zm7615.78 -193.22l1958.92 0 0 -1872.3 -1958.92 0 0 1872.3zm-1997.23 786.23l1958.92 0 0 -3431.44 -1958.92 0 0 3431.44zm-1997.22 959.47l1958.92 0 0 -2352.04 -1958.92 0 0 2352.04zm0 -2392.01l1958.92 0 0 -2998.34 -1958.92 0 0 2998.34zm-1624.1 1645.76l1585.79 0 0 -2232.09 0 -2078.85 -1585.79 0 0 2078.85 0 2232.09z'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 100%;
}
.plus-video-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.8;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9574.7 5390.35'%3E%3Cpath class='fil0' d='M-0 3837.87l1958.92 0 0 -2485.28 -1958.92 0 0 2485.28zm7615.78 -193.22l1958.92 0 0 -1872.3 -1958.92 0 0 1872.3zm-1997.23 786.23l1958.92 0 0 -3431.44 -1958.92 0 0 3431.44zm-1997.22 959.47l1958.92 0 0 -2352.04 -1958.92 0 0 2352.04zm0 -2392.01l1958.92 0 0 -2998.34 -1958.92 0 0 2998.34zm-1624.1 1645.76l1585.79 0 0 -2232.09 0 -2078.85 -1585.79 0 0 2078.85 0 2232.09z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9574.7 5390.35'%3E%3Cpath class='fil0' d='M-0 3837.87l1958.92 0 0 -2485.28 -1958.92 0 0 2485.28zm7615.78 -193.22l1958.92 0 0 -1872.3 -1958.92 0 0 1872.3zm-1997.23 786.23l1958.92 0 0 -3431.44 -1958.92 0 0 3431.44zm-1997.22 959.47l1958.92 0 0 -2352.04 -1958.92 0 0 2352.04zm0 -2392.01l1958.92 0 0 -2998.34 -1958.92 0 0 2998.34zm-1624.1 1645.76l1585.79 0 0 -2232.09 0 -2078.85 -1585.79 0 0 2078.85 0 2232.09z'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    z-index: 2;
}
.heading-plus-masking{
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.heading-plus-masking-p{
    font-size: 21px;
    line-height: 28px;
    width: 32vw;
    color: rgb(61, 61, 61);
    font-weight: 400;
}

@media (min-width: 1400px) and (max-width: 1440px) {
    .plus-video-container {
        position: relative;
        width: 670px !important;
        overflow: hidden;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -130px;
    }
    .second-background {
        width: 100%;
        background-position-x: center;
        background-position-y: -40px;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
@media (min-width: 1366px) and (max-width: 1399px) {
    .plus-video-container {
        position: relative;
        width: 670px !important;
        overflow: hidden;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -150px;
    }
    .second-background {
        width: 100%;
        background-position-x: center;
        background-position-y: -40px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .animated-video-container video {
        height: 230px;
    }
}
@media (min-width: 1280px) and (max-width: 1365px) {
    .heading-plus-masking {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .plus-video-container {
        position: relative;
        width: 560px !important;
        overflow: hidden;
    }
    .masked-plus {
        mask-repeat: no-repeat;
        width: 100%;
        background-color: white;
        border: 1px solid white;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -80px;
        margin-bottom: -170px;
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .heading-plus-masking {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .plus-video-container {
        position: relative;
        width: 560px !important;
        overflow: hidden;
    }
    .masked-plus {
        mask-repeat: no-repeat;
        width: 100%;
        background-color: white;
        border: 1px solid white;
    }
    .plusmasking-container{
        max-width: 1600px;
        margin-top: -200px;
    }
    .animated-video-container video{
        height: 300px;
    }
    .animated-video-container{
        margin-top: -70px;
    }
}
@media (min-width: 1024px) and (max-width: 1079px) {
    .heading-plus-masking {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .masked-plus {
        mask-repeat: no-repeat;
        width: 100%;
        background-color: white;
        border: 1px solid white;
    }
    .plus-video-container {
        position: relative;
        width: 560px !important;
        overflow: hidden;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: 0px;
    }
}
@media (min-width: 810px) and (max-width: 1023px) {
    .plus-masking-row{
        flex-direction: column;
    }
    .plus-video-container {
        position: relative;
        width: 630px !important;
        overflow: hidden;
        left: 60px;
        top: 50px;
    }
    .plus-right-side {
        width: 100%;
        padding: 0px 20px;
    }
    .heading-plus-masking-p {
        font-size: 21px;
        line-height: 28px;
        width: 100%;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .heading-plus-masking {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
}


@media (min-width: 768px) and (max-width: 809px){
    .plus-masking-row{
        flex-direction: column;
    }
    .plus-video-container {
        position: relative;
        width: 600px !important;
        overflow: hidden;
        left: 55px;
    }
    .plus-right-side {
        width: 100%;
        padding: 0px 20px;
        position: relative;
        top: -70px;
    }
    .heading-plus-masking-p {
        font-size: 21px;
        line-height: 28px;
        width: 100%;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .heading-plus-masking {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -62px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .plus-masking-row{
        flex-direction: column;
    }
    .plus-video-container {
        position: relative;
        width: 334px !important;
        overflow: hidden;
        left: 10px;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -92px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .plus-right-side {
        width: 100%;
        margin-top: 0px;
    }
    .heading-plus-masking-p {
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .heading-plus-masking {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
}
@media (min-width: 393px) and (max-width: 424px) {
     .plus-masking-row{
        flex-direction: column;
    }
    .plus-video-container {
        position: relative;
        width: 334px !important;
        overflow: hidden;
        margin-left: -8px;
    }
    .heading-plus-masking-p {
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .heading-plus-masking {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -92px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .plus-right-side {
        width: 100%;
        margin-top: 10px;
        padding: 0px 10px;
    }
}
@media (min-width: 375px) and (max-width: 392px) {
    .plus-masking-row{
        flex-direction: column;
    }
    .plus-video-container {
        position: relative;
        width: 305px !important;
        overflow: hidden;
    }
    .plus-right-side {
        width: 100%;
        margin-top: 0px;
        padding: 0px 15px;
    }
    .heading-plus-masking-p {
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .heading-plus-masking {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -90px;
        padding-left: 15px;
    }
}
@media (min-width: 360px) and (max-width: 374px) {
    .plus-masking-row{
        flex-direction: column;
    }
    .plus-video-container {
        position: relative;
        width: 280px !important;
        overflow: hidden;
    }
    .heading-plus-masking-p {
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .heading-plus-masking {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -90px;
        padding-left: 15px;
    }
    .plus-right-side {
        width: 100%;
        margin-top: -20px;
        padding: 0px 10px;
    }
}
@media (min-width: 300px) and (max-width: 359px) {
    .plus-masking-row{
        flex-direction: column;
    }
    .plus-video-container {
        position: relative;
        width: 245px !important;
        overflow: hidden;
    }
    .heading-plus-masking-p {
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .heading-plus-masking {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .plusmasking-container {
        max-width: 1600px;
        margin-top: -90px;
        padding-left: 15px;
    }
    .plus-right-side {
        width: 100%;
        margin-top: -40px;
        padding: 0px 10px;
    }
}