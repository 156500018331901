.viewport {
  margin: 0px;
  width: 100vw;
  position: fixed;
}

::-webkit-scrollbar {
  width: 0.6vw;
  border-radius: 0px;
  background: #e7e7e7;
}

::-webkit-scrollbar-thumb {
  background-color: #2ba4c3;
  border-radius: 10px;
  border-radius: 10px;
}

.scroll-to-top {
  background-color: white;
  border-radius: 100px;
  bottom: 20px;
  color: var(--white);
  height: 50px;
  position: fixed;
  right: 20px;
  transition: all 0.3s;
  width: 50px;
  z-index: 9991;
  transform: rotateX(180deg);
  box-shadow: 3px 3px 5px rgba(71, 81, 93, 0.301961),
    -3px -3px 7px rgba(255, 255, 255, 0.501961);
}
.scroll-to-top img {
  width: 23px;
  height: 13px !important;
  position: relative;
  left: 14px;
  bottom: -13px;
}
.wrapper-short-cuts {
  border-radius: 100px;
  bottom: 90px;
  height: 50px;
  position: fixed;
  right: 0px;
  width: 50px;
  z-index: 9991;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: rotateX(180deg);
}

.wrapper-short-cuts.open {
  visibility: visible;
  opacity: 1;
  transform: rotateX(180deg);
  transition: all 0.3s ease-in-out;
}

.short-cuts-one,
.short-cuts-two,
.short-cuts-three {
  background-color: #2ba4c3;
  border-radius: 100px;
  height: 50px;
  position: fixed;
  right: 20px;
  width: 50px;
  z-index: 9991;
  box-shadow: 3px 3px 5px rgba(71, 81, 93, 0.301961),
    -3px -3px 7px rgba(255, 255, 255, 0.501961);
  transform: rotateX(180deg);
  transition: all 0.3s;
}

.short-cuts-one {
  bottom: -190px;
}

.short-cuts-two {
  bottom: -130px;
}

.short-cuts-three {
  bottom: -70px;
}

.short-cuts-one img,
.short-cuts-two img,
.short-cuts-three img {
  width: 25px;
  height: 25px !important;
  position: relative;
  left: 12px;
  bottom: -12px;
}

.short-cuts {
  background-color: #2ba4c3;
  border-radius: 100px;
  bottom: 90px;
  height: 50px;
  position: fixed;
  right: 20px;
  transition: all 0.3s;
  width: 50px;
  z-index: 9991;
  box-shadow: 3px 3px 5px rgba(71, 81, 93, 0.301961),
    -3px -3px 7px rgba(255, 255, 255, 0.501961);
}

.short-cuts img {
  width: 33px;
  height: 33px !important;
  position: relative;
  left: 8px;
  bottom: -10px;
}

.cross-short-cuts {
  background-color: #2ba4c3;
  border-radius: 100px;
  bottom: 90px;
  height: 50px;
  position: fixed;
  right: 20px;
  transition: all 0.3s;
  width: 50px;
  z-index: 9991;
  box-shadow: 3px 3px 5px rgba(71, 81, 93, 0.301961),
    -3px -3px 7px rgba(255, 255, 255, 0.501961);
}

.cross-short-cuts img {
  width: 25px;
  height: 25px !important;
  position: relative;
  left: 12px;
  bottom: -12px;
  transform: rotate(45deg);
  filter: invert(1);
}
