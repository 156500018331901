.resale-container{

}
.rapid-resale-main{
    margin-top: -130px;
    overflow-x: hidden;
}
.rapid-resale-main-img{
    padding: 20px;
    /* height: 70vh; */
    width: 100%;
}
.image-wrapper-resale img {
    height: 30px;
    width: 30px;
}
.right-bullet svg {
    height: 25px;
    width: 25px;
    margin-right: 10px;
}
.points-wrapper {
    display: flex;
}
.rapid-resale-first-section{
    top: -280px;
    left: 120px;
    position: relative;
    display: flex;
}
.resale-rapid-heading{
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}

.image-wrapper-resale{
    width: 30px;
}
.resale-1-wrapper {
    display: flex;
    background-color: #2ba4c3;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    top: -50px;
    left: 70px;
}
.resale-2-wrapper {
    display: flex;
    background-color: #2ba4c3;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    top: -20px;
    left: 10px;
}
.resale-3-wrapper {
    display: flex;
    background-color: #2ba4c3;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    left: 10px;
    top: 10px;
}
.resale-4-wrapper {
    display: flex;
    background-color: #2ba4c3;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    bottom: -40px;
    left: 70px;
}
.resale-5-wrapper {
    display: flex;
    background-color: #2ba4c3;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    top: -50px;
    right: 70px;
}
.resale-6-wrapper {
    display: flex;
    background-color: #2ba4c3;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    right: 10px;
    top: -20px;
}
.resale-7-wrapper {
    display: flex;
    background-color: #2ba4c3;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    top: 10px;
    right: 10px;
}
.resale-8-wrapper {
    display: flex;
    background-color: #2ba4c3;
    padding: 20px;
    border-radius: 50px;
    margin: 10px;
    position: relative;
    bottom: -40px;
    right: 70px;
}
.resale-txt-p {
    color: white;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    text-align: left;
    width: 300px;
    margin-top: 6px;
    margin-bottom: 0px;
    padding-left: 10px;
}
.round-bg-color {
    background-color: white;
    padding: 20px;
    border-radius: 50px;
    box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: -5px 6px 0px 0px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
}
.custom-resale-container {
    display: flex;
    justify-content: space-between;
    background-image: url("../images/infocircle.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 630px 630px;
    height: 690px;
    padding-top: 100px;
    padding-bottom: 100px;
}
.resale-title-txt{
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.p-txt-resale{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: bold;
    line-height: 28px;
    text-align: left;
}
.resale-poi-txt {
    color: #3d3d3d;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.rapid-logo-resale{
    width: 350px;
}
@media (min-width: 1280px) and (max-width: 1399px) {
    .rapid-resale-first-section {
        top: -230px;
        left: 120px;
        position: relative;
        display: flex;
        width: 700px;
    }
    .rapid-resale-main {
        margin-top: -125px;
        overflow-x: hidden;
    }
    .resale-txt-p {
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 270px;
        margin-top: 6px;
        margin-bottom: 0px;
        padding-left: 10px;
    }
    .custom-resale-container {
        display: flex;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: -25px;
        left: 0px;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: -25px;
        right: 0px;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        bottom: -100px;
        left: 0px;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        bottom: -100px;
        right: 0px;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 10px;
        left: -70px;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        left: -70px;
        top: 60px;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        right: -70px;
        top: 10px;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 60px;
        right: -70px;
    }
}
@media (max-width: 1080px) and (max-width: 1279px) {
    .rapid-resale-first-section {
        top: -220px;
        left: 120px;
        position: relative;
        display: flex;
    }
    .rapid-resale-main {
        margin-top: -125px;
        overflow-x: hidden;
    }
    .resale-txt-p {
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 270px;
        margin-top: 6px;
        margin-bottom: 0px;
        padding-left: 10px;
    }
    .custom-resale-container {
        display: flex;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: -25px;
        left: -20px;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: -25px;
        right: -20px;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        bottom: -100px;
        left: -20px;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        bottom: -100px;
        right: -20px;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 10px;
        left: -70px;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        left: -70px;
        top: 60px;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        right: -70px;
        top: 10px;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 60px;
        right: -70px;
    }
    .resale-title-txt {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        margin-top: -60px;
    }
}
@media (min-width: 1024px) and (max-width: 1079px) {
    .rapid-resale-first-section {
        top: -220px;
        left: 120px;
        position: relative;
        display: flex;
    }
    .rapid-resale-main {
        margin-top: -104px;
        overflow-x: hidden;
    }
    .resale-txt-p {
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 270px;
        margin-top: 6px;
        margin-bottom: 0px;
        padding-left: 10px;
    }
    .custom-resale-container {
        display: flex;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 470px 470px;
        height: 690px;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 10px;
        left: 50px;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        width: -moz-fit-content;
        width: fit-content;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 5px;
        right: 40px;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        bottom: -150px;
        left: 40px;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        bottom: -150px;
        right: 30px;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 50px;
        left: -20px;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        left: -20px;
        top: 105px;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        right: -20px;
        top: 50px;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 5px;
        border-radius: 50px;
        margin: 10px;
        position: relative;
        top: 100px;
        right: -20px;
    }
    .round-bg-color {
        background-color: white;
        padding: 15px;
        border-radius: 50px;
        box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -webkit-box-shadow: -5px 6px 0px 0px rgba(0, 0, 0, 0.6);
        -o-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
    }
}

@media (min-width: 810px) and  (max-width: 1023px) {
    .rapid-resale-first-section {
        top: -158px;
        left: 70px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .rapid-resale-main {
        margin-top: -94px;
        overflow-x: hidden;
    }
    .resale-txt-p {
        color: white;
        font-size: 13px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 220px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 15px;
    }
    .custom-resale-container {
        display: flex;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-position: center 43px;
        background-size: 490px 490px;
        height: 690px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .round-bg-color {
        background-color: white;
        padding: 10px;
        border-radius: 105px;
        box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.6);
        -o-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        height: 50px;
        width: 50px;
        position: relative;
        top: 3px;
    }
    .image-wrapper-resale {
        width: 15px;
        position: relative;
        left: 7px;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 15px;
        left: 10px;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 50px;
        left: -50px;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        left: -50px;
        top: 100px;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        bottom: -140px;
        left: 0px;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 15px;
        right: -10px;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        right: -50px;
        top: 50px;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 100px;
        right: -50px;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        bottom: -140px;
        right: -20px;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .resale-title-txt {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.3;
        text-shadow: 1px 1px #b8bfd6;
        margin-top: -50px;
        margin-left: -35px;
    }
    .p-txt-resale {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        margin-left: -35px;
    }
    .custom-resale-poi{
        padding-top: 0px !important;
        padding-bottom: 50px !important;
        margin-top: -50px !important;
    }
    .rapid-logo-resale {
        width: 100px;
        position: relative;
        top: -60px;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .rapid-resale-first-section {
        top: -150px;
        left: 70px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .rapid-resale-main {
        margin-top: -94px;
        overflow-x: hidden;
    }
    .resale-txt-p {
        color: white;
        font-size: 13px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 220px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 15px;
    }
    .custom-resale-container {
        display: flex;
        justify-content: space-between;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 490px 490px;
        height: 690px;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .round-bg-color {
        background-color: white;
        padding: 10px;
        border-radius: 105px;
        box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.6);
        -o-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        height: 50px;
        width: 50px;
        position: relative;
        top: 3px;
    }
    .image-wrapper-resale {
        width: 15px;
        position: relative;
        left: 7px;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 15px;
        left: 10px;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 50px;
        left: -30px;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        left: -30px;
        top: 100px;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        bottom: -140px;
        left: 0px;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 15px;
        right: -10px;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        right: -30px;
        top: 50px;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        top: 100px;
        right: -30px;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: relative;
        bottom: -140px;
        right: -20px;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .resale-title-txt {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.3;
        text-shadow: 1px 1px #b8bfd6;
        margin-top: -50px;
        margin-left: -15px;
    }
    .p-txt-resale {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        margin-left: -15px;
    }
    .custom-resale-poi{
        padding-top: 0px !important;
        padding-bottom: 50px !important;
        margin-top: -50px !important;
    }

}

@media (min-width: 425px) and (max-width: 767px) {
    .rapid-resale-main {
        margin-top: 86px;
        overflow-x: hidden;
    }
    .rapid-resale-main-img {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: -10px;
    }
    .rapid-resale-first-section {
        top: 0px;
        left: 20px;
        position: relative;
        display: flex;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 300px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-txt-p {
        color: white;
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 290px;
        margin-top: 6px;
        margin-bottom: 0px;
        padding-left: 20px;
    }
    .resale-title-txt {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding: 0px 12px;
        margin-top: 0px;
    }
    .p-txt-resale {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        padding: 0px 12px;
    }
    .resale-poi-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 300px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
        margin: 50px 0px;
    }
    .custom-resale-poi{
        padding-bottom: 0px !important;
        padding-top: 100px !important;
    }
    .rapid-logo-resale {
       display: none;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .rapid-resale-main {
        margin-top: 86px;
        overflow-x: hidden;
    }
    .rapid-resale-main-img {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: -10px;
    }
    .rapid-resale-first-section {
        top: 0px;
        left: 20px;
        position: relative;
        display: flex;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 300px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        justify-content: center;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 10px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-txt-p {
        color: white;
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 240px;
        margin-top: 6px;
        margin-bottom: 0px;
        padding-left: 20px;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .resale-title-txt {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding: 0px 12px;
        margin-top: 0px;
    }
    .p-txt-resale {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        padding: 0px 12px;
    }
    .resale-poi-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 300px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
        margin: 50px 0px;
    }
    .custom-resale-poi{
        padding-bottom: 0px !important;
        padding-top: 100px !important;
    }
    .rapid-logo-resale {
        display: none;
     }
}
@media (min-width: 360px) and (max-width: 374px) {
    .rapid-resale-main {
        margin-top: 86px;
        overflow-x: hidden;
    }
    .rapid-resale-main-img {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: -10px;
    }
    .rapid-resale-first-section {
        top: 0px;
        left: 20px;
        position: relative;
        display: flex;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 300px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px 25px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-txt-p {
        color: white;
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 220px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 6px;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .round-bg-color {
        background-color: white;
        padding: 10px;
        border-radius: 50px;
        box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -webkit-box-shadow: -5px 6px 0px 0px rgba(0, 0, 0, 0.6);
        -o-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        position: relative;
        height: 50px;
        width: 50px;
    }
    .image-wrapper-resale {
        width: 15px;
        position: relative;
        left: 5px;
    }
    .resale-title-txt {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding: 0px 12px;
        margin-top: 0px;
    }
    .p-txt-resale {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        padding: 0px 12px;
    }
    .resale-poi-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 200px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
        margin: 0px 0px;
    }
    .custom-resale-poi{
        padding-bottom: 0px !important;
        padding-top: 100px !important;
    }
    .rapid-logo-resale {
        display: none;
     }
}
@media (min-width: 300px) and (max-width: 359px) {
    .rapid-resale-main {
        margin-top: 86px;
        overflow-x: hidden;
    }
    .rapid-resale-main-img {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: -10px;
    }
    .rapid-resale-first-section {
        top: 0px;
        left: 20px;
        position: relative;
        display: flex;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 300px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
    }
    .resale-1-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-2-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-3-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-4-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        justify-content: center;
    }
    .resale-5-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-6-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-7-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-8-wrapper {
        display: flex;
        background-color: #2ba4c3;
        padding: 10px;
        border-radius: 50px;
        margin: 5px;
        position: unset;
        flex-direction: row-reverse;
    }
    .resale-txt-p {
        color: white;
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        width: 220px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 6px;
    }
    .resale-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .round-bg-color {
        background-color: white;
        padding: 10px;
        border-radius: 50px;
        box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        -webkit-box-shadow: -5px 6px 0px 0px rgba(0, 0, 0, 0.6);
        -o-box-shadow: 10px -10px rgba(0, 0, 0, 0.6);
        position: relative;
        height: 50px;
        width: 50px;
    }
    .image-wrapper-resale {
        width: 15px;
        position: relative;
        left: 5px;
    }
    .resale-title-txt {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding: 0px 12px;
        margin-top: 0px;
    }
    .p-txt-resale {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        text-align: left;
        padding: 0px 12px;
    }
    .resale-poi-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-resale-container {
        display: flex;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 560px 560px;
        height: 690px;
        padding-top: 210px;
        padding-bottom: 200px;
        background-image: none;
        flex-direction: column;
        margin: 0px 0px;
    }
    .custom-resale-poi{
        padding-bottom: 0px !important;
        padding-top: 20px !important;
    }
    .rapid-logo-resale {
        display: none;
     }
}