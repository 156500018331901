.service-container-main {
  margin-top: -130px;
  overflow-x: hidden;
}

.services-img {
  padding: 20px;
  width: 100%;
}

.services-first-section {
  top: -310px;
  left: 120px;
  position: relative;
  display: flex;
  width: fit-content;
}

.services-rapid-heading {
  -webkit-text-stroke: 1px #000;
  color: #fff;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
}

.services-rapid-txt {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  position: relative;
}

.services-wrapper {
  max-width: 1800px;
}

.four-service-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid gray;
  cursor: pointer;
}

.service-title {
  font-size: 24px;
  font-weight: 600;
  color: gray;
}

.service-slide-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
}

.service-slide-one {
  background-image: url("../images/repair_maintanance.webp");
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  background-position: center;
  border-radius: 20px;
}

.service-slide-two {
  background-image: url("../images/services/diagnosticbanner.webp");
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  background-position: center;
  border-radius: 20px;
}

.service-slide-three {
  background-image: url("../images/repair_maintanance.webp");
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  background-position: center;
  border-radius: 20px;
}

.service-slide-four {
  background-image: url("../images/repair_maintanance.webp");
  background-repeat: no-repeat;
  width: 100%;
  height: 50vh;
  background-position: center;
  border-radius: 20px;
}
.service-slide-one::before {
  content: ""; /* Create a pseudo-element for the overlay */
  position: absolute;
  top: 1;
  left: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color with opacity */
  border-radius: 20px; /* Ensure overlay matches border radius */
  opacity: 0.9; /* Initially invisible */
  transition: background-color 0.5s ease-in-out 100ms; /* Apply transition effect */
}
.service-slide-two::before {
  content: ""; /* Create a pseudo-element for the overlay */
  position: absolute;
  top: 1;
  left: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color with opacity */
  border-radius: 20px; /* Ensure overlay matches border radius */
  opacity: 0.9; /* Initially invisible */
  transition: background-color 0.5s ease-in-out 100ms; /* Apply transition effect */
}
.service-slide-three::before {
  content: ""; /* Create a pseudo-element for the overlay */
  position: absolute;
  top: 1;
  left: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color with opacity */
  border-radius: 20px; /* Ensure overlay matches border radius */
  opacity: 0.9; /* Initially invisible */
  transition: background-color 0.5s ease-in-out 100ms; /* Apply transition effect */
}
.service-slide-four::before {
  content: ""; /* Create a pseudo-element for the overlay */
  position: absolute;
  top: 1;
  left: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color with opacity */
  border-radius: 20px; /* Ensure overlay matches border radius */
  opacity: 0.9; /* Initially invisible */
  transition: background-color 0.5s ease-in-out 100ms; /* Apply transition effect */
}

.select-service-title {
  color: #2ba4c3;
  font-size: 24px;
  font-weight: 600;
}

.service-title-wrapper {
  border-bottom: 2px solid #2ba4c3;
  z-index: 10;
}

.inner-content {
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.inner-content-heading {
  -webkit-text-stroke: 1px black;
  color: white;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
  z-index: 10;
}

.inner-content-title {
  color: white;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  position: relative;
  z-index: 10;
}

.inner-content-title-wrapper-one {
  position: relative;
  bottom: -100px;
  width: 1000px;
}

.inner-content-title-wrapper-two {
  position: relative;
  bottom: -200px;
  width: 1000px;
}

.inner-content-title-wrapper-three {
  position: relative;
  bottom: -200px;
  width: 1000px;
}

.inner-content-title-wrapper-four {
  position: relative;
  bottom: -200px;
  width: 1000px;
}

.services-main-title {
  -webkit-text-stroke: 1px white;
  color: black;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  margin-top: -90px;
  width: 1500px;
}

.goal-section {
  max-width: 1600px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.goal-title-txt-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.goal-heading {
  -webkit-text-stroke: 1px white;
  color: black;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  position: relative;
  z-index: 10;
}

.goal-txt-p {
  color: #3d3d3d;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  position: relative;
  z-index: 10;
  width: 800px;
}
.goal-img-wrapper {
  width: 90%;
  margin: 10px;
  overflow: hidden;
  border-radius: 30px;
  margin-left: 70px;
}
.goal-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.5s ease;
}
.goal-img-wrapper:hover .goal-img {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

@media (min-width: 1400px) and (max-width: 1440px) {
  .services-first-section {
    top: -280px;
    left: 120px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }
  .services-main-title {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: -90px;
    width: 1400px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
  .service-slide-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 1280px) and (max-width: 1399px) {
  .service-container-main {
    margin-top: -120px;
    overflow-x: hidden;
  }
  .services-first-section {
    top: -236px;
    left: 120px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }
  .services-main-title {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: -90px;
    width: 990px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
  .service-slide-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: -30px;
    width: 920px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -160px;
    width: 940px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -160px;
    width: 930px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -160px;
    width: 880px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: 600px;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 50px;
  }
}
@media (min-width: 1080px) and (max-width: 1279px) {
  .service-container-main {
    margin-top: -125px;
    overflow-x: hidden;
  }
  .services-first-section {
    top: -236px;
    left: 120px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }
  .services-main-title {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: -90px;
    width: 990px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
  .service-slide-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: -30px;
    width: 920px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -160px;
    width: 940px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -160px;
    width: 930px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -160px;
    width: 880px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: 600px;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 50px;
  }
}
@media (min-width: 1024px) and (max-width: 1079px) {
  .service-container-main {
    margin-top: -103px;
    overflow-x: hidden;
  }
  .services-first-section {
    top: -236px;
    left: 120px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }
  .services-main-title {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: -90px;
    width: 990px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
  .service-slide-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: -30px;
    width: 920px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -160px;
    width: 940px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -160px;
    width: 930px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -160px;
    width: 880px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: 600px;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 50px;
  }
}
@media (min-width: 810px) and (max-width: 1023px) {
  .service-container-main {
    margin-top: -97px;
    overflow-x: hidden;
  }
  .services-first-section {
    top: -186px;
    left: 70px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }
  .services-main-title {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: -90px;
    width: 750px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
  .service-slide-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: -110px;
    width: 640px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -230px;
    width: 640px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -230px;
    width: 640px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -220px;
    width: 640px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: 370px;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 50px;
  }
  .services-rapid-heading {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }
  .services-rapid-txt {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    position: relative;
  }
  .service-title-wrapper {
    border-bottom: 0px solid #2ba4c3;
    z-index: 10;
  }
  .inner-content-heading {
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
  }
  .service-slide-one {
    background-image: url("../images/repair_maintanance.webp");
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    background-position: top;
  }
  .goal-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    z-index: 10;
  }
}
@media (min-width: 768px) and (max-width: 809px) {
  .service-container-main {
    margin-top: -97px;
    overflow-x: hidden;
  }
  .services-first-section {
    top: -186px;
    left: 70px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }
  .services-main-title {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: -90px;
    width: 750px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
  }
  .service-slide-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: -20px;
    width: 600px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -160px;
    width: 600px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -160px;
    width: 600px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -160px;
    width: 600px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: 370px;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 50px;
  }
  .services-rapid-heading {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }
  .services-rapid-txt {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    position: relative;
  }
  .service-title-wrapper {
    border-bottom: 0px solid #2ba4c3;
    z-index: 10;
  }
  .inner-content-heading {
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
  }
  .service-slide-one {
    background-image: url("../images/repair_maintanance.webp");
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    background-position: top;
  }
  .goal-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    z-index: 10;
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .service-container-main {
    margin-top: 80px;
    overflow-x: hidden;
  }
  .services-img-mobile {
    border-radius: 35px;
    height: auto !important;
    margin-top: -20px;
    padding: 20px;
    width: 100%;
}
  .services-first-section {
    top: 0px;
    left: 20px;
    position: relative;
    display: flex;
    width: 370px;
}
.services-main-title {
  -webkit-text-stroke: 0px white;
  color: black;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  margin-top: 0px;
  width: 360px;
  margin-left: 10px;
  margin-right: 0px;
}
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }
  .service-slide-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: 0px;
    width: 300px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -200px;
    width: 300px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -200px;
    width: 300px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -200px;
    width: 300px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: 370px;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 20px;
  }
  .services-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}
  .services-rapid-txt {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    position: relative;
  }
  .service-title-wrapper {
    border-bottom: 0px solid #2ba4c3;
    z-index: 10;
  }
  .inner-content-heading {
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
  }
  .service-slide-one {
    background-image: url("../images/repair_maintanance.webp");
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
  }
  .goal-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    z-index: 10;
  }
  .select-service-title {
    color: #2ba4c3;
    font-size: 16px;
    font-weight: 600;
  }
  .service-title {
    font-size: 16px;
    font-weight: 600;
    color: gray;
  }
  .inner-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  .inner-content-title {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    position: relative;
    z-index: 10;
  }
  .goal-section {
    max-width: 1600px;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .service-slide-two {
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    background-position: center;
    border-radius: 20px;
    background-size: cover;
}
}
@media (min-width: 393px) and (max-width: 424px) {
  .service-container-main {
    margin-top: 80px;
    overflow-x: hidden;
  }
  .services-img-mobile {
    border-radius: 35px;
    height: auto !important;
    margin-top: -20px;
    padding: 20px;
    width: 100%;
}
  .services-first-section {
    top: 0px;
    left: 20px;
    position: relative;
    display: flex;
    width: 370px;
}
  .services-main-title {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: 0px;
    width: 330px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
  }
  .service-slide-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: 13px;
    width: 280px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -150px;
    width: 280px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -150px;
    width: 280px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -150px;
    width: 280px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: auto;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 15px;
  }
  .services-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}
  .services-rapid-txt {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    position: relative;
  }
  .service-title-wrapper {
    border-bottom: 0px solid #2ba4c3;
    z-index: 10;
  }
  .inner-content-heading {
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
  }
  .service-slide-one {
    background-image: url("../images/repair_maintanance.webp");
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
  }
  .goal-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    z-index: 10;
  }
  .select-service-title {
    color: #2ba4c3;
    font-size: 16px;
    font-weight: 600;
  }
  .service-title {
    font-size: 16px;
    font-weight: 600;
    color: gray;
  }
  .inner-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  .inner-content-title {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    position: relative;
    z-index: 10;
  }
  .goal-section {
    max-width: 1600px;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .service-slide-two {
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    background-position: center;
    border-radius: 20px;
    background-size: cover;
}
}
@media (min-width: 375px) and (max-width: 392px) {
  .service-container-main {
    margin-top: 80px;
    overflow-x: hidden;
  }
  .services-img-mobile {
    border-radius: 35px;
    height: auto !important;
    margin-top: -20px;
    padding: 20px;
    width: 100%;
}
  .services-first-section {
    top: 0px;
    left: 20px;
    position: relative;
    display: flex;
    width: 370px;
}
  .services-main-title {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: 0px;
    width: 330px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
  }
  .service-slide-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: 13px;
    width: 280px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -150px;
    width: 280px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -150px;
    width: 280px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -150px;
    width: 280px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: auto;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 15px;
  }
  .services-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}
  .services-rapid-txt {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    position: relative;
  }
  .service-title-wrapper {
    border-bottom: 0px solid #2ba4c3;
    z-index: 10;
  }
  .inner-content-heading {
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
  }
  .service-slide-one {
    background-image: url("../images/repair_maintanance.webp");
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
  }
  .goal-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    z-index: 10;
  }
  .select-service-title {
    color: #2ba4c3;
    font-size: 16px;
    font-weight: 600;
  }
  .service-title {
    font-size: 16px;
    font-weight: 600;
    color: gray;
  }
  .inner-content {
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  .inner-content-title {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    position: relative;
    z-index: 10;
  }
  .goal-section {
    max-width: 1600px;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .service-slide-two {
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    background-position: center;
    border-radius: 20px;
    background-size: cover;
}
}
@media (min-width: 300px) and (max-width: 374px) {
  .service-container-main {
    margin-top: 80px;
    overflow-x: hidden;
  }
  .services-img-mobile {
    border-radius: 35px;
    height: auto !important;
    margin-top: -20px;
    padding: 20px;
    width: 100%;
}
.services-first-section {
  top: 0px;
  left: 20px;
  position: relative;
  display: flex;
  width: 340px;
}
  .services-main-title {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
    margin-bottom: 50px;
    margin-top: 0px;
    width: 290px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .four-service-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid gray;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
  }
  .service-slide-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .inner-content-title-wrapper-one {
    position: relative;
    bottom: 15px;
    width: 250px;
  }
  .inner-content-title-wrapper-two {
    position: relative;
    bottom: -150px;
    width: 240px;
  }
  .inner-content-title-wrapper-three {
    position: relative;
    bottom: -150px;
    width: 240px;
  }
  .inner-content-title-wrapper-four {
    position: relative;
    bottom: -150px;
    width: 240px;
  }
  .goal-title-txt-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
  }
  .goal-txt-p {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 10;
    width: auto;
  }
  .goal-img-wrapper {
    width: 90%;
    margin: 10px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 10px;
  }
  .services-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}
  .services-rapid-txt {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    position: relative;
  }
  .service-title-wrapper {
    border-bottom: 0px solid #2ba4c3;
    z-index: 10;
  }
  .inner-content-heading {
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    z-index: 10;
  }
  .service-slide-one {
    background-image: url("../images/repair_maintanance.webp");
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    border-radius: 20px;
    background-position: center;
        background-size: cover;
  }
  .goal-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    position: relative;
    z-index: 10;
  }
  .select-service-title {
    color: #2ba4c3;
    font-size: 14px;
    font-weight: 600;
  }
  .service-title {
    font-size: 14px;
    font-weight: 600;
    color: gray;
  }
  .inner-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .inner-content-title {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    position: relative;
    z-index: 10;
  }
  .goal-section {
    max-width: 1600px;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .service-slide-two {
    background-repeat: no-repeat;
    width: 100%;
    height: 50vh;
    background-position: center;
    border-radius: 20px;
    background-size: cover;
}
}
