.exhibitions-container-main {
    margin-top: -130px;
    overflow-x: hidden;
}

.exhibitions-img {
    padding: 20px;
    width: 100%;
}

.exhibitions-first-container {
    top: -300px;
    left: 120px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
}

.exhibitions-rapid-heading {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}

.date-wrapper-upcoming {
    background-color: #2ba4c3;
    padding: 40px;
    align-items: center;
    border-radius: 20px;
    display: flex;
    text-align: center;
}

.date-details-wrapper {
    display: flex;
}
.exibition-banner-wrapper img{
    width: 100%;
}

.date-exhibition {
    color: white;
    font-size: 21px;
    font-weight: 700;
}

.upcoming-heading-main {
    color: #2ba4c3;
    font-size: 32px;
    font-weight: 500;
    width: fit-content;
}

.upcoming-exhibitions-container {
    max-width: 1500px;
}

.exhibition-heading-name {
    color: #2ba4c3;
    font-size: 32px;
    font-weight: 500;
}

.booth-txt {
    color: #5c6b8d;
    font-weight: 600;
    margin-bottom: 0;
}

.booth-name-txt {
    color: #2ba4c3;
    font-weight: 600;
}

.location-wrapper-exhibition {
    display: flex;
}

.location-img-exhibition {
    height: 30px !important;
    width: 30px;
}

.exhibition-details-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.upcoming-exhibitions-row {
    border: 1px solid #d3d7db;
    border-radius: 16px;
    padding: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.upcoming-middle-title {
    color: #5c6b8d;
    font-weight: 600;
}

.right-arrow-exhibition {
    height: 30px !important;
    width: 30px;
}

.upcoming-middle {
    border-left: 2px solid #d3d7db;
    border-right: 2px solid #d3d7db;
    padding: 10px 40px;
}

.right-arrow-exhibition-wrapper {
    background-color: white;
    padding: 17px;
    border-radius: 40px;
    display: flex;
    align-items: center;
}

.exhibition-link-website {
    display: flex;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    color: white;
    width: 200px;
    padding: 0px 20px;
}

.exhibition-btn {
    background-color: #2ba4c3;
    padding: 10px;
    border: 1px solid #2ba4c3;
    border-radius: 50px;
    display: flex;
    align-items: center;
}

.exibition-banner-wrapper {
    padding: 20px;
    width: 330px;
}

.last-upcoming {
    display: flex;
    align-items: center;
    justify-content: center;
}

.exhibition-btn:hover {
    background-color: white;
    border: 1px solid black;
}

.exhibition-btn:hover .exhibition-link-website {
    color: black;
    font-weight: normal;
}

.exhibition-btn:hover .right-arrow-exhibition-wrapper {
    background-color: #2ba4c3;
}

.exhibition-btn:hover .right-arrow-exhibition {
    filter: invert(1);
}

.past {
    background-color: #d3d7db;
    color: #d3d7db;
}

.past-color {
    color: #5c6b8d;
}

.past-opacity {
    opacity: 0.8;
}

.past-btn {
    background-color: #d3d7db;
    border: 1px solid #d3d7db;
    pointer-events: none;
}

.exhibition-mt-top {
    margin-top: -50px;
}
.mobile-view-second-wrapper {
    width: auto;
    display: contents;
}
@media (min-width: 1400px) and (max-width: 1440px) {
    .exhibitions-first-container {
        top: -250px;
        width: -moz-fit-content;
        width: fit-content;
    }

    .upcoming-exhibitions-container {
        max-width: 1350px;
    }
    .upcoming-middle {
        border-left: 2px solid #d3d7db;
        border-right: 2px solid #d3d7db;
        padding: 10px 10px;
    }
    .exhibition-heading-name {
        color: #2ba4c3;
        font-size: 30px;
        font-weight: 500;
    }
    
}

@media (min-width: 1280px) and (max-width:1399px) {
    .exhibitions-first-container {
        top: -250px;
        width: -moz-fit-content;
        width: fit-content;
    }

    .upcoming-exhibitions-container {
        max-width: 1230px;
    }

    .exhibition-heading-name {
        font-size: 26px;
    }

    .exhibition-link-website {
        font-size: 16px;
        width: 140px;
        padding: 0px 0px;
    }
}

@media (min-width: 1080px) and (max-width: 1279px) {
    .exhibitions-first-container {
        top: -210px;
        width: -moz-fit-content;
        width: fit-content;
    }

    .upcoming-exhibitions-container {
        max-width: 1030px;
    }

    .exhibition-heading-name {
        font-size: 21px;
    }

    .exhibition-link-website {
        font-size: 16px;
        width: 140px;
        padding: 0px 0px;
    }

    .upcoming-exhibitions-row {
        padding: 20px;
    }

    .upcoming-middle {
        width: 30%;
    }

    .last-upcoming {
        width: 25%;
    }

    .date-exhibition {
        font-size: 16px;
    }

    .date-wrapper-upcoming {
        padding: 30px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 12px;
    }

    .exhibition-btn {
        padding: 4px;
    }
}

@media (min-width: 1024px) and (max-width: 1079px) {
    .exhibitions-first-container {
        top: -210px;
        width: -moz-fit-content;
        width: fit-content;
    }

    .upcoming-exhibitions-container {
        max-width: 980px;
    }

    .exhibition-heading-name {
        font-size: 21px;
    }

    .exhibition-link-website {
        font-size: 16px;
        width: 140px;
        padding: 0px 0px;
    }

    .upcoming-exhibitions-row {
        padding: 20px;
    }

    .upcoming-middle {
        width: 30%;
    }

    .last-upcoming {
        width: 25%;
    }

    .date-exhibition {
        font-size: 16px;
    }

    .date-wrapper-upcoming {
        padding: 30px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 12px;
    }

    .exhibition-btn {
        padding: 4px;
    }
}

@media (min-width: 810px) and (max-width: 1023px) {
    .exhibitions-first-container {
        top: -163px;
        width: -moz-fit-content;
        width: fit-content;
        left: 50px;
    }

    .upcoming-exhibitions-container {
        max-width: 770px;
    }

    .exhibition-heading-name {
        font-size: 21px;
    }

    .exhibition-link-website {
        font-size: 11px;
        width: 119px;
        padding: 0px 0px;
    }

    .upcoming-exhibitions-row {
        padding: 10px;
    }

    .upcoming-middle {
        width: 26%;
        padding: 10px 10px;
    }

    .last-upcoming {
        width: 24%;
    }

    .date-exhibition {
        font-size: 16px;
    }

    .date-wrapper-upcoming {
        padding: 22px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 6px;
    }

    .exhibition-btn {
        padding: 4px;
    }

    .exhibitions-container-main {
        margin-top: -90px;
        overflow-x: hidden;
    }

    .exhibitions-rapid-heading {
        font-size: 3rem;
    }

    .exibition-banner-wrapper {
        padding: 0px;
        width: 150px;
    }

    .upcoming-first-part {
        width: 50%;
    }
}
@media (min-width: 768px) and (max-width: 809px) {
    .exhibitions-first-container {
        top: -153px;
        width: -moz-fit-content;
        width: fit-content;
        left: 50px;
    }

    .upcoming-exhibitions-container {
        max-width: 720px;
    }
    .exhibition-heading-name {
        font-size: 18px;
    }
    .exhibition-link-website {
        font-size: 11px;
        width: 119px;
        padding: 0px 0px;
    }

    .upcoming-exhibitions-row {
        padding: 10px;
    }

    .upcoming-middle {
        width: 30%;
        padding: 10px 10px;
    }

    .last-upcoming {
        width: 20%;
    }

    .date-exhibition {
        font-size: 16px;
    }

    .date-wrapper-upcoming {
        padding: 22px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 6px;
    }

    .exhibition-btn {
        padding: 4px;
    }

    .exhibitions-container-main {
        margin-top: -90px;
        overflow-x: hidden;
    }

    .exhibitions-rapid-heading {
        font-size: 3rem;
    }

    .exibition-banner-wrapper {
        padding: 0px;
        width: 120px;
    }

    .upcoming-first-part {
        width: 50%;
    }
    .exhibition-mt-top {
        margin-top: -70px;
    }
}

@media (min-width: 425px) and (max-width:767px) {
    .exhibitions-first-container {
        top: 10px;
        left: 20px;
        width: 350px;
    }
    .exhibitions-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .upcoming-exhibitions-container {
        max-width: 400px;
    }
    .exhibition-heading-name {
        font-size: 18px;
    }
    .exhibition-link-website {
        font-size: 15px;
        width: 158px;
        padding: 0px 20px;
    }
    .upcoming-exhibitions-row {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
    }

    .last-upcoming {
        width: 100%;
    }

    .date-exhibition {
        font-size: 16px;
    }

    .date-wrapper-upcoming {
        padding: 30px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 10px;
    }

    .exhibition-btn {
        padding: 12px;
    }

    .exhibitions-container-main {
        margin-top: 70px;
        overflow-x: hidden;
    }

    .exhibitions-rapid-heading {
        font-size: 34px;
    }

    .exibition-banner-wrapper {
        padding: 20px;
        width: auto;
    }

    .upcoming-first-part {
        width: 100%;
        padding: 10px 10px;
    }
    .exhibition-mt-top {
        margin-top: 0px;
    }
    .exhibitions-img {
        padding: 20px;
        height: auto !important;
        border-radius: 35px;
    }
    .mobile-view-second-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
        border-top: 2px solid #d3d7db;
        border-bottom: 2px solid #d3d7db;
        border-right: 0px solid #d3d7db;
        border-left: 0px solid #d3d7db;
        margin: 15px 0px;
    }
    .upcoming-heading-main {
        color: #2ba4c3;
        font-size: 26px;
        font-weight: 500;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media (min-width: 393px) and (max-width: 424px) {
    .exhibitions-first-container {
        top: 10px;
        left: 20px;
        width: 350px;
    }
    .exhibitions-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .upcoming-exhibitions-container {
        max-width: 360px;
    }
    .exhibition-heading-name {
        font-size: 18px;
    }
    .exhibition-link-website {
        font-size: 15px;
        width: 158px;
        padding: 0px 20px;
    }
    .upcoming-exhibitions-row {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
    }

    .last-upcoming {
        width: 100%;
    }

    .date-exhibition {
        font-size: 14px;
    }

    .date-wrapper-upcoming {
        padding: 20px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 10px;
    }

    .exhibition-btn {
        padding: 6px;
    }

    .exhibitions-container-main {
        margin-top: 70px;
        overflow-x: hidden;
    }

    .exhibitions-rapid-heading {
        font-size: 34px;
    }

    .exibition-banner-wrapper {
        padding: 20px;
        width: auto;
    }

    .upcoming-first-part {
        width: 100%;
        padding: 10px 10px;
    }
    .exhibition-mt-top {
        margin-top: 0px;
    }
    .exhibitions-img {
        padding: 20px;
        height: auto !important;
        border-radius: 35px;
    }
    .mobile-view-second-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
        border-top: 2px solid #d3d7db;
        border-bottom: 2px solid #d3d7db;
        border-right: 0px solid #d3d7db;
        border-left: 0px solid #d3d7db;
        margin: 15px 0px;
    }
    .upcoming-heading-main {
        color: #2ba4c3;
        font-size: 26px;
        font-weight: 500;
        width: -moz-fit-content;
        width: fit-content;
    }
}
@media (min-width: 375px) and (max-width: 392px) {
    .exhibitions-first-container {
        top: 10px;
        left: 20px;
        width: 350px;
    }
    .exhibitions-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .upcoming-exhibitions-container {
        max-width: 350px;
    }
    .exhibition-heading-name {
        font-size: 18px;
    }
    .exhibition-link-website {
        font-size: 15px;
        width: 158px;
        padding: 0px 20px;
    }
    .upcoming-exhibitions-row {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
    }

    .last-upcoming {
        width: 100%;
    }

    .date-exhibition {
        font-size: 16px;
    }

    .date-wrapper-upcoming {
        padding: 13px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 10px;
    }

    .exhibition-btn {
        padding: 6px;
    }

    .exhibitions-container-main {
        margin-top: 70px;
        overflow-x: hidden;
    }

    .exhibitions-rapid-heading {
        font-size: 34px;
    }

    .exibition-banner-wrapper {
        padding: 20px;
        width: auto;
    }

    .upcoming-first-part {
        width: 100%;
        padding: 10px 10px;
    }
    .exhibition-mt-top {
        margin-top: 0px;
    }
    .exhibitions-img {
        padding: 20px;
        height: auto !important;
        border-radius: 35px;
    }
    .mobile-view-second-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
        border-top: 2px solid #d3d7db;
        border-bottom: 2px solid #d3d7db;
        border-right: 0px solid #d3d7db;
        border-left: 0px solid #d3d7db;
        margin: 15px 0px;
    }
    .upcoming-heading-main {
        color: #2ba4c3;
        font-size: 26px;
        font-weight: 500;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media (min-width: 360px) and (max-width: 374px) {
    .exhibitions-first-container {
        top: 10px;
        left: 20px;
        width: 350px;
    }
    .exhibitions-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .upcoming-exhibitions-container {
        max-width: 320px;
    }
    .exhibition-heading-name {
        font-size: 18px;
    }
    .exhibition-link-website {
        font-size: 15px;
        width: 158px;
        padding: 0px 20px;
    }
    .upcoming-exhibitions-row {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
    }

    .last-upcoming {
        width: 100%;
    }

    .date-exhibition {
        font-size: 16px;
        width: 41px;
    }

    .date-wrapper-upcoming {
        padding: 20px 23px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 10px;
    }

    .exhibition-btn {
        padding: 6px;
    }

    .exhibitions-container-main {
        margin-top: 70px;
        overflow-x: hidden;
    }

    .exhibitions-rapid-heading {
        font-size: 34px;
    }

    .exibition-banner-wrapper {
        padding: 20px;
        width: auto;
    }

    .upcoming-first-part {
        width: 100%;
        padding: 10px 10px;
    }
    .exhibition-mt-top {
        margin-top: 0px;
    }
    .exhibitions-img {
        padding: 20px;
        height: auto !important;
        border-radius: 35px;
    }
    .mobile-view-second-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
        border-top: 2px solid #d3d7db;
        border-bottom: 2px solid #d3d7db;
        border-right: 0px solid #d3d7db;
        border-left: 0px solid #d3d7db;
        margin: 15px 0px;
    }
    .upcoming-heading-main {
        color: #2ba4c3;
        font-size: 26px;
        font-weight: 500;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media (min-width: 300px) and (max-width: 359px) {
    .exhibitions-first-container {
        top: 10px;
        left: 20px;
        width: 350px;
    }
    .exhibitions-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .upcoming-exhibitions-container {
        max-width: 290px;
    }
    .exhibition-heading-name {
        font-size: 18px;
    }
    .exhibition-link-website {
        font-size: 15px;
        width: 158px;
        padding: 0px 20px;
    }
    .upcoming-exhibitions-row {
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
    }

    .last-upcoming {
        width: 100%;
    }

    .date-exhibition {
        font-size: 16px;
        width: 41px;
    }

    .date-wrapper-upcoming {
        padding: 20px 23px;
    }

    .right-arrow-exhibition-wrapper {
        padding: 10px;
    }

    .exhibition-btn {
        padding: 6px;
    }

    .exhibitions-container-main {
        margin-top: 70px;
        overflow-x: hidden;
    }

    .exhibitions-rapid-heading {
        font-size: 34px;
    }

    .exibition-banner-wrapper {
        padding: 20px;
        width: auto;
    }

    .upcoming-first-part {
        width: 100%;
        padding: 0px 0px;
    }
    .exhibition-mt-top {
        margin-top: 0px;
    }
    .exhibitions-img {
        padding: 20px;
        height: auto !important;
        border-radius: 35px;
    }
    .mobile-view-second-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .upcoming-middle {
        width: 100%;
        padding: 30px 10px;
        border-top: 2px solid #d3d7db;
        border-bottom: 2px solid #d3d7db;
        border-right: 0px solid #d3d7db;
        border-left: 0px solid #d3d7db;
        margin: 15px 0px;
    }
    .upcoming-heading-main {
        color: #2ba4c3;
        font-size: 26px;
        font-weight: 500;
        width: -moz-fit-content;
        width: fit-content;
    }
}