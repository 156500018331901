.turnkey-main {
    margin-top: -130px;
    overflow-x: hidden;
}

.turnkey-img {
    padding: 20px;
    width: 100%;
}

.custom-turnkey-container-first {
    max-width: 1440px;
    margin-bottom: 100px;
    margin-top: -70px;
}

.turnkey-first-section {
    top: -300px;
    left: 120px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
}
.turnkey-rapid-heading {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}

.turnkey-rapid-txt {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    position: relative;
}

.turnkey-first-section-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    margin-top: -14px;
}

.first-word {
    color: #c63006;
}

.turnkey-first-para {
    color: #2ba4c3;
    font-size: 35px;
    font-weight: 400;
    line-height: 1.2;
    text-align: left;
    margin-bottom: 50px;
}

.turnkey-first-p-txt {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
}
.turnkey-first-p-txt-under{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    position: relative;
    left: 60px;
}
.third-section-imgs-wrapper {
    display: flex;
    justify-content: center;
}

.turnkey-img-wrapper {
    height: 430px;
    width: 470px;
    margin: 10px;
    overflow: hidden;
}

.turnkey-img-box {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    transform: scale(1);
    /* Scale the image by 1.1 times on hover */
    transition: transform 0.5s ease;
    /* Add a smooth transition effect */
}

.custom-comntainer-three-box {
    max-width: 1600px;
}

.box-heading-txt {
    color: white;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
}

.box-txt {
    color: white;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.box-txt-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 400px;
    bottom: 420px;
    left: 40px;
    z-index: 1;
}

.box-heading-txt-firstbox {
    color: #2ba4c3;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
}

.box-txt-firstbox {
    color: #2ba4c3;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.turnkey-img-wrapper:hover .turnkey-img-box {
    transform: scale(1.1);
    /* Scale the image by 1.1 times on hover */
    transition: transform 0.5s ease;
    /* Add a smooth transition effect */
}

.custom-turnkey-step-container {
    max-width: 1600px;
    margin-top: -100px;
}

.turnkey-step-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    left: 60px;
}

.point-bollet-wrapper {
    display: flex;
}

.turnkey-steps-wrapper {
    margin-top: -190px;
}

.turnkey-bullet {
    margin-right: 8px;
}

.stepone-svg svg {
    margin: 30px;
    height: 70px;
}
.steptwo-svg svg{
    margin: 30px;
    height: 70px;
    margin-top: 35px;
}
.stepthree-svg svg{
    margin: 30px;
    height: 70px;
    margin-top: 50px;
}
.stepfour-svg svg{
    margin: 0px;
    height: 70px;
    margin-right: 30px;
}
.stepfive-svg svg{
    margin: 30px;
    height: 70px;
    margin-top: 40px;
}
.stepsix-svg svg{
    margin: 30px;
    height: 70px;
    margin-top: 20px;

}
.turnkey-bullet svg{
    height: 16px;
    margin-top: -10px;
}
.row-wrapper-one {
    display: flex;
    justify-content: space-between;
    padding: 150px 280px 80px 40px;
}
.row-wrapper-two {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 100px 130px;
}
.row-wrapper-three {
    display: flex;
    justify-content: space-between;
    padding: 0px 280px 150px 0px;
}

.stepone {
    display: flex;
}

.steptwo {
    display: flex;
    margin-top: 70px;
}

.step-three {
    display: flex;
}

.step-four {
    display: flex;
    margin-top: 70px;
}

.step-five {
    display: flex;
}

.step-six {
    display: flex;
    margin-top: 70px;
}

.steps-title {
    color: #2ba4c3;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
}

.step-one-line {
    width: 340px;
    position: relative;
    left: 570px;
    top: 265px;
}

.step-two-line {
    width: 480px;
    position: relative;
    top: 485px;
    left: 170px;
}

.step-three-line {
    width: 380px;
    position: relative;
    top: 640px;
    left: -206px;
}

.step-four-line {
    width: 650px;
    position: relative;
    top: 720px;
    left: 380px;
}

.step-five-line {
    width: 310px;
    position: relative;
    top: 920px;
    left: -150px;
}
.points-p{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}
.turnkey-img-wrapper {
    position: relative;
}

.turnkey-img-box {
    display: block;
    width: 100%;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7)); /* Adjust the color and opacity as needed */
    z-index: 1;
}

.turnkey-img-box {
    position: relative;
    z-index: 0;
}
@media (min-width: 1400px) and (max-width: 1440px) {
    .custom-turnkey-container-first {
        max-width: 1380px;
        margin-bottom: 100px;
        margin-top: -70px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .turnkey-img-wrapper {
        height: 430px;
        width: 430px;
        margin: 10px;
        overflow: hidden;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 20px;
        width: 1400px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 20px;
        width: 950px;
    }
    .step-one-line {
        width: 340px;
        position: relative;
        left: 430px;
        top: 265px;
    }
    .step-two-line {
        width: 480px;
        position: relative;
        top: 495px;
        left: 30px;
    }
    .step-three-line {
        width: 290px;
        position: relative;
        top: 640px;
        left: -246px;
    }
    .step-five-line {
        width: 310px;
        position: relative;
        top: 920px;
        left: -250px;
    }
}

@media (min-width: 1280px) and (max-width: 1399px) {
    .custom-turnkey-container-first {
        max-width: 1030px;
        margin-bottom: 100px;
        margin-top: -70px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .turnkey-img-wrapper {
        height: 310px;
        width: 310px;
        margin: 10px;
        overflow: hidden;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 20px;
        top: -110px;
        width: 1000px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 20px;
        top: -110px;
        width: 1000px;
    }
    .step-one-line {
        width: 340px;
        position: relative;
        left: 390px;
        top: 245px;
    }
    .step-two-line {
        width: 410px;
        position: relative;
        top: 485px;
        left: -60px;
    }
    .step-three-line {
        width: 170px;
        position: relative;
        top: 610px;
        left: -316px;
    }
    .step-five-line {
        width: 310px;
        position: relative;
        top: 960px;
        left: -100px;
    }
    .turnkey-main {
        margin-top: -120px;
        overflow-x: hidden;
        width: 100%;
    }
    .turnkey-first-section {
        top: -228px;
        left: 120px;
        position: relative;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 270px;
        bottom: 300px;
        left: 30px;
    }
    .turnkey-steps-wrapper {
        margin-top: -340px;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 170px 100px 80px 40px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px 100px 0px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 80px 150px 0px;
    }
    .complete-project {
        width: 310px;
        line-height: 28px;
        margin-top: -6px;
    }
    .step-four-line {
        width: 450px;
        position: relative;
        top: 710px;
        left: 340px;
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .custom-turnkey-container-first {
        max-width: 1030px;
        margin-bottom: 100px;
        margin-top: -70px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .turnkey-img-wrapper {
        height: 310px;
        width: 310px;
        margin: 10px;
        overflow: hidden;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 20px;
        top: -110px;
        width: 1000px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 20px;
        top: -110px;
        width: 1000px;
    }
    .step-one-line {
        width: 340px;
        position: relative;
        left: 390px;
        top: 245px;
    }
    .step-two-line {
        width: 410px;
        position: relative;
        top: 485px;
        left: -60px;
    }
    .step-three-line {
        width: 170px;
        position: relative;
        top: 610px;
        left: -316px;
    }
    .step-five-line {
        width: 310px;
        position: relative;
        top: 960px;
        left: -100px;
    }
    .turnkey-main {
        margin-top: -125px;
        overflow-x: hidden;
        width: 100%;
    }
    .turnkey-first-section {
        top: -228px;
        left: 120px;
        position: relative;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 270px;
        bottom: 300px;
        left: 30px;
    }
    .turnkey-steps-wrapper {
        margin-top: -340px;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 170px 100px 80px 40px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px 100px 0px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 80px 150px 0px;
    }
    .complete-project {
        width: 310px;
        line-height: 28px;
        margin-top: -6px;
    }
    .step-four-line {
        width: 450px;
        position: relative;
        top: 710px;
        left: 340px;
    }
}
@media (min-width: 1024px) and (max-width: 1079px) {
    .custom-turnkey-container-first {
        max-width: 960px;
        margin-bottom: 60px;
        margin-top: -70px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .turnkey-img-wrapper {
        height: 290px;
        width: 290px;
        margin: 10px;
        overflow: hidden;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 20px;
        top: -110px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 20px;
        top: -110px;
    }
    .step-one-line {
        width: 340px;
        position: relative;
        left: 390px;
        top: 245px;
    }
    .step-two-line {
        width: 410px;
        position: relative;
        top: 485px;
        left: -60px;
    }
    .step-three-line {
        width: 170px;
        position: relative;
        top: 590px;
        left: -356px;
    }
    .step-five-line {
        width: 310px;
        position: relative;
        top: 960px;
        left: -100px;
    }
    .turnkey-main {
        margin-top: -100px;
        overflow-x: hidden;
        width: 100%;
    }
    .turnkey-first-section {
        top: -228px;
        left: 120px;
        position: relative;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 270px;
        bottom: 280px;
        left: 30px;
    }
    .turnkey-steps-wrapper {
        margin-top: -340px;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 170px 100px 80px 40px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px 100px 0px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 80px 150px 0px;
    }
    .complete-project {
        width: 310px;
        line-height: 28px;
        margin-top: -6px;
    }
    .step-four-line {
        width: 450px;
        position: relative;
        top: 710px;
        left: 340px;
    }
}
@media (min-width: 810px) and (max-width: 1023px) {
    .custom-turnkey-container-first {
        max-width: 750px;
        margin-bottom: 60px;
        margin-top: -70px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .turnkey-img-wrapper {
        height: 225px;
        width: 225px;
        margin: 10px;
        overflow: hidden;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 20px;
        top: -110px;
        width: 680px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 20px;
        top: -110px;
        width: 600px;
    }
    .step-one-line {
        width: 230px;
        position: relative;
        left: 310px;
        top: 245px;
    }
    .step-two-line {
        width: 340px;
        position: relative;
        top: 485px;
        left: -50px;
    }
    .step-three-line {
        width: 170px;
        position: relative;
        top: 590px;
        left: -226px;
    }
    .step-five-line {
        width: 240px;
        position: relative;
        top: 1030px;
        left: -150px;
    }
    .turnkey-main {
        margin-top: -100px;
        overflow-x: hidden;
        width: 100%;
    }
    .turnkey-first-section {
        top: -182px;
        left: 60px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 200px;
        bottom: 230px;
        left: 30px;
    }
    .turnkey-steps-wrapper {
        margin-top: -290px;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 170px 24px 80px 0px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 100px 0px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 20px 150px 0px;
    }
    .complete-project {
        width: 210px;
    }
    .step-four-line {
        width: 450px;
        position: relative;
        top: 750px;
        left: 160px;
    }
    .box-heading-txt-firstbox {
        color: #2ba4c3;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-heading-txt {
        color: white;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-txt-firstbox {
        color: #2ba4c3;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-txt {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .turnkey-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .turnkey-first-section-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-top: -14px;
    }
    .turn-left {
        width: 40%;
    }
    .turn-right{
       width: 60%;
    }
    .stepone-svg svg {
        margin: 30px 0px 0px 0px;
        height: 70px;
    }
    .points-p {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        margin-top: -5px;
    }
    .stepthree-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 50px;
    }
    .stepsix-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 20px;
    }
    .stepfour-svg svg {
        margin: 0px;
        height: 70px;
        margin-right: 0px;
    }
    .steptwo-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 35px;
    }
    .stepfive-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 40px;
    }
    .custom-turnkey-step-container {
        max-width: 1600px;
        margin-top: -50px;
    }
}

@media (min-width: 768px) and (max-width: 809px){
    .custom-turnkey-container-first {
        max-width: 710px;
        margin-bottom: 60px;
        margin-top: -70px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .turnkey-img-wrapper {
        height: 220px;
        width: 220px;
        margin: 10px;
        overflow: hidden;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 20px;
        top: -110px;
        width: 630px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 20px;
        top: -110px;
        width: 600px;
    }
    .step-one-line {
        width: 230px;
        position: relative;
        left: 310px;
        top: 245px;
    }
    .step-two-line {
        width: 340px;
        position: relative;
        top: 485px;
        left: -50px;
    }
    .step-three-line {
        width: 170px;
        position: relative;
        top: 590px;
        left: -226px;
    }
    .step-five-line {
        width: 240px;
        position: relative;
        top: 1040px;
        left: -150px;
    }
    .turnkey-main {
        margin-top: -92px;
        overflow-x: hidden;
        width: 100%;
    }
    .turnkey-first-section {
        top: -182px;
        left: 60px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 200px;
        bottom: 220px;
        left: 30px;
    }
    .turnkey-steps-wrapper {
        margin-top: -290px;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 170px 24px 80px 0px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 100px 0px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 15px 70px 0px;
    }
    .complete-project {
        width: 210px;
    }
    .step-four-line {
        width: 450px;
        position: relative;
        top: 750px;
        left: 160px;
    }
    .box-heading-txt-firstbox {
        color: #2ba4c3;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-heading-txt {
        color: white;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-txt-firstbox {
        color: #2ba4c3;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-txt {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .turnkey-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .turnkey-first-section-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-top: -14px;
    }
    .turn-left {
        width: 40%;
    }
    .turn-right{
       width: 60%;
    }
    .stepone-svg svg {
        margin: 30px 0px 0px 0px;
        height: 70px;
    }
    .points-p {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        margin-top: -5px;
    }
    .stepthree-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 50px;
    }
    .stepsix-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 20px;
    }
    .stepfour-svg svg {
        margin: 0px;
        height: 70px;
        margin-right: 0px;
    }
    .steptwo-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 35px;
    }
    .stepfive-svg svg {
        margin: 0px;
        height: 70px;
        margin-top: 40px;
    }
    .custom-turnkey-step-container {
        max-width: 1600px;
        margin-top: -50px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .turnkey-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: black;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .turnkey-first-section {
        top: 10px;
        left: 20px;
        position: relative;
        display: flex;
        width: 400px;
    }
    .turnkey-rapid-txt {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        position: relative;
        width: 360px;
    }
    .step-three {
        display: flex;
        margin-top: 0px;
    }
    .turnkey-img {
        border-radius: 35px;
        height: auto !important;
        margin-top: 0px;
        padding: 20px;
        width: 100%;
    }
    .turn-row{
        flex-direction: column;
    }
    .turn-left{
        width: 100%;
    }
    .turn-right{
        width: 100%;
    }
    .turnkey-first-section-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-top: -95px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-para {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        text-align: left;
        margin-bottom: 11px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-p-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
    }
    .third-section-imgs-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: -70px;
    }
    .turnkey-img-wrapper {
        height: 370px;
        width: 370px;
        margin: 10px;
        overflow: hidden;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 320px;
        bottom: 360px;
        left: 35px;
    }
    .turnkey-img-two-mobile {
        margin-top: -170px;
    }
    .turnkey-img-three-mobile {
        margin-top: -170px;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 10px;
        margin-top: -180px;
        width: 340px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 10px;
        width: 300px;
    }
    .mobile-hide{
        display: none;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 200px 0px 0px 0px;
        flex-direction: column;
    }
    .stepone-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 5px;
    }
    .steptwo-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 5px;
    }
    .steptwo {
        display: flex;
        margin-top: 30px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 30px 0px 0px 0px;
        flex-direction: column;
    }
    .stepthree-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 10px;
    }
    .stepfour-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 7px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 0px 0px;
        flex-direction: column;
    }
    .stepfive-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 6px;
    }
    .stepsix-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 5px;
    }
    .step-four {
        display: flex;
        margin-top: 30px;
    }
    .step-five {
        display: flex;
        margin-top: 30px;
    }
    .step-six {
        display: flex;
        margin-top: 30px;
    }
    .points-p {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
    .box-txt-firstbox {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-txt {
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-heading-txt-firstbox {
        color: #2ba4c3;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-heading-txt {
        color: white;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .custom-turnkey-container-first {
        max-width: 1440px;
        margin-bottom: 50px;
        margin-top: 100px;
    }
}

@media (min-width: 375px) and (max-width:424px) {
    .turnkey-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: black;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .turnkey-first-section {
        top: 10px;
        left: 20px;
        position: relative;
        display: flex;
        width: 400px;
    }
    .turnkey-rapid-txt {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        position: relative;
        width: 360px;
    }
    .turnkey-img {
        border-radius: 35px;
        height: auto !important;
        margin-top: 0px;
        padding: 20px;
        width: 100%;
    }
    .turn-row{
        flex-direction: column;
    }
    .turn-left{
        width: 100%;
    }
    .turn-right{
        width: 100%;
    }
    .turnkey-first-section-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-top: -95px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-para {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        text-align: left;
        margin-bottom: 11px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-p-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
    }
    .third-section-imgs-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: -70px;
        margin-left: -5px;
    }
    .turnkey-img-wrapper {
        height: 340px;
        width: 340px;
        margin: 10px;
        overflow: hidden;
    }
    .custom-turnkey-step-container {
        max-width: 1600px;
        margin-top: -100px;
        padding-left: 0px;
        padding-right: 20px;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 310px;
        bottom: 330px;
        left: 30px;
    }
    .turnkey-img-two-mobile {
        margin-top: -170px;
    }
    .turnkey-img-three-mobile {
        margin-top: -170px;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 20px;
        margin-top: -180px;
        width: 340px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 20px;
        width: 300px;
    }
    .mobile-hide{
        display: none;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 200px 0px 0px 0px;
        flex-direction: column;
    }
    .stepone-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 6px;
    }
    .steptwo-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 8px;
    }
    .steptwo {
        display: flex;
        margin-top: 30px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 30px 0px 0px 0px;
        flex-direction: column;
    }
    .stepthree-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 9px;
    }
    .stepfour-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 10px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 0px 0px;
        flex-direction: column;
    }
    .stepfive-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 9px;
    }
    .stepsix-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 5px;
    }
    .step-four {
        display: flex;
        margin-top: 30px;
    }
    .step-five {
        display: flex;
        margin-top: 30px;
    }
    .step-six {
        display: flex;
        margin-top: 30px;
    }
    .points-p {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
    .box-txt-firstbox {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-txt {
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-heading-txt-firstbox {
        color: #2ba4c3;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-heading-txt {
        color: white;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .custom-turnkey-container-first {
        max-width: 1440px;
        margin-bottom: 50px;
        margin-top: 100px;
    }
}
@media (min-width: 360px ) and (max-width: 374px){
    .turnkey-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: black;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .turnkey-first-section {
        top: 10px;
        left: 20px;
        position: relative;
        display: flex;
        width: 400px;
    }
    .turnkey-rapid-txt {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        position: relative;
        width: 360px;
    }
    .turnkey-img {
        border-radius: 35px;
        height: auto !important;
        margin-top: 0px;
        padding: 20px;
        width: 100%;
    }
    .turn-row{
        flex-direction: column;
    }
    .turn-left{
        width: 100%;
    }
    .turn-right{
        width: 100%;
    }
    .turnkey-first-section-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-top: -85px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-para {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        text-align: left;
        margin-bottom: 11px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-p-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
    }
    .third-section-imgs-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: -70px;
    }
    .turnkey-img-wrapper {
        height: 280px;
        width: 335px;
        margin: 20px 0px;
        overflow: hidden;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 260px;
        bottom: 270px;
        left: 20px;
    }
    .turnkey-img-two-mobile {
        margin-top: -230px;
    }
    .turnkey-img-three-mobile {
        margin-top: -215px;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 10px;
        margin-top: -230px;
        width: 290px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 10px;
        width: 280px;
    }
    .mobile-hide{
        display: none;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 200px 0px 0px 0px;
        flex-direction: column;
    }
    .stepone-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 6px;
    }
    .steptwo-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 7px;
    }
    .steptwo {
        display: flex;
        margin-top: 30px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 30px 0px 0px 0px;
        flex-direction: column;
    }
    .stepthree-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 7px;
    }
    .stepfour-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 8px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 0px 0px;
        flex-direction: column;
    }
    .stepfive-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 8px;
    }
    .stepsix-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 7px;
    }
    .step-four {
        display: flex;
        margin-top: 30px;
    }
    .step-five {
        display: flex;
        margin-top: 30px;
    }
    .step-six {
        display: flex;
        margin-top: 30px;
    }
    .points-p {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
    .custom-turnkey-container-first {
        max-width: 1440px;
        margin-bottom: 100px;
        margin-top: -50px;
    }
    .steps-title {
        color: #2ba4c3;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-txt-firstbox {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-txt {
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-heading-txt-firstbox {
        color: #2ba4c3;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-heading-txt {
        color: white;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .custom-turnkey-container-first {
        max-width: 1440px;
        margin-bottom: 50px;
        margin-top: 100px;
    }
}
@media (min-width: 300px) and (max-width:359px) {
    .turnkey-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        width: -moz-fit-content;
        width: fit-content;
    }
    .turnkey-first-section {
        top: 0px;
        left: 20px;
        position: relative;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
    }
    .turnkey-rapid-txt {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        position: relative;
        width: 280px;
    }
    .turnkey-img {
        border-radius: 35px;
        height: auto !important;
        margin-top: -20px;
        padding: 20px;
        width: 100%;
    }
    .turn-row{
        flex-direction: column;
    }
    .turn-left{
        width: 100%;
    }
    .turn-right{
        width: 100%;
    }
    .turnkey-first-section-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-top: -85px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-para {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        text-align: left;
        margin-bottom: 11px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .turnkey-first-p-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
    }
    .third-section-imgs-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: -70px;
    }
    .turnkey-img-wrapper {
        height: 280px;
        width: 280px;
        margin: 10px;
        overflow: hidden;
    }
    .box-txt-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 260px;
        bottom: 270px;
        left: 20px;
    }
    .turnkey-img-two-mobile {
        margin-top: -230px;
    }
    .turnkey-img-three-mobile {
        margin-top: -215px;
    }
    .turnkey-step-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 10px;
        margin-top: -230px;
        width: 290px;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 10px;
        width: 280px;
    }
    .mobile-hide{
        display: none;
    }
    .row-wrapper-one {
        display: flex;
        justify-content: space-between;
        padding: 200px 0px 0px 0px;
        flex-direction: column;
    }
    .stepone-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 6px;
    }
    .steptwo-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 7px;
    }
    .steptwo {
        display: flex;
        margin-top: 30px;
    }
    .row-wrapper-two {
        display: flex;
        justify-content: space-between;
        padding: 30px 0px 0px 0px;
        flex-direction: column;
    }
    .stepthree-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 7px;
    }
    .stepfour-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 8px;
    }
    .row-wrapper-three {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 0px 0px;
        flex-direction: column;
    }
    .stepfive-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 8px;
    }
    .stepsix-svg svg {
        margin: 0px;
        height: 50px;
        margin-top: 7px;
    }
    .step-four {
        display: flex;
        margin-top: 30px;
    }
    .step-five {
        display: flex;
        margin-top: 30px;
    }
    .step-six {
        display: flex;
        margin-top: 30px;
    }
    .points-p {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
    .custom-turnkey-container-first {
        max-width: 1440px;
        margin-bottom: 100px;
        margin-top: -50px;
    }
    .steps-title {
        color: #2ba4c3;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-txt-firstbox {
        color: #2ba4c3;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-txt {
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .box-heading-txt-firstbox {
        color: #2ba4c3;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .box-heading-txt {
        color: white;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: left;
    }
    .custom-turnkey-container-first {
        max-width: 1440px;
        margin-bottom: 50px;
        margin-top: 100px;
    }
}

@media (resolution: 120dpi) {
    .turnkey-step-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        left: 60px;
        width: 95%;
    }
    .turnkey-first-p-txt-under {
        color: #3d3d3d;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        position: relative;
        left: 60px;
        width: 95%;
    }
}