.associates-container{
    background-image: url("../images/parallex.webp"), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 100px;
    background-blend-mode: overlay;
}
.associates-partners-txt{
    text-align: center;
}
.associate-partner-heading{
    -webkit-text-stroke: 1px black;
  color: white;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.associate-partner-p-txt{
    font-size: 21px;
    line-height: 28px;
    color: white;
    font-weight: 400;
}
.partner-images-wrapper{
    display: flex;
    justify-content: center;
    background-color: white;
    padding: 50px;
    margin: 100px;
}
.partner-images{
    width: 250px;
}
.partner-img {
    width: 100%;
    height: auto !important;
}
.key-point-of-partner{
    font-size: 18px;
    line-height: 28px;
    color: #2ba4c3;
    font-weight: 700;
    text-align: center;
}
@media (min-width: 810px) and (max-width: 1023px) {
    .partner-images-wrapper {
        display: flex;
        justify-content: center;
        background-color: white;
        padding: 0px;
        margin: 70px 0px;
    }
    .associate-partner-heading {
        -webkit-text-stroke: 1px black;
        color: white;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .key-point-of-partner {
        font-size: 16px;
        line-height: 28px;
        color: #2ba4c3;
        font-weight: 700;
        text-align: center;
        margin-top: -25px;
    }
}
@media (min-width: 768px) and (max-width: 809px) {
    .partner-images-wrapper {
        display: flex;
        justify-content: center;
        background-color: white;
        padding: 20px;
        margin: 70px 0px;
    }
    .key-point-of-partner {
        font-size: 16px;
        line-height: 28px;
        color: #2ba4c3;
        font-weight: 700;
        text-align: center;
        margin-top: -25px;
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .associate-partner-heading {
        -webkit-text-stroke: 0px black;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 0px 0px #b8bfd6;
    }
    .partner-images-wrapper {
        display: flex;
        justify-content: center;
        background-color: transparent;
        padding: 0px;
        margin: 0px;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-top: 50px;
    }
    .partner-images {
        width: 250px;
        margin-bottom: 10px;
    }
    .associates-container {
        height: 100%;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 50px;
        background-blend-mode: overlay;
    }
    .associate-partner-p-txt {
        font-size: 18px;
        line-height: 28px;
        color: white;
        font-weight: 400;
    }
    .key-point-of-partner {
        font-size: 18px;
        line-height: 28px;
        color: #2ba4c3;
        font-weight: 700;
        text-align: center;
    }
}

@media (min-width: 300px) and (max-width: 424px) {
    .associate-partner-heading {
        -webkit-text-stroke: 0px black;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 0px 0px #b8bfd6;
    }
    .partner-images-wrapper {
        display: flex;
        justify-content: center;
        background-color: transparent;
        padding: 0px;
        margin: 0px;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-top: 50px;
    }
    .partner-images {
        width: 250px;
        margin-bottom: 10px;
    }
    .associates-container {
        height: 100%;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 50px;
        background-blend-mode: overlay;
    }
    .associate-partner-p-txt {
        font-size: 18px;
        line-height: 28px;
        color: white;
        font-weight: 400;
    }
    .key-point-of-partner {
        font-size: 18px;
        line-height: 28px;
        color: #2ba4c3;
        font-weight: 700;
        text-align: center;
    }
}