.mobile-big-swiper-wrapper{
    background-color: #dde1ed8f;
}
.mobile-big-content-wrapper{
    padding: 20px 20px 20px 20px;
    height: 50vh;
    overflow-y: scroll;
}
.mobile-big-p{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
}
.RAPIDunpack-mobile-heading{
    color: #2ba4c3;
}
.mobile-bold-txt{
    color: #2ba4c3;
}
@media (min-width: 425px) and (max-width: 767px) {
    .mobile-big-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .mobile-big-swiper-wrapper {
        background-color: #dde1ed8f;
        position: relative;
        margin-left: -30px;
        overflow: hidden;
    }
    .mobile-big-content-wrapper {
        padding: 20px 60px 20px 20px;
        height: 50vh;
        overflow-y: scroll;
    }
}
@media (min-width: 300px) and (max-width: 424px) {
    .mobile-big-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .mobile-big-swiper-wrapper {
        overflow: hidden;
    }
}