.background-mountain{
    background-image: url('../images/animatedbottle-background.webp');
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    opacity: 0.7;
    z-index: 0;
    position: absolute;
    /* bottom: -90.7083vw; */
    height: 28.8021vw;
    object-fit: cover;
    
}
.container-wrapper-bottle{
    background-image: url('../images/bg_bottle_animation.webp');
    width: 100%;
    background-position: bottom;
    object-fit: cover;
    padding: 80px;
    
}
.animated-bottle-maindiv{
    padding-bottom: 200px;
}
.bottle-imgdiv{
    z-index: 9;
}
.bottle-image{
    height: 80.3519vh !important;
    width: 45.1296vh  !important;
}
.bottle-contentdiv{
    padding-top: 0px;
     width: 36.0625vw
}
.bottle-content-p-first{
    font-size: 48px;
    line-height: 57px;
    text-align: justify;
    font-weight: bold;
    color: #2ba4c3;
    -webkit-text-stroke: 0.7px #000;
}
.bottle-content-p-second{
    font-size: 21px;
    line-height: 28px;
    text-align: justify;
    width: 32vw;
    color: rgb(61, 61, 61);
    font-weight: 400;
}
.container-custom{
    display: flex;
    position: relative;
    top: 300px;
    padding-bottom: 350px;
    max-width: 1353px;

}
.second-background{
    background-image: url("../images/Rapid-Background-bottle.webp");
    width: 100%;
    background-position: bottom;
    object-fit: auto;

}
.bottle-content-p-leftone{
    padding-top: 350px;
}
.bottle-content-p-leftone-heading{
    font-size: 48px;
    line-height: 57px;
    text-align: right;
    font-weight: bold;
    color: #2ba4c3;
    -webkit-text-stroke: 0.7px #000;
}
.bottle-content-p-lefttwo{
    font-size: 21px;
    line-height: 28px;
    text-align: right;
    color: rgb(61, 61, 61);
    font-weight: 400;
    margin-top: 40px;
}
.bottle-content-p-rightone{
    padding-top: 150px;
}
.bottle-content-p-rightone-heading{
    font-size: 48px;
  line-height: 57px; /* Adjusted as '26px' would not work as a valid line-height */
  text-align: left;
  font-weight: bold;
  color: #2ba4c3;
  -webkit-text-stroke: 0.7px #000;
}
.bottle-content-p-righttwo{
    font-size: 21px;
    line-height: 28px;
  text-align: left;
  color: rgb(61, 61, 61);
  font-weight: 400;
  margin-top: 40px;
}
.txt-boolet-wrapper{
    display: flex;
}
.boolet{
    padding-right: 10px;
}
.yt-video-what-is-our-setup{
    padding-bottom: 20px;
}

@media (min-width: 1440px) {
    .bottle-image {
        position: relative;
        top: 40px;
        left: -20px;
    }
    .bottle-content-p-leftone {
        padding-top: 250px;
    }
    .bottle-content-p-rightone {
    padding-top: 50px;
}

}
@media (min-width: 1366px) and (max-width: 1399px) {
    .bottle-image {
        height: 80.3519vh !important;
        width: 45.1296vh !important;
        position: relative;
        left: 100px;
    }
}
@media (min-width: 1280px) and (max-width: 1365px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: bottom;
        object-fit: cover;
        padding: 50px;
    }
    .bottle-contentdiv {
        padding-top: 40px;
        width: 49.0625vw;
    }
    .bottle-content-p-second {
        font-size: 21px;
        line-height: 28px;
        text-align: justify;
        width: 40vw;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .bottle-content-p-leftone {
        padding-top: 200px;
    }
    .bottle-content-p-rightone {
        padding-top: 20px;
    }
    .bottle-content-p-first {
        font-size: 42px;
        line-height: 57px;
        text-align: justify;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0.7px #000;
    }
    .bottle-content-p-leftone-heading {
        font-size: 42px;
        line-height: 57px;
        text-align: right;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0.7px #000;
    }
    .bottle-content-p-rightone-heading {
        font-size: 42px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0.7px #000;
    }
    .bottle-image {
        height: 80.3519vh !important;
        width: 45.1296vh !important;
        position: relative;
        left: 90px;
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: bottom;
        object-fit: cover;
        padding: 50px;
    }
    .bottle-contentdiv {
        padding-top: 40px;
        width: 49.0625vw;
    }
    .bottle-content-p-second {
        font-size: 21px;
        line-height: 28px;
        text-align: justify;
        width: 40vw;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .bottle-content-p-leftone {
        padding-top: 200px;
    }
    .bottle-content-p-rightone {
        padding-top: 20px;
    }
    .bottle-content-p-first {
        font-size: 42px;
        line-height: 57px;
        text-align: justify;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0.7px #000;
    }
    .bottle-content-p-leftone-heading {
        font-size: 42px;
        line-height: 57px;
        text-align: right;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0.7px #000;
    }
    .bottle-content-p-rightone-heading {
        font-size: 42px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0.7px #000;
    }
    .container-custom {
        display: flex;
        position: relative;
        top: 300px;
        padding-bottom: 200px;
        max-width: 1353px;
    }
}

@media (min-width: 1024px) and (max-width: 1079px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: bottom;
        object-fit: cover;
        padding: 50px;
    }
    .bottle-contentdiv {
        padding-top: 40px;
        width: 49.0625vw;
    }
    .bottle-content-p-second {
        font-size: 21px;
        line-height: 28px;
        text-align: justify;
        width: 40vw;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .bottle-content-p-leftone {
        padding-top: 200px;
    }
    .bottle-content-p-rightone {
        padding-top: 20px;
    }
}

@media (min-width: 810px) and (max-width: 1023px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: bottom;
        object-fit: cover;
        padding: 0px;
    }
    .bottle-image {
        height: 100% !important;
        width: auto !important;
    }
    /* .bottle-image {
        height: auto !important;
        width: 330px !important;
    } */
    .bottle-image {
        height: 540px !important;
        width: 300px !important;
    }
    .bottle-contentdiv {
        padding-top: 0px;
        width: 55.0625vw;
    }
    .custom-container-scroll-bottle{
        max-width: 800px;
    }
    .bottle-content-p-second {
        font-size: 21px;
        line-height: 28px;
        text-align: left;
        width: auto;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .scroll-bottle-row {
        padding-top: 50px;
    }
    .bottle-content-p-leftone {
        padding-top: 0px;
        width: 49%;
    }
    .bottle-content-p-rightone {
        padding-top: 0px;
        width: 49%;
    }
    .bottle-content-p-leftone-heading {
        font-size: 40px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-content-p-lefttwo {
        font-size: 21px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .bottle-content-p-righttwo {
        font-size: 21px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .container-custom {
        display: flex;
        position: relative;
        top: 50px;
        padding-bottom: 0px;
        max-width: 1353px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .for-bottle{
        display: none;
    }
    .bottle-content-p-rightone-heading {
        font-size: 40px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-content-p-first {
        font-size: 40px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .animated-bottle-maindiv {
        padding-bottom: 120px;
        margin-top: -130px;
    }
    .animated-video-container {
        position: relative;
        margin-top: -150px;
    }
    .second-background {
        width: 100%;
        background-position: bottom;
        background-image: none;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: bottom;
        object-fit: cover;
        padding: 0px;
    }
    .bottle-image {
        height: 100% !important;
        width: auto !important;
    }
    /* .bottle-image {
        height: auto !important;
        width: 330px !important;
    } */
    .bottle-image {
        height: 540px !important;
        width: 300px !important;
    }
    .bottle-contentdiv {
        padding-top: 0px;
        width: 55.0625vw;
    }
    .custom-container-scroll-bottle{
        max-width: 800px;
    }
    .bottle-content-p-second {
        font-size: 21px;
        line-height: 28px;
        text-align: left;
        width: auto;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .scroll-bottle-row {
        padding-top: 50px;
    }
    .bottle-content-p-leftone {
        padding-top: 0px;
        width: 49%;
    }
    .bottle-content-p-rightone {
        padding-top: 0px;
        width: 49%;
    }
    .bottle-content-p-leftone-heading {
        font-size: 40px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-content-p-lefttwo {
        font-size: 21px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .bottle-content-p-righttwo {
        font-size: 21px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .container-custom {
        display: flex;
        position: relative;
        top: 50px;
        padding-bottom: 0px;
        max-width: 1353px;
    }
    .for-bottle{
        display: none;
    }
    .bottle-content-p-rightone-heading {
        font-size: 39px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-content-p-first {
        font-size: 40px;
        line-height: 57px;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .second-background {
        width: 100%;
        background-position: bottom;
        background-image: none;
        padding: 0px 20px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: 0px center;
        object-fit: cover;
        padding: 0px;
        background-repeat: no-repeat;
    }
    .bottle-image {
        height: 100% !important;
        width: auto !important;
    }
    /* .bottle-image {
        height: auto !important;
        width: 330px !important;
    } */
    .bottle-image {
        height: 410px !important;
        width: 230px !important;
    }
    .bottle-contentdiv {
        padding-top: 50px;
        width: 100%;
        padding: 50px 20px 0px 20px;
    }
    .custom-container-scroll-bottle{
        max-width: 800px;
    }
    .bottle-content-p-second {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        width: auto;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .scroll-bottle-row {
        padding-top: 50px;
        flex-direction: column;
    }
    .bottle-content-p-leftone {
        padding-top: 0px;
        width: 100%;
    }
    .bottle-content-p-rightone {
        padding-top: 0px;
        width: 100%;
    }
    .bottle-content-p-leftone-heading {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-content-p-lefttwo {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .bottle-content-p-righttwo {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .container-custom {
        display: flex;
        position: relative;
        top: 50px;
        padding-bottom: 0px;
        max-width: 1353px;
    }
    .for-bottle{
        display: none;
    }
    .bottle-content-p-rightone-heading {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-imgdiv {
        z-index: 9;
        width: 100%;
        text-align: center;
    }
    .animated-bottle-maindiv {
        padding-bottom: 80px;
    }
    .bottle-content-p-first {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .second-background {
        width: 100%;
        background-position: bottom;
        padding: 0px 5px;
        background-image: none;
    }
    .animated-video-container {
        position: relative;
        margin-top: -190px;
    }
    .animated-bottle-maindiv {
        padding-bottom: 80px;
        margin-top: -233px;
    }
    .yt-video-what-is-our-setup{
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: 0px center;
        object-fit: cover;
        padding: 0px;
        background-repeat: no-repeat;
    }
    .bottle-image {
        height: 100% !important;
        width: auto !important;
    }
    /* .bottle-image {
        height: auto !important;
        width: 330px !important;
    } */
    .bottle-image {
        height: 410px !important;
        width: 230px !important;
    }
    .bottle-contentdiv {
        padding-top: 50px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .custom-container-scroll-bottle{
        max-width: 800px;
    }
    .bottle-content-p-second {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        width: auto;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .scroll-bottle-row {
        padding-top: 50px;
        flex-direction: column;
    }
    .bottle-content-p-leftone {
        padding-top: 0px;
        width: 100%;
    }
    .bottle-content-p-rightone {
        padding-top: 0px;
        width: 100%;
    }
    .bottle-content-p-leftone-heading {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-content-p-lefttwo {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .bottle-content-p-righttwo {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .container-custom {
        display: flex;
        position: relative;
        top: 50px;
        padding-bottom: 0px;
        max-width: 1353px;
    }
    .for-bottle{
        display: none;
    }
    .bottle-content-p-rightone-heading {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-imgdiv {
        z-index: 9;
        width: 100%;
        text-align: center;
    }
    .animated-bottle-maindiv {
        padding-bottom: 80px;
    }
    .bottle-content-p-first {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .second-background {
        width: 100%;
        background-position: bottom;
        padding: 0px 5px;
        background-image: none;
    }
    .animated-video-container {
        position: relative;
        margin-top: -190px;
    }
    .animated-bottle-maindiv {
        padding-bottom: 80px;
        margin-top: -233px;
    }
    .yt-video-what-is-our-setup{
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .container-wrapper-bottle {
        width: 100%;
        background-position: center;
        object-fit: cover;
        padding: 0px;
        background-repeat: no-repeat;
    }
    .bottle-image {
        height: 100% !important;
        width: auto !important;
    }
    /* .bottle-image {
        height: auto !important;
        width: 330px !important;
    } */
    .bottle-image {
        height: 410px !important;
        width: 230px !important;
    }
    .bottle-contentdiv {
        padding-top: 50px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .custom-container-scroll-bottle{
        max-width: 800px;
    }
    .bottle-content-p-second {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        width: auto;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .scroll-bottle-row {
        padding-top: 50px;
        flex-direction: column;
    }
    .bottle-content-p-leftone {
        padding-top: 0px;
        width: 100%;
    }
    .bottle-content-p-rightone {
        padding-top: 0px;
        width: 100%;
    }
    .bottle-content-p-leftone-heading {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-content-p-lefttwo {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .bottle-content-p-righttwo {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        color: rgb(61, 61, 61);
        font-weight: 400;
        margin-top: 20px;
    }
    .container-custom {
        display: flex;
        position: relative;
        top: 50px;
        padding-bottom: 0px;
        max-width: 1353px;
    }
    .for-bottle{
        display: none;
    }
    .bottle-content-p-rightone-heading {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .bottle-imgdiv {
        z-index: 9;
        width: 100%;
        text-align: center;
    }
    .animated-bottle-maindiv {
        padding-bottom: 80px;
    }
    .bottle-content-p-first {
        font-size: 32px;
        line-height: 1.4;
        text-align: left;
        font-weight: bold;
        color: #2ba4c3;
        -webkit-text-stroke: 0px #000;
    }
    .second-background {
        width: 100%;
        background-position: bottom;
        padding: 0px 5px;
        background-image: none;
    }
    .animated-video-container {
        position: relative;
        margin-top: -190px;
    }
    .animated-bottle-maindiv {
        padding-bottom: 80px;
        margin-top: -233px;
    }
    .yt-video-what-is-our-setup{
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (resolution: 144dpi) {
    .bottle-image {
        height: 80.3519vh !important;
        width: 45.1296vh !important;
        position: relative;
        top: 60px;
        left: 120px;
    }
    .bottle-content-p-leftone {
        padding-top: 100px;
    }
    .animated-video-container video {
        height: 300px;
    }
    .container-wrapper-bottle {
        padding: 50px 0px;
    }
    .bottle-content-p-rightone {
        padding-top: 0px;
    }
}