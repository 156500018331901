.custom-worldmap-container{
    max-width: 100%;
    background-color: #f1f1f1;
}
.custom-worldmap-row{
    display: flex;
    flex-direction: column;
}
.world-map-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    width: 70%;
}
.world-map-txt{
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
    width: 95%;
}
.txt-wrapper-worldmap{
    max-width: 1400px;
    position: relative;
    left: 100px;
    top: 100px;
    padding-bottom: 100px;
    width: 88%;
}
.world-map-img-wrapper{
    text-align: center;
}
.world-map-img{
    width: 85%;
}
@media (min-width: 1400px) and (max-width: 1440px) {
    .world-map-txt {
        font-size: 21px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: 1230px;
    }
    .world-map-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        width: 71%;
    }
}
@media (min-width: 1280px) and (max-width: 1399px) {
    .world-map-txt {
        font-size: 21px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: 1230px;
    }
    .world-map-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        width: 79%;
    }
    .txt-wrapper-worldmap {
        max-width: 1400px;
        position: relative;
        left: 20px;
        top: 100px;
        padding-bottom: 100px;
        width: 88%;
    }
}
@media (min-width: 1080px) and (max-width: 1279px){
    .world-map-txt {
        font-size: 21px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: 930px;
    }
    .txt-wrapper-worldmap {
        position: relative;
        left: 20px;
        top: 100px;
        padding-bottom: 100px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .world-map-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        width: 94%;
    }
}
@media (min-width: 1024px) and (max-width: 1079px) {
    .world-map-txt {
        font-size: 21px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: 930px;
    }
    .txt-wrapper-worldmap {
        position: relative;
        left: 20px;
        top: 100px;
        padding-bottom: 100px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .world-map-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        width: 94%;
    }
}
@media (min-width: 810px) and (max-width: 1023px) {
    .world-map-txt {
        font-size: 21px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: 100%;
        padding-right: 30px;
    }
    .txt-wrapper-worldmap {
        position: relative;
        left: 20px;
        top: 50px;
        padding-bottom: 50px;
        width: -moz-fit-content;
        width: 99%;
    }
    .world-map-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        width: fit-content;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .world-map-txt {
        font-size: 21px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: 660px;
    }
    .txt-wrapper-worldmap {
        position: relative;
        left: 50px;
        top: 100px;
        padding-bottom: 100px;
        width: fit-content;
    }
    .world-map-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        width: fit-content;
    }
    .txt-wrapper-worldmap {
        position: relative;
        left: 20px;
        top: 100px;
        padding-bottom: 100px;
        width: -moz-fit-content;
        width: 98%;
    }
    .world-map-txt {
        font-size: 21px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: auto;
        padding-right: 30px;
    }
}
@media (min-width: 300px) and (max-width: 767px) {
    .world-map-txt {
        font-size: 18px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
        width: auto;
    }
    .txt-wrapper-worldmap {
        position: relative;
        left: 0px;
        top: 50px;
        padding-bottom: 50px;
        width: -moz-fit-content;
        width: fit-content;
        padding-right: 10px;
        padding-left: 10px;
    }
    .world-map-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 0px 0px #b8bfd6;
        width: 100%;
    }
    .world-map-img {
        width: 100%;
    }
    .custom-worldmap-container {
        max-width: 100%;
        background-color: #f1f1f1;
        padding: 0px 8px;
    }
    .second-world-map-heading{
        margin-top: -10px;
    }
}
