.beer-in-can-container {
    padding-bottom: 100px;
    background-color: #fff;
    z-index: 5;
    display: block;
    position: relative;
}

.custom-beerin-can-container {
    max-width: 1500px;
}
.beer-in-can-heading {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    top: -300px;
    left: 120px;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
}

.beer-in-can-txt {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.subpage-hero-section {
    margin-top: -130px;
    overflow-x: hidden;
}

.subpageherobackground {
    width: 100%;
    padding: 20px
}

.sub-hero-background {
    padding-left: 30px;
    padding-right: 20px;
}

.beer-in-can-img {
    margin-left: 90px;
    margin-top: 50px;
}

.beer-in-can-img-wrapper {
    height: auto;
    width: 250px;
}

.beer-in-can-right-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2;
    text-shadow: 1px 1px #b8bfd6;
}

.animated-bic {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.fadeInUp-bic {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
.right-bullet svg{
    width: 20px;
    margin-right: 20px;
}
.custom-container-poi{
    padding-top: 100px;
    padding-bottom: 100px;
}
.poi-txt-right{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.points-wrapper{
    display: flex;
}
.points-heading-txt-main{
    font-size: 64px;
    color: black;
    font-weight: bold;
    -webkit-text-stroke: 1px white;
}
@keyframes fadeInUp {
    from {
        transform: translate3d(0, 70px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 70px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.subpage-hero-section-mobile {
    background-color: #fff;
    z-index: 99;
    display: block;
    position: relative;
    margin-top: 60px;
}

.subpageherobackground-mobile {
    width: 100%;
    height: 290px !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 20px;
}

.beer-in-can-heading-mobile {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    top: -180px;
    left: 40px;
    position: relative;
    z-index: 181;
}

/* ---------------------- RESPONSIVE FOR HERO SECTION------------------------ */

@media (min-width: 1400px) and (max-width: 1440px) {
    .beer-in-can-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -237px;
        left: 120px;
        position: relative;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    /* div#root {
        overflow-y: scroll;
        scrollbar-width: none;
    } */

    .beer-in-can-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -250px;
        left: 120px;
        position: relative;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    /* div#root {
        overflow-y: scroll;
        scrollbar-width: none;
    } */

    .subpage-hero-section {
        margin-top: -125px;
        overflow-x: hidden;
    }

    .beer-in-can-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -200px;
        left: 120px;
        position: relative;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    /* div#root {
        overflow-y: scroll;
        scrollbar-width: none;
    } */

    .subpage-hero-section {
        margin-top: -89px;
        overflow-x: hidden;
    }

    .beer-in-can-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -134px;
        left: 60px;
        position: relative;
    }
    .points-heading-txt-main {
        font-size: 40px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 1px white;
    }
}

/* ---------------------- BEER IN CAN SECOND SECTION ------------------------ */

@media (min-width: 1400px) and (max-width: 1440px) {
    .beer-in-can-right {
        width: 53%;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .beer-in-can-container {
        overflow-x: hidden;
    }

    .beer-in-can-right {
        width: 56.1%;
    }

    .beer-in-can-img {
        margin-left: 90px;
        margin-top: 120px;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .beer-in-can-img {
        margin-left: 90px;
        margin-top: 190px;
    }
    .csd-in-can-heading-mobile{
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -200px;
        left: 40px;
        position: relative;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .can-txt-row {
        flex-direction: column-reverse;
        padding: 0px 30px 0px 30px;
    }

    .beer-in-can-left {
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
    }

    .beer-in-can-right-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 40px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }

    .beer-in-can-right {
        width: 100%;
    }

    .beer-in-can-img {
        margin-left: 0px;
        margin-top: 50px;
    }

    .beer-in-can-container {
        padding-bottom: 100px;
        background-color: #fff;
        z-index: 99;
        display: block;
        position: relative;
        margin-top: -50px;
    }
    .beer-in-can-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -154px;
        left: 60px;
        position: relative;
        width: fit-content;
    }
    .csd-in-can-heading-mobile{
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -154px;
        left: 41px;
        position: relative;
        width: fit-content;
    }
}
@media (min-width: 600px) and (max-width: 767px){
    .beer-in-can-container {
        background-color: #fff;
        display: block;
        padding-bottom: 100px;
        position: relative;
        z-index: 99;
        margin-top: -50px;
    }
    .can-txt-row {
        flex-direction: column;
        padding: 0px 30px 0px 30px;
    }

    .beer-in-can-left {
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
    }

    .beer-in-can-right-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }

    .beer-in-can-right {
        width: 100%;
    }

    .beer-in-can-img {
        margin-left: 0px;
        margin-top: 50px;
    }
    .csd-in-can-heading-mobile{
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 34px;
        font-weight: 600;
        left: 40px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -248px;
        z-index: 181;
        width: 260px;
    }
    .csd-txt{
        margin-top: -190px;
    }
    .points-heading-txt-main {
        font-size: 35px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
    }
    .custom-container-poi {
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .beer-in-can-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .poi-txt-right {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
}
@media (min-width: 425px) and (max-width: 599px){
    .beer-in-can-container {
        background-color: #fff;
        display: block;
        padding-bottom: 100px;
        position: relative;
        z-index: 99;
        margin-top: 10px;
    }
    .can-txt-row {
        flex-direction: column;
        padding: 0px 0px 0px 0px;
    }

    .beer-in-can-left {
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
    }

    .beer-in-can-right-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }

    .beer-in-can-right {
        width: 100%;
        margin-top: 30px;
        padding: 0px 15px;
    }
    .beer-in-can-img {
        margin-left: 0px;
        margin-top: 0px;
    }
    .csd-in-can-heading-mobile {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        left: 20px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        z-index: 181;
        width: 380px;
    }
    .csd-txt {
        margin-top: -30px;
    }
    .points-heading-txt-main {
        font-size: 35px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
    }
    .custom-container-poi {
        padding-top: 50px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
.beer-in-can-txt {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.poi-txt-right {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.new-moblie-bic-heading{
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
}
.subpageherobackground-mobile {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}
}
@media (min-width: 393px) and (max-width: 424px) {
    .beer-in-can-container {
        background-color: #fff;
        display: block;
        padding-bottom: 100px;
        position: relative;
        z-index: 99;
        margin-top: 10px;
    }
    .can-txt-row {
        flex-direction: column;
        padding: 0px 0px 0px 0px;
    }
    .beer-in-can-left {
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 34px;
        font-weight: 600;
        left: 40px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        z-index: 181;
    }
    .csd-in-can-heading-mobile {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        left: 20px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        z-index: 181;
        width: 360px;
    }
    .csd-txt {
        margin-top: -30px;
    }
    .beer-in-can-right-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }

    .beer-in-can-right {
        width: 100%;
        margin-top: 30px;
    }

    .beer-in-can-img {
        margin-left: 0px;
        margin-top: 0px;
    }
    .points-heading-txt-main {
        font-size: 35px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
    }
    .custom-container-poi {
        padding-top: 50px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        left: 40px;
        position: relative;
        z-index: 181;
        width: 330px;
    }
    .poi-txt-right {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .new-moblie-bic-heading{
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
}
.subpageherobackground-mobile {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}
}
@media (min-width: 375px) and (max-width: 392px){
    .beer-in-can-container {
        background-color: #fff;
        display: block;
        padding-bottom: 100px;
        position: relative;
        z-index: 99;
        margin-top: 10px;
    }
    .can-txt-row {
        flex-direction: column;
        padding: 0px 0px 0px 0px;
    }
    .beer-in-can-left {
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        left: 40px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        z-index: 181;
    }
    .csd-in-can-heading-mobile {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        left: 20px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        z-index: 181;
        width: 350px;
    }
    .csd-txt {
        margin-top: -30px;
    }
    .beer-in-can-right-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }

    .beer-in-can-right {
        width: 100%;
        margin-top: 30px;
    }

    .beer-in-can-img {
        margin-left: 0px;
        margin-top: 0px;
    }
    .points-heading-txt-main {
        font-size: 35px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
    }
    .custom-container-poi {
        padding-top: 50px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .beer-in-can-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .poi-txt-right {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .new-moblie-bic-heading{
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
}
.subpageherobackground-mobile {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}
}
@media (min-width: 300px) and (max-width: 374px) {
    .beer-in-can-container {
        background-color: #fff;
        display: block;
        padding-bottom: 100px;
        position: relative;
        z-index: 99;
        margin-top: 10px;
    }
    .can-txt-row {
        flex-direction: column;
        padding: 0px 0px 0px 0px;
    }
    .beer-in-can-left {
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        left: 40px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        z-index: 181;
    }
    .beer-in-can-right-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }

    .beer-in-can-right {
        width: 100%;
        margin-top: 30px;
    }

    .beer-in-can-img {
        margin-left: 0px;
        margin-top: 0px;
    }
    .beer-in-can-right-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .csd-in-can-heading-mobile {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        left: 20px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        z-index: 181;
        width: 300px;
    }
    .csd-txt {
        margin-top: -30px;
    }
    .points-heading-txt-main {
        font-size: 35px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
    }
    .custom-container-poi {
        padding-top: 50px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .beer-in-can-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .poi-txt-right {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .new-moblie-bic-heading{
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
}
.subpageherobackground-mobile {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}
}
@media (width: 1080px) {
    .beer-in-can-right{
        width: 53.333333%;
    }
}

