.mega-menu-main {
    background-color: white;
    position: fixed;
    top: 115px;
    z-index: 2222;
    width: 100%;
    left: 0px;
    height: 450px;
    transition: all ease-in-out 0.7s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}
.mega-menu-main-scrolled{
    background-color: white;
    position: fixed;
    top: 90px;
    z-index: 2222;
    width: 100%;
    left: 0px;
    height: 450px;
    transition: all ease-in-out 0.7s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}
.megamenu-inner-wrapper{
    padding-top: 20px;
}
.header-container-megamenu{
  padding-top: 30px;
  padding-bottom: 30px;
}
.ul-megamenu-header{
    display: flex;
}
.li-megamenu-header{
    list-style-type: none;
    padding: 0px 25px;
}
.btn-mm-wrapper{
    border: 1px solid black;
    border-radius: 30px;
    background-color: white;
}
.txt-img-mm-wrapper{
    display: flex;
}
.img-wrapper-mm{
    background-color: #2ba4c3;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    margin-top: 6px;
}
.right-arrow-mm-img{
    height: 30px !important;
    width: 30px;
    filter: invert(1);
    margin-top: 4px;
    padding: 3px;
    margin-left: 3px;
}
.txt-mm-btn {
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 30px;
    margin-bottom: 16px;
}
.a-tag-mm-header{
    text-decoration: none;
    color: black;
    letter-spacing: 1.2px;
}
.btn-mm-wrapper:hover {
    background-color: #2ba4c3;
}
.btn-mm-wrapper:hover .txt-mm-btn{
    color: white;
}
.btn-mm-wrapper:hover .img-wrapper-mm{
    background-color: white;
}
.btn-mm-wrapper:hover .right-arrow-mm-img{
    filter: invert(0);
}

.megamenu-page-name{
    padding-top: 15px;
    display: flex;
    justify-content: end;
}

.mm-img-wrapper{
    width: 150px;
}
.mm-initial-div-wrap{
    display: flex;
    flex-direction: row;

}
.inner-wrapper-mm-one{
    display: flex;
    margin: 10px;
}

.btn-mega-menu{
    background-color: #2ba4c3;
    padding: 10px;
    border: 1px solid #2ba4c3;
    color: white;
    border-radius: 30px;
    margin: 2px;
    font-size: 14px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.mm-btns-wrapper{
    margin: 10px;
    width: 400px;
}
.mm-img-wrapper img{
    width: 100%;
}
.sapratoe-line{
    height: 1px;
    background-color: rgb(50, 50, 50);
    margin-top: 40px;
    width: 1300px;
    text-align: center;
}
.p-txt-mm{
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
}
.under-line-btn{
    margin-top: 40px;
    margin-bottom: 40px;
}
.btn-mega-menu:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
}
.overlay-mm{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Transparent white */
  z-index: 999; /* Make sure it's above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}
.side-bar-wrapper {
    background-color: white;
    width: 400px;
    z-index: 1300;
    position: fixed;
    height: 100vh;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 20px 20px
}
.sidebar-top-imgs-wrapper {
    display: flex;
    padding: 30px 20px 20px 20px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 11;
    background-color: white;
}
.close-icon{
    height: 25px !important;
    width: 25px;
    filter: invert(0);
}
.side-bar-logo{
    width: 40%;
}
.sidebar-links{
    color: black;
}
.sidebar-ul{
    position: relative;
    top: 90px;
    left: -10px;

}
.sidebar-ul li {
    list-style-type: none;
    padding: 10px 10px 10px 0px;
    font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 1px;
}
.p-txt-mm-sidebar{
    font-size: 21px;
    line-height: 28px;
    color: white;
    font-weight: 400;
}
.sidebar-bottom-wrapper {
    background-color: rgba(36, 36, 36, 0.93);
    padding: 60px 40px 60px 20px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.down-arrow-sidebar{
    height: 20px !important;
    width: 20px;
    filter: invert(0);
    margin-left: 5px;
}
.submenu-wrapper-sidebar {
    background-color: white;
    width: 400px;
    z-index: 1300;
    position: fixed;
    height: 100vh;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
}
.sidebar-submenu-a{
    text-decoration: none;
    background-color: #2ba4c3;
    color: white;
    padding: 10px;
    border-radius: 30px;
    display: inline-block;
    margin-top: 5px;
    margin-right: 3px;
    font-size: 13px;

}
.btns-submenu-wrapper{
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

}
.last-megamenu{
    margin-bottom: 120px;

}

@media (min-width: 1200px) and (max-width: 1366px){
    .txt-mm-btn {
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 10px;
        margin-bottom: 15px;
    }
}
@media (min-width: 768px) and (max-width: 1199px) {
    .sidebar-img-btns {
        width: 73%;
    }
}
@media (min-width: 300px) and (max-width: 600px){
    .submenu-wrapper-sidebar {
        background-color: white;
        width: 100%;
        z-index: 1300;
        position: fixed;
        height: 100vh;
        top: 0px;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: scroll;
    }
    .side-bar-wrapper {
        background-color: white;
        width: 100%;
        z-index: 1300;
        position: fixed;
        height: 100vh;
        top: 0px;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .sidebar-img-btns {
        width: 60%;
        position: relative;
        left: 10px;
    }
    .sidebar-ul-wrapper{
        position: relative;
    top: -50px;
    }
}