.contact-form-BIC {
    background-color: rgba(243, 247, 250, 1);
    padding-bottom: 100px;
    padding-top: 100px;
}

.custom-container-form-bic {
    background: url(../../images/beer-in-can/career-form-bic.png) left bottom / 510px no-repeat,
        rgb(255, 255, 255);
    padding: 30px;
    border-radius: 30px;
    max-width: 1500px;
}

.pf-25 {
    padding-left: 25px;
}

.form-wrapper {
    margin-top: 20px;
}

.bic-form-heading {
    font-size: 64px;
    color: black;
    font-weight: bold;
    -webkit-text-stroke: 1px white;
}

.bic-form-left-txt {
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.dis-flex {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.bic-label-txt {
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;

}

.bic-input {
    border: none;
    cursor: pointer;
    color: rgb(38, 42, 57);
    background-color: rgb(245, 246, 251);
    height: 50px;
    border-radius: 10px;
    padding: 15px 16px;
    width: 430px;
}

.name-of-organization {
    margin-left: 8px;
    margin-bottom: 20px;
}

.name-of-organization-input {
    width: 880px;
}

.message-txt {
    margin-left: 8px;
    margin-bottom: 20px;
}

.message-txt-textarea {
    border: none;
    cursor: pointer;
    color: rgb(38, 42, 57);
    background-color: rgb(245, 246, 251);
    border-radius: 10px;
    padding: 16px 20px;
    width: 880px;
    height: 140px;
}

.border-bic {
    border-top: 1px solid rgb(71, 80, 95);
    opacity: 0.2;
    width: 98.5%;
    margin-left: 9px;
    margin-bottom: 20px;
}

.check-box-txt {
    display: flex;
    margin-right: 174px;
    margin-top: 10px;
}

.bic-checkbox-txt {
    color: #3d3d3d;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
}

.check-box-bic {
    padding: 10px;
}

input[type=checkbox] {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-top: 5px;
    margin-right: 10px;
}

.contact-btn-bic-wrapper {
    border-radius: 30px;
    border: .0625rem solid #333;
    display: flex;
    height: 50px;
    width: 132px;
    background-color: #151515;
}

.contact-btn-bic-submit-txt {
    padding-top: 11px;
    padding-left: 21px;
    color: white;
}

.right-arrow-span-contact-bic {
    background-color: white;
    border-radius: 100px;
    margin-left: 25px;
    height: 40px;
    width: 40px;
    margin-top: 3px;
}

.right-arrow-contact-bic {
    width: 30px;
    height: 30px !important;
    padding-left: 4px;
    margin-top: 5px;
    padding: 3px 3px 3px 6px;
}

.contact-btn-bic-wrapper:hover {
    background-color: white;
}

.contact-btn-bic-wrapper:hover .contact-btn-bic-submit-txt {
    color: black;
}

.contact-btn-bic-wrapper:hover .right-arrow-span-contact-bic {
    background-color: #151515;
}

.contact-btn-bic-wrapper:hover .right-arrow-contact-bic {
    filter: invert(1);
}

.validation-message {
    color: red;
}

.invalid {
    background-color: rgba(242, 44, 43, 0.15);
}

option {
    background-color: rgb(245, 246, 251)
}

.terms-validation {
    margin-top: -30px;
    margin-left: 10px;
}

select {
    border-right: 16px solid transparent !important
}

::placeholder {
    font-size: 14px;
}

.right-colums {
    padding-left: 70px;

}

@media (min-width: 1400px) and (max-width: 1440px) {
    .custom-container-form-bic {
        padding: 30px;
        border-radius: 30px;
        max-width: 1300px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 370px;
    }
    .selected-value {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        background-color: rgb(245, 246, 251);
        width: 370px !important;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 370px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .invalid {
        background-color: rgba(242, 44, 43, 0.15) !important;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 745px;
        height: 140px;
    }
    .dropdown-options {
        position: absolute;
        top: 110%;
        left: 0;
        z-index: 1;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #f5f6fb;
        border-radius: 4px;
        width: 370px !important;
    }
}
@media (min-width: 1280px) and (max-width: 1399px) {
    .bic-form-heading {
        font-size: 42px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 1px white;
    }
    .custom-container-form-bic {
        padding: 30px;
        border-radius: 30px;
        max-width: 1300px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 280px;
    }
    .right-colums {
        padding-left: 20px;
    }
    .selected-value {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        background-color: rgb(245, 246, 251);
        width: 280px !important;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 280px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .invalid {
        background-color: rgba(242, 44, 43, 0.15) !important;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 555px;
        height: 140px;
    }
    .dropdown-options {
        position: absolute;
        top: 110%;
        left: 0;
        z-index: 1;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #f5f6fb;
        border-radius: 4px;
        width: 280px;
    }
    .dis-flex {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        position: relative;
        left: -50px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 174px;
        margin-top: 10px;
        margin-left: 60px;
    }
}
@media (min-width: 1024px) and (max-width: 1279px) {
    .bic-form-heading {
        font-size: 42px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 1px white;
    }
    .custom-container-form-bic {
        padding: 30px;
        border-radius: 30px;
        max-width: 1300px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 280px;
    }
    .right-colums {
        padding-left: 20px;
    }
    .selected-value {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        background-color: rgb(245, 246, 251);
        width: 280px !important;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 280px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .invalid {
        background-color: rgba(242, 44, 43, 0.15) !important;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 555px;
        height: 140px;
    }
    .dropdown-options {
        position: absolute;
        top: 110%;
        left: 0;
        z-index: 1;
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #f5f6fb;
        border-radius: 4px;
        width: 280px;
    }
}
@media (max-width: 1080px) {
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 300px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 635px;
        height: 140px;
    }
    .right-colums {
        padding-left: 20px;
    }
    .selected-value {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        background-color: rgb(245, 246, 251);
        width: 300px !important;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 300px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .invalid {
        background-color: rgba(242, 44, 43, 0.15) !important;
    }
    .tab-view-bic-input {
        margin-left: -12px;
    }
}

@media (max-width: 810px) {
    .contact-form-BIC-row {
        flex-direction: column;
    }
    .contact-form-BIC-leftside {
        width: 100%;
    }
    .contact-form-BIC-rightside {
        width: 100%;
    }
    .right-colums {
        padding-left: 30px;
    }
    .tab-view-bic-input {
        margin-left: -30px;
    }
    .pf-25 {
        padding-left: 40px;
    }
    .p-10{
        padding: 10px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 690px;
        height: 140px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 35px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 174px;
        margin-top: 10px;
        margin-left: 10px;
    }
}
@media (min-width: 768px) and (max-width: 1023px){
    .bic-form-heading {
        font-size: 40px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 1px white;
        line-height: 1.2;
    }
    .contact-form-BIC-row {
        flex-direction: column;
    }
    .contact-form-BIC-leftside {
        width: 100%;
    }
    .contact-form-BIC-rightside {
        width: 100%;
    }
    .right-colums {
        padding-left: 30px;
    }
    .tab-view-bic-input {
        margin-left: -30px;
    }
    .pf-25 {
        padding-left: 29px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 655px;
        height: 140px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 35px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 174px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .right-arrow-span-contact-bic {
        background-color: white;
        border-radius: 100px;
        margin-left: 20px;
        height: 40px;
        width: 40px;
        margin-top: 3px;
    }
.contact-btn-bic-submit-txt {
    padding-top: 11px;
    padding-left: 15px;
    color: white;
}
}
@media (min-width: 600px) and (max-width: 767px){
    .contact-form-BIC-row {
        flex-direction: column;
    }
    .contact-form-BIC-leftside {
        width: 100%;
    }
    .contact-form-BIC-rightside {
        width: 100%;
    }
    .right-colums {
        padding-left: 30px;
    }
    .tab-view-bic-input {
        margin-left: -30px;
    }
    .pf-25 {
        padding-left: 6px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 655px;
        height: 140px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 35px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 174px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 260px;
    }
    .selected-value {
        width: 260px !important;
    }
    .dropdown-options {
        width: 260px !important;
    }
    .name-of-organization {
        margin-left: -20px;
        margin-bottom: 20px;
    }
    .message-txt {
        margin-left: -20px;
        margin-bottom: 20px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 534px;
        height: 140px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 34px;
        margin-top: 0px;
        margin-left: 10px;
    }
    .bic-form-heading {
        font-size: 35px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 1px white;
        line-height: 1.2;
    }
    .bic-form-left-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
}
@media (min-width: 425px) and (max-width: 599px){
    .bic-form-heading {
        font-size: 35px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
        line-height: 1.2;
    }
    .pf-25 {
        padding-left: 10px;
    }
    .dis-flex {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .right-colums {
        padding-left: 40px;
    }
    .input-bic {
        margin-bottom: 15px;
    }
    .name-of-organization {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 370px;
        height: 140px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 370px;
    }
    .selected-value {
        width: 370px !important;
    }
    .dropdown-options {
        width: 370px !important;
    }
    .border-bic {
        border-top: 1px solid rgb(71, 80, 95);
        opacity: 0.2;
        width: 98.5%;
        margin-left: -27px;
        margin-bottom: 20px;
    }
    .mobile-terms-check {
        display: flex;
        flex-direction: row;
    }
    .check-box-txt {
        display: flex;
        margin-right: 54px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 4px;
        margin-top: 0px;
        margin-left: -24px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 20px;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 370px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .terms-validation {
        margin-top: -30px;
        margin-left: -30px;
    }
    .tab-view-bic-input {
        margin-left: -30px;
        margin-top: -20px;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 0px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        left: 40px;
        right: 0px;
        position: relative;
        z-index: 181;
        width: fit-content;
    }
    .contact-form-BIC {
        background-color: rgba(243, 247, 250, 1);
        padding-bottom: 50px;
        padding-top: 0px;
    }
    .bic-form-left-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-container-form-bic {
        padding: 50px 20px 0px 20px;
        border-radius: 30px;
        max-width: 1500px;
    }
}
@media (min-width: 375px) and (max-width: 424px){
    .bic-form-heading {
        font-size: 32px;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
        line-height: 1.2;
    }
    .pf-25 {
        padding-left: 10px;
    }
    .dis-flex {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .right-colums {
        padding-left: 40px;
    }
    .input-bic {
        margin-bottom: 15px;
    }
    .name-of-organization {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 320px;
        height: 140px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 320px;
    }
    .selected-value {
        width: 320px !important;
    }
    .dropdown-options {
        width: 320px !important;
    }
    .border-bic {
        border-top: 1px solid rgb(71, 80, 95);
        opacity: 0.2;
        width: 98.5%;
        margin-left: -27px;
        margin-bottom: 20px;
    }
    .mobile-terms-check {
        display: flex;
        flex-direction: column;
    }
    .check-box-txt {
        display: flex;
        margin-right: 54px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 4px;
        margin-top: 0px;
        margin-left: -24px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 20px;
        margin-left: 130px;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 320px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .tab-view-bic-input {
        margin-left: -30px;
        margin-top: -20px;
    }
    .terms-validation {
        margin-top: -96px;
        margin-left: -10px;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        left: 40px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        z-index: 181;
        width: fit-content;
        right: 0px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 20px;
        margin-left: -30px;
    }
    .contact-form-BIC {
        background-color: rgba(243, 247, 250, 1);
        padding-bottom: 50px;
        padding-top: 0px;
    }
    .bic-form-left-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-container-form-bic {
        padding: 50px 20px 0px 20px;
        border-radius: 30px;
        max-width: 1500px;
    }
}
@media (min-width: 360px) and (max-width: 374px) {
    .bic-form-heading {
        font-size: 2rem;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
        line-height: 1.2;
    }
    .pf-25 {
        padding-left: 10px;
    }
    .dis-flex {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .right-colums {
        padding-left: 40px;
    }
    .input-bic {
        margin-bottom: 15px;
    }
    .name-of-organization {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 325px;
        height: 140px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 325px;
    }
    .selected-value {
        width: 325px !important;
    }
    .dropdown-options {
        width: 325px !important;
    }
    .border-bic {
        border-top: 1px solid rgb(71, 80, 95);
        opacity: 0.2;
        width: 98.5%;
        margin-left: -27px;
        margin-bottom: 20px;
    }
    .mobile-terms-check {
        display: flex;
        flex-direction: column;
    }
    .check-box-txt {
        display: flex;
        margin-right: 54px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 4px;
        margin-top: 0px;
        margin-left: -24px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 20px;
        margin-left: 80px;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 295px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .tab-view-bic-input {
        margin-left: -30px;
        margin-top: -20px;
    }
    .terms-validation {
        margin-top: -96px;
        margin-left: -10px;
    }
    .selected-value-invalid {
        width: 265px !important;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 20px;
        margin-left: -30px;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        left: 40px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        z-index: 181;
        right: 0px;
        width: fit-content;
    }
    .contact-form-BIC {
        background-color: rgba(243, 247, 250, 1);
        padding-bottom: 50px;
        padding-top: 0px;
    }
    .bic-form-left-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-container-form-bic {
        padding: 50px 20px 0px 20px;
        border-radius: 30px;
        max-width: 1500px;
    }
}
@media (min-width: 300px) and (max-width: 359px) {
    .bic-form-heading {
        font-size: 2rem;
        color: black;
        font-weight: bold;
        -webkit-text-stroke: 0px white;
        line-height: 1.2;
    }
    .pf-25 {
        padding-left: 10px;
    }
    .dis-flex {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .right-colums {
        padding-left: 40px;
    }
    .input-bic {
        margin-bottom: 15px;
    }
    .name-of-organization {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt {
        margin-left: -30px;
        margin-bottom: 20px;
    }
    .message-txt-textarea {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        border-radius: 10px;
        padding: 16px 20px;
        width: 265px;
        height: 140px;
    }
    .bic-input {
        border: none;
        cursor: pointer;
        color: rgb(38, 42, 57);
        background-color: rgb(245, 246, 251);
        height: 50px;
        border-radius: 10px;
        padding: 15px 16px;
        width: 265px;
    }
    .selected-value {
        width: 265px !important;
    }
    .dropdown-options {
        width: 265px !important;
    }
    .border-bic {
        border-top: 1px solid rgb(71, 80, 95);
        opacity: 0.2;
        width: 98.5%;
        margin-left: -27px;
        margin-bottom: 20px;
    }
    .mobile-terms-check {
        display: flex;
        flex-direction: column;
    }
    .check-box-txt {
        display: flex;
        margin-right: 54px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .check-box-txt {
        display: flex;
        margin-right: 4px;
        margin-top: 0px;
        margin-left: -24px;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 20px;
        margin-left: 80px;
    }
    .selected-value-invalid {
        cursor: pointer;
        padding: 13px;
        border-radius: 10px;
        width: 295px !important;
        background-color: rgba(242, 44, 43, 0.15);
    }
    .tab-view-bic-input {
        margin-left: -30px;
        margin-top: -20px;
    }
    .terms-validation {
        margin-top: -96px;
        margin-left: -10px;
    }
    .selected-value-invalid {
        width: 265px !important;
    }
    .contact-btn-bic-wrapper {
        border-radius: 30px;
        border: .0625rem solid #333;
        display: flex;
        height: 50px;
        width: 132px;
        background-color: #151515;
        margin-right: 20px;
        margin-left: -30px;
    }
    .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        left: 40px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        z-index: 181;
        right: 0px;
        width: fit-content;
    }
    .contact-form-BIC {
        background-color: rgba(243, 247, 250, 1);
        padding-bottom: 50px;
        padding-top: 0px;
    }
    .bic-form-left-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .custom-container-form-bic {
        padding: 50px 20px 0px 20px;
        border-radius: 30px;
        max-width: 1500px;
    }
}