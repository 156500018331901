.about-projects-container {
    padding-bottom: 100px;
}
.project-heading{
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.project-txt{
    font-size: 21px;
    line-height: 28px;
    text-align: justify;
    color: rgba(61, 61, 61);
    font-weight: 400;
    
}
.project-btn-1{
    padding: 11px 30px;
    border-radius: 30px;
    background-color: #2ba4c3;
    border: 1px solid transparent;
    color: white;
}
.project-btns-wrapper{
    display: flex;
    justify-content: space-between;
}
.all-project-btn{
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    height: 50px;
    width: 190px;
    cursor: pointer;
}
.all-project-btn-txt{
    padding-top: 11px;
    padding-left: 21px;
}
.right-arrow-span {
    background-color: #2ba4c3;
    border-radius: 100px;
    margin-left: 28px;
    height: 40px;
    width: 40px;
    margin-top: 4px;
}
.right-arrow{
    width: 30px;
    height: 30px !important;
    padding-left: 12px;
    padding-top: 10px;
    filter: invert(1);
}
.wrapper-row{
    padding-top: 50px;
    padding-bottom: 50px;
}
.projectcard-img{
    width: 100%;
    height: 210px !important;
}
.text-wrapper {
    padding-top: 100px;
    padding-bottom: 0px;
}
.card-text{
    font-size: 21px;
    line-height: 28px;
    color: rgba(61, 61, 61);

}
.custom-project-card{
    width: 26rem;
}
.all-project-btn:hover{
    background-color: #2ba4c3;
}
.all-project-btn:hover .right-arrow-span{
    background-color: white;
}
.all-project-btn:hover .right-arrow{
    filter: invert(0);
}
.all-project-btn:hover .all-project-btn-txt{
    color: white;
}
.all-exhibition{
    color: rgba(61, 61, 61);
    text-decoration: none;
}
@media (min-width: 1080px) and (max-width: 1399px) {
    .custom-project-card{
        width: 19rem;
    }
}

@media (min-width: 1024px) and (max-width: 1079px) {
    .custom-project-card{
        width: 19rem;
    }
}
@media (min-width: 810px) and (max-width: 1023px) {
    .custom-project-card {
        width: 14rem;
    }
    .projectcard-img {
        width: 100%;
        height: 140px !important;
    }
    .text-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .wrapper-row {
        padding-top: 0px;
        padding-bottom: 50px;
    }
    .project-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .about-projects-container {
        padding-bottom: 50px;
    }
    .card-text {
        font-size: 16px;
        line-height: 28px;
        color: rgba(61, 61, 61);
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .custom-project-card {
        width: 14rem;
    }
    .projectcard-img {
        width: 100%;
        height: 140px !important;
    }
    .text-wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .wrapper-row {
        padding-top: 0px;
        padding-bottom: 50px;
    }
    .project-heading {
        -webkit-text-stroke: 1px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .about-projects-container {
        padding-bottom: 50px;
    }
    .card-text {
        font-size: 16px;
        line-height: 28px;
        color: rgba(61, 61, 61);
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .project-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 0px 0px #b8bfd6;
    }
    .text-wrapper {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .wrapper-row {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .custom-project-row{
        flex-direction: column;
    }
    .custom-project-card {
        width: 23.5rem;
        margin: 10px 0px;
    }
    .about-projects-container {
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .project-txt {
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
        color: rgba(61, 61, 61);
        font-weight: 400;
    }
    .card-text {
        font-size: 18px;
        line-height: 28px;
        color: rgba(61, 61, 61);
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .project-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 0px 0px #b8bfd6;
    }
    .text-wrapper {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .wrapper-row {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .custom-project-row{
        flex-direction: column;
    }
    .custom-project-card {
        width: 22rem;
        margin: 10px 0px;
    }
    .projectcard-img {
        width: 100%;
        height: 180px !important;
    }
    .about-projects-container {
        padding-bottom: 50px;
    }
    .project-txt {
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
        color: rgba(61, 61, 61);
        font-weight: 400;
    }
    .card-text {
        font-size: 18px;
        line-height: 28px;
        color: rgba(61, 61, 61);
    }
}
@media (min-width: 360px) and (max-width: 374px) {
    .project-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .text-wrapper {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .wrapper-row {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .custom-project-row{
        flex-direction: column;
    }
    .custom-project-card {
        width: 20.5rem;
        margin: 10px 4px;
    }
    .projectcard-img {
        width: 100%;
        height: 170px !important;
    }
    .about-projects-container {
        padding-bottom: 50px;
    }
    .project-txt {
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
        color: rgba(61, 61, 61);
        font-weight: 400;
    }
    .card-text {
        font-size: 18px;
        line-height: 28px;
        color: rgba(61, 61, 61);
    }
}
@media (min-width: 300px) and (max-width: 359px) {
    .project-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .text-wrapper {
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .wrapper-row {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .custom-project-row{
        flex-direction: column;
    }
    .custom-project-card {
        width: 18rem;
        margin: 10px 4px;
    }
    .projectcard-img {
        width: 100%;
        height: 170px !important;
    }
    .about-projects-container {
        padding-bottom: 50px;
    }
    .project-txt {
        font-size: 18px;
        line-height: 28px;
        text-align: justify;
        color: rgba(61, 61, 61);
        font-weight: 400;
    }
    .card-text {
        font-size: 18px;
        line-height: 28px;
        color: rgba(61, 61, 61);
    }
}