.customer-brands-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
}
.custom-brand-container{
  background-image: url(../images/customerbrands/brandsgradiantbg.webp);
  padding-top: 30px;
  padding-bottom: 19px;
}
.logo-customize-container{
  display: flex;
    justify-content: center;
}
.brand-title-wrapper{
  text-align: center;
  padding-bottom: 100px;
}
.our-brand-txt{
  -webkit-text-stroke: 1px white;
  color: black;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}

.flip-box {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
  margin-right: 0px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  color: black;
}
.flip-box-front img {
  height: 100%;
}
.flip-box-back {
  color: white;
  transform: rotateY(180deg);
}
.flip-boxes-wrapper{
  display: flex;
}
.logo-img-box {
  width: 200px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 30px;
}
.other-three{
  display: flex;
    justify-content: center;
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .flip-box {
    background-color: transparent;
    width: 140px;
    height: 140px;
    perspective: 1000px;
    margin-right: 10px;
}
.logo-img-box {
  width: 140px;
  padding-bottom: 10px;
  padding-right: 0px;
  border-radius: 30px;
}

.custom-brand-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: bottom;
}

}
@media (min-width: 768px) and (max-width: 1023px) {
  .flip-box {
    background-color: transparent;
    width: 100px;
    height: 100px;
    perspective: 1000px;
    margin-right: 10px;
}
.logo-img-box {
  width: 100px;
  padding-bottom: 10px;
  padding-right: 0px;
  border-radius: 30px;
}
.custom-brand-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: bottom;
}
.our-brand-txt {
  -webkit-text-stroke: 1px white;
  color: black;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.customer-brands-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
.brand-title-wrapper {
  text-align: center;
  padding-bottom: 50px;
}
}
@media (min-width: 425px) and (max-width: 767px) {
  .flip-boxes-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 10px;
}
.our-brand-txt {
  -webkit-text-stroke: 0px white;
  color: black;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.custom-brand-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: top;
  background-attachment: fixed;
}
.our-brand-txt {
  -webkit-text-stroke: 0px white;
  color: black;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.customer-brands-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
.brand-title-wrapper {
  text-align: center;
  padding-bottom: 50px;
}
.logo-img-box {
  width: 180px;
  padding-bottom: 10px;
  padding-right: 0px;
  border-radius: 30px;
}
.flip-box {
  background-color: transparent;
  width: 180px;
  height: 180px;
  perspective: 1000px;
  margin-right: 10px;
}
}
@media (min-width: 360px) and (max-width: 424px) {
  .flip-boxes-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 10px;
}
.our-brand-txt {
  -webkit-text-stroke: 0px white;
  color: black;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.custom-brand-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: top;
  background-attachment: fixed;
}
.our-brand-txt {
  -webkit-text-stroke: 0px white;
  color: black;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.customer-brands-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
.brand-title-wrapper {
  text-align: center;
  padding-bottom: 50px;
}
.logo-img-box {
  width: 140px;
  padding-bottom: 10px;
  padding-right: 0px;
  border-radius: 30px;
}
.flip-box {
  background-color: transparent;
  width: 140px;
  height: 140px;
  perspective: 1000px;
  margin-right: 10px;
}
}
@media (min-width: 300px) and (max-width: 359px) {
  .flip-boxes-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 10px;
}
.our-brand-txt {
  -webkit-text-stroke: 0px white;
  color: black;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.custom-brand-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: top;
  background-attachment: fixed;
}
.our-brand-txt {
  -webkit-text-stroke: 0px white;
  color: black;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
}
.customer-brands-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}
.brand-title-wrapper {
  text-align: center;
  padding-bottom: 50px;
}
.logo-img-box {
  width: 130px;
  padding-bottom: 10px;
  padding-right: 0px;
  border-radius: 30px;
}
.flip-box {
  background-color: transparent;
  width: 130px;
  height: 130px;
  perspective: 1000px;
  margin-right: 10px;
}
}