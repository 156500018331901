.fzf-container{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    padding: 60px 0px;
    overflow: hidden;
}
.heading-fzf{
    -webkit-text-stroke: 1px #fff;
    color: #000;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.txt-fzf{
    font-size: 21px;
    color: rgb(65,65,65);
}
.btn-fzf{
    background-color: white;
    display: flex;
    padding: 10px;
    border: 1px solid black;
    border-radius: 30px;
}
.back-to-home-fzf{
    text-decoration: none;
    color: black;
    padding: 5px;
    font-size: 18px;
    padding-right: 10px;
}
.right-arrow-four-zero-four{
    width: 30px;
    height: 30px !important;
    filter: invert(1);
}
.right-arrow-four-zero-four-wrapper{
    background-color: #2ba4c3;
    border-radius: 30px;
    padding: 5px;
}
.btn-fzf:hover{
    background-color: #2ba4c3;
}
.btn-fzf:hover .right-arrow-four-zero-four-wrapper{
    background-color: white;
}
.btn-fzf:hover .right-arrow-four-zero-four{
    filter: invert(0);
}
.btn-fzf:hover .back-to-home-fzf{
    color: white;
}
.fzf-image{
    width: 900px;
}
.fzf-content-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 425px) and (max-width: 600px) {
    .fzf-image {
        width: 400px;
    }
    .heading-fzf {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .fzf-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px;
    }
    .txt-fzf {
        font-size: 16px;
        color: rgb(65, 65, 65);
    }
    .fzf-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 200px 0px 0px 0px;
        overflow: hidden;
    }
}
@media (min-width: 393px) and (max-width: 424px) {
    .fzf-image {
        width: 400px;
    }
    .heading-fzf {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .fzf-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
    }
    .txt-fzf {
        font-size: 16px;
        color: rgb(65, 65, 65);
    }
    .fzf-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 200px 0px 0px 0px;
        overflow: hidden;
    }
}
@media (min-width: 375px) and (max-width: 392px) {
    .fzf-image {
        width: 400px;
    }
    .heading-fzf {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .fzf-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
    }
    .txt-fzf {
        font-size: 16px;
        color: rgb(65, 65, 65);
    }
    .fzf-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 200px 0px 0px 0px;
        overflow: hidden;
    }
}
@media (min-width: 360px) and (max-width: 374px) {
    .fzf-image {
        width: 400px;
    }
    .heading-fzf {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .fzf-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
    }
    .txt-fzf {
        font-size: 16px;
        color: rgb(65, 65, 65);
    }
    .fzf-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 200px 0px 0px 0px;
        overflow: hidden;
    }
    .fzf-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
}
@media (min-width: 300px) and (max-width: 359px) {
    .fzf-image {
        width: 400px;
    }
    .heading-fzf {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .fzf-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
    }
    .txt-fzf {
        font-size: 14px;
        color: rgb(65, 65, 65);
    }
    .fzf-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 200px 0px 0px 0px;
        overflow: hidden;
    }
    .fzf-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
}