.footer-cointainer {
    background-color: black;
    padding: 65px 0px;
    position: relative;
    z-index: 0;
    bottom: 0px;
}
.sub-main-footer-container{
    max-width: 1536px;
}
.footer-contact-btn {
    height: 45px;
    width: 148px;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 500;
    color: #2ba4c3;
    padding-left: 25px;
    padding-top: 10px;
    text-decoration: none;
}
.img-footer-contact{
    width: 40px;
    padding: 9px;
    filter: invert(1);
}
.img-footer-contat-span{
    border-radius: 100px;
    background-color: #2ba4c3;
    margin: 5px;
}
.footer-button-icon-wrapper {
    background-color: #ebebeb;
    height: 50px;
    width: 200px;
    display: flex;
    padding: 0px;
    border-radius: 30px;
    margin-top: 30px;
}
.footer-ul-list{
    display: flex;
    list-style-type: none;
    padding-top: 15px;
    align-items: center;
    text-align: center;
    justify-content: right;
    color: white;
}
.footer-list{
    padding: 0 24px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.footer-title{
    font-size: 32px;
    color: #2ba4c3;
    font-weight: bold;
    -webkit-text-stroke: 1px #000;
}
.img-footer-icon-latterc{
    filter: invert(1);
    height: 30px;
    padding-right: 4px;
    margin-top: 3px;
    width: 23px;
    margin-left: 0;
}
.footer-txt{
    color: white;
    line-height: 15px;
}
.footer-two{
    padding-top: 15px;
}
.follow-us{
    display: flex;
}
.follow-icons{
    padding-right: 30px;
}
.img-footer-icon{
    height: 30px;
    width: 30px;
    padding-right: 7px;
    filter: invert(1);
    margin-top: 10px;
}
.img-txt{
    display: flex;
}
.line-span {
    display: block;
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 10px 0;
}  
.footer-one{
    padding-bottom: 25px;
}
.followus-div{
    padding-left: 70px;
}
.pt-15{
    padding-top: 15px;
}
/* .footer-contact-btn:hover{
    background-color: #2ba4c3;
    color: white;
} */
svg.footer-social-media-icon {
    filter: brightness(1.5);
}
.footer-title-info{
    font-size: 28px;
    color: white;
    font-weight: bold;
    -webkit-text-stroke: 1px #000;
}
.footer-button-icon-wrapper:hover{
    background-color: #2ba4c3;
}
.footer-button-icon-wrapper:hover .footer-contact-btn{
  color: white;
}
.footer-button-icon-wrapper:hover .img-footer-contat-span{
    background-color: white;
}
.footer-button-icon-wrapper:hover .img-footer-contact{
    filter: invert(0);
}
.follow-icons-youtube svg{
    width: 55px;
    height: 70px;
    margin-top: 10px;
    margin-right: 30px;
}
.follow-icons svg {
    width: 55px;
    height: 70px;
    margin-top: 0px;
    margin-right: 30px;
}
.mf-50{
    margin-left: -50px;
}
.follow-icon-instagram svg{
    width: 55px;
    height: 70px;
    margin-top: 0px;
    margin-right: 30px;
}
@media (min-width: 1300px) and (max-width: 1440px) {
    .footer-cointainer {
        background-color: black;
        padding: 60px 60px;
        position: relative;
        z-index: 11;
    }
    .followus-div {
        padding-left: 70px;
        width: 20%;
    }
    .footer-list {
        padding: 0 14px;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        color: white;
        cursor: pointer;
    }
}
@media (min-width: 1024px) and (max-width: 1299px) {
    .footer-sub-one {
        width: 35%;
        padding-left: 30px;
    }
    .footer-sub-two.mf-50 {
        width: 40%;
        margin-left: 0px;
        padding-left: 50px;
    }
    .footer-sub-three {
        padding-left: 0px;
        padding-top: 0px;
    }
    .footer-ul-list {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding-top: 0px;
        align-items: center;
        text-align: center;
        justify-content: right;
        color: white;
        position: relative;
        top: 0px;
        left: 0px;
    }
    .footer-list {
        padding: 0 3px;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        color: white;
        cursor: pointer;
    }
    .rapid-bevtech-pvt {
        position: absolute;
        left: 30px;
        bottom: 10px;
    }
    .footer-cointainer {
        background-color: black;
        padding: 90px 0px;
        position: relative;
        z-index: 11;
        padding-bottom: 0px;
    }
    ul.footer-ul-list li {
        margin: 5px;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .footer-sub-one {
        width: 40%;
        padding-left: 20px;
    }
    .footer-sub-two.mf-50 {
        width: 40%;
        margin-left: 0px;
        padding-left: 15px;
    }
    .footer-sub-three{
        padding-left: 0px;
        padding-top: 0px;
    }
    .footer-ul-list {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding-top: 0px;
        margin-bottom: 0px;
        align-items: center;
        text-align: left;
        justify-content: right;
        color: white;
        position: relative;
        left: 8px;
    }
    .footer-list {
        padding: 0;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;
        color: white;
        cursor: pointer;
    }
    .footer-two .col-3{
        flex: 0 0 auto;
    width: 26%;
    padding: 0px !important; 
    }
    .footer-txt {
        color: white;
        line-height: 26px;
        font-size: 15px;
    }
    .footer-two .col-9 {
        flex: 0 0 auto;
        width: 74%;
        padding: 0px;
        align-items: center;
        display: contents;
    }
    .rapid-bevtech-pvt {
        position: relative;
        left: 20px;
    }
    .footer-cointainer {
        background-color: black;
        padding: 90px 0px;
        position: relative;
        z-index: 11;
        padding-bottom: 0px;
    }
    ul.footer-ul-list li {
        margin: 5px;
    }
    p.footer-title {
        font-size: 28px;
    }
    .footer-title-info{
        font-size: 20px;
    }
    .img-footer-icon{
        margin-top: 0px;
    }
    .img-txt {
        display: flex;
        align-items: center;
    }
    .follow-icons-youtube svg {
        width: 49px;
        height: 69px;
        margin-top: 10px;
        margin-right: 15px;
    }
    .follow-icon-instagram svg {
        width: 55px;
        height: 70px;
        margin-top: 0px;
        margin-right: 15px;
    }
    .follow-icons {
        padding-right: 0px;
    }
}
@media (min-width: 600px) and (max-width: 767px) {
    .footer-sub-one {
        width: 100%;
        padding-left: 50px;
        padding-bottom: 20px;
    }
    .footer-sub-two.mf-50 {
        width: 50%;
        padding-left: 50px;
        margin-left: 0px;
    }
    .followus-div {
        padding-left: 52px;
    }
    .footer-two {
        padding-top: 15px;
        display: flex;
        flex-direction: column-reverse;
    }
    .footer-ul-list {
        display: flex;
        list-style-type: none;
        padding-top: 15px;
        align-items: start;
        text-align: left;
        justify-content: left;
        color: white;
        flex-direction: column;
        position: relative;
        left: -10px;
    }
    ul.footer-ul-list li {
        margin-top: 10px;
    }
    .img-txt.rapid-bevtech-pvt {
        position: relative;
        left: 330px;
        bottom: 60px;
        width: 180%;
        overflow-x: hidden;
    }
}
@media (min-width: 425px) and (max-width: 599px) {
    .footer-sub-one {
        width: 100%;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    .footer-sub-two.mf-50 {
        width: 100%;
        padding-left: 20px;
        margin-left: 0px;
    }
    .followus-div {
        padding-left: 20px;
        padding-top: 30px;
        width: 100%;
    }
    .footer-ul-list {
        display: flex;
        list-style-type: none;
        padding-top: 15px;
        align-items: start;
        text-align: left;
        justify-content: left;
        color: white;
        flex-direction: column;
        position: relative;
        left: -50px;
    }
    .footer-two {
        padding-top: 15px;
        display: flex;
        flex-direction: column-reverse;
    }
    .img-txt.rapid-bevtech-pvt {
        position: relative;
        width: 290%;
        left: 5px;
    }
    ul.footer-ul-list li {
        margin-top: 5px;
    }
    .footer-two {
        padding-top: 0px;
        display: flex;
        flex-direction: column-reverse;
    }
}
@media (min-width: 300px) and (max-width: 424px) {
    .footer-title {
        font-size: 25px;
        color: #2ba4c3;
        font-weight: bold;
        -webkit-text-stroke: 1px #000;
    }
    .footer-title-info {
        font-size: 23px;
        color: white;
        font-weight: bold;
        -webkit-text-stroke: 1px #000;
    }
    .footer-sub-one {
        width: 100%;
        padding-left: 21px;
        padding-bottom: 20px;
    }
    .footer-sub-two.mf-50 {
        width: 100%;
        padding-left: 21px;
        margin-left: 0px;
    }
    .followus-div {
        padding-left: 21px;
        padding-top: 30px;
        width: 100%;
    }
    .footer-ul-list {
        display: flex;
        list-style-type: none;
        padding-top: 15px;
        align-items: start;
        text-align: left;
        justify-content: left;
        color: white;
        flex-direction: column;
        position: relative;
        left: -45px;
    }
    .footer-two {
        padding-top: 15px;
        display: flex;
        flex-direction: column-reverse;
    }
    .img-txt.rapid-bevtech-pvt {
        position: relative;
        width: 320%;
        left: 7px;
    }
    ul.footer-ul-list li {
        margin-top: 5px;
    }
    .footer-two {
        padding-top: 0px;
        display: flex;
        flex-direction: column-reverse;
    }
}
@media (width: 1080px) {
    .footer-sub-one {
        width: 33%;
        padding-left: 20px;
    }
    .footer-sub-two.mf-50 {
        width: 40%;
        margin-left: 0px;
        padding-left: 85px;
    }
    .footer-sub-three{
        padding-left: 40px;
        padding-top: 0px;
    }
    .footer-ul-list {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding-top: 0px;
        margin-bottom: 0px;
        align-items: center;
        text-align: left;
        justify-content: right;
        color: white;
        position: relative;
        left: 28px;
        top: 0px;
    }
    .footer-list {
        padding: 0;
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
        color: white;
        cursor: pointer;
        padding-left: 15px;
    }
    .footer-two .col-3{
        flex: 0 0 auto;
    width: 26%;
    padding: 0px !important; 
    }
    .footer-txt {
        color: white;
        line-height: 26px;
        font-size: 15px;
    }
    .footer-two .col-9 {
        flex: 0 0 auto;
        width: 74%;
        padding: 0px;
        align-items: center;
        display: contents;
    }
    .rapid-bevtech-pvt {
        position: relative;
        left: 20px;
    }
    .footer-cointainer {
        background-color: black;
        padding: 90px 0px;
        position: relative;
        z-index: 11;
        padding-bottom: 0px;
    }
    ul.footer-ul-list li {
        margin: 5px;
    }
    p.footer-title {
        font-size: 28px;
    }
    .footer-title-info{
        font-size: 20px;
    }
    .img-footer-icon{
        margin-top: 0px;
    }
    .img-txt {
        display: flex;
        align-items: center;
    }
    .follow-icons-youtube svg {
        width: 49px;
        height: 69px;
        margin-top: 10px;
        margin-right: 15px;
    }
    .follow-icon-instagram svg {
        width: 55px;
        height: 70px;
        margin-top: 0px;
        margin-right: 15px;
    }
    .footer-two {
        padding-top: 15px;
        padding-bottom: 50px;
    }
}