.pop-up-container {
    max-width: 100%;
    position: relative;
    top: 0px;
}
.pop-up-container-wrapper {
    padding: 120px 200px;
    width: 100%;
    background-color: #2ea5c430;
    height: 100vh;
    overflow: hidden;
}
.pop-up-label-txt{
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
}
.input-pop-up {
    border: none;
    border-bottom: 1px solid #555;
    background-color: transparent;
    width: 100%;
    margin-bottom: 20px;
}
.pop-up-form {
    padding: 0px 30px;
    width: 620px;
}
.pop-up-contact-form-wrapper{
    display: flex;
    justify-content: center;
}
.left-side-heading-txt-pop-up{
    font-size: 55px;
    font-weight: 500;
    width: 400px;
}
.pop-up-txt-left-p{
    font-size: 24px;
}
.pop-up-link{
   color: black;
   text-decoration: none;
   border-bottom: 1px solid #2ba4c3;
}
.select-pop-up{
    border: none;
    border-bottom: 1px solid #555;
    background-color: transparent;
    width: 100%;
    margin-bottom: 20px;
}
.option-pop-up{
    background-color: #2ea5c430;
}
.default-option{
    color: #555;
}
.checkbox-wrapper{
    display: flex;
}
.pop-up-submit-btn{
    display: flex;
    border: 1px solid black;
    border-radius: 30px;
    background-color: white;
    padding: 5px;
    margin-top: 20px;
}
.pop-up-right-arrow{
    width: 30px;
    filter: invert(1);
}
.pop-up-right-arrow-wrapper{
    background-color: #2ba4c3;
    border-radius: 30px;
    padding: 5px;
}
.pop-btn-txt {
    font-size: 16px;
    margin: 6px 18px 0px 10px;
}
.pop-up-submit-btn:hover {
    background-color: #2ba4c3;
}
.pop-up-submit-btn:hover .pop-up-right-arrow{
    filter: invert(0);
}
.pop-up-submit-btn:hover .pop-up-right-arrow-wrapper{
    background-color: white;
}
.pop-up-submit-btn:hover .pop-btn-txt{
    color: white;
}
.validation-message-pop-up {
    color: red;
    margin-top: -15px;
    margin-bottom: 3px;
}

.close-btn-wrapper-pop-up {
    width: 20px;
    height: 20px;
    position: relative;
    right: -230px;
    top: -100px;
}
.left-img-pop-up{
    width: 500px;
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
select {
    color: #555;
}
.close-btn-wrapper-pop-up img {
    width: 25px;
    height: 25px !important;
}
@keyframes FadeInUp {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-up {
    animation: FadeInUp 0.5s ease-out;
  }
  

@media (min-width: 1400px) and (max-width: 1440px) {
    .pop-up-container-wrapper {
        padding: 60px 150px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 20px;
        height: 20px;
        position: relative;
        right: -120px;
        top: -30px;
    }
}
@media (min-width: 1280px) and (max-width: 1399px) {
    .pop-up-container-wrapper {
        padding: 60px 50px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 20px;
        height: 20px;
        position: relative;
        right: -30px;
        top: -40px;
    }
    .left-img-pop-up {
        width: 400px;
    }
    .pop-up-submit-btn {
        display: flex;
        border: 1px solid black;
        border-radius: 30px;
        background-color: white;
        padding: 5px;
        margin-top: 0px;
    }
    .input-pop-up {
        border: none;
        border-bottom: 1px solid #555;
        background-color: transparent;
        width: 100%;
        margin-bottom: 10px;
    }
    .pop-up-label-txt {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 2px;
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .pop-up-container-wrapper {
        padding: 60px 50px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 30px;
        height: 20px;
        position: relative;
        right: -30px;
        top: -40px;
    }
    .left-img-pop-up {
        width: 450px;
    }
}

@media (min-width: 810px) and (max-width: 1079px) {
    .pop-up-container-wrapper {
        padding: 40px 20px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 30px;
        height: 20px;
        position: relative;
        right: 0px;
        top: -90px;
    }
    .left-img-pop-up {
        width: 450px;
    }
    .left-side-heading-txt-pop-up {
        font-size: 40px;
        font-weight: 500;
        width: 310px;
    }
    .left-img-pop-up {
        width: 330px;
    }
    .pop-up-txt-left-p {
        font-size: 18px;
    }
    .pop-up-container {
        max-width: 100%;
        position: relative;
        top: 70px;
    }
    .pop-up-form {
        padding: 0px 0px;
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .pop-up-container-wrapper {
        padding: 40px 20px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 30px;
        height: 20px;
        position: relative;
        right: 0px;
        top: -90px;
    }
    .left-img-pop-up {
        width: 450px;
    }
    .left-side-heading-txt-pop-up {
        font-size: 40px;
        font-weight: 500;
        width: 310px;
    }
    .left-img-pop-up {
        width: 330px;
    }
    .pop-up-txt-left-p {
        font-size: 18px;
    }
    .pop-up-container {
        max-width: 100%;
        position: relative;
        top: 70px;
    }
    .pop-up-form {
        padding: 0px 0px;
        width: auto;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .pop-up-container-wrapper {
        padding: 0px 20px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 30px;
        height: 20px;
        position: relative;
        right: -11px;
        top: -140px;
    }
    .left-img-pop-up {
        width: 450px;
    }
    .left-side-heading-txt-pop-up {
        font-size: 32px;
        font-weight: 500;
        width: 410px;
        margin-bottom: 0px;
    }
    .left-img-pop-up {
        width: 240px;
        display: none;
    }
    .pop-up-txt-left-p {
        font-size: 18px;
        margin-bottom: 0px;
    }
    .pop-up-container {
        max-width: 100%;
        position: relative;
        top: 110px;
    }
    .pop-up-form {
        padding: 0px 0px;
        width: auto;
    }
    .pop-up-row {
        display: flex;
        flex-direction: column;
    }
    .left-side-popup {
        width: 100%;
        position: relative;
        top: -60px;
    }
    .right-side-popup{
        width: 100%;
    }
    .pop-up-contact-form-wrapper {
        display: flex;
        justify-content: center;
        position: relative;
        top: -50px;
    }
    .pop-up-submit-btn {
        display: flex;
        border: 1px solid black;
        border-radius: 30px;
        background-color: white;
        padding: 5px;
        margin-top: 0px;
    }
    .input-pop-up {
        border: none;
        border-bottom: 1px solid #555;
        background-color: transparent;
        width: 100%;
        margin-bottom: 13px;
    }
    .pop-up-label-txt {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 3px;
    }
}

@media (min-width: 393px) and (max-width: 424px) {
    .pop-up-container-wrapper {
        padding: 0px 10px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 30px;
        height: 20px;
        position: relative;
        right: -5px;
        top: -143px;
    }
    .left-img-pop-up {
        width: 450px;
    }
    .left-side-heading-txt-pop-up {
        font-size: 32px;
        font-weight: 500;
        width: 410px;
        margin-bottom: 0px;
    }
    .left-img-pop-up {
        width: 240px;
        display: none;
    }
    .pop-up-txt-left-p {
        font-size: 18px;
        margin-bottom: 0px;
    }
    .pop-up-container {
        max-width: 100%;
        position: relative;
        top: 110px;
    }
    .pop-up-form {
        padding: 0px 0px;
        width: auto;
    }
    .pop-up-row {
        display: flex;
        flex-direction: column;
    }
    .left-side-popup {
        width: 100%;
        position: relative;
        top: -60px;
    }
    .right-side-popup{
        width: 100%;
    }
    .pop-up-contact-form-wrapper {
        display: flex;
        justify-content: center;
        position: relative;
        top: -50px;
    }
    .pop-up-submit-btn {
        display: flex;
        border: 1px solid black;
        border-radius: 30px;
        background-color: white;
        padding: 5px;
        margin-top: 0px;
    }
    .input-pop-up {
        border: none;
        border-bottom: 1px solid #555;
        background-color: transparent;
        width: 100%;
        margin-bottom: 10px;
    }
    .pop-up-label-txt {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 3px;
    }
}
@media (min-width: 375px) and (max-width: 392px) {
    .pop-up-container-wrapper {
        padding: 0px 10px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 30px;
        height: 20px;
        position: relative;
        right: -1px;
        top: -150px;
    }
    .left-img-pop-up {
        width: 450px;
    }
    .left-side-heading-txt-pop-up {
        font-size: 32px;
        font-weight: 500;
        width: 410px;
        margin-bottom: 0px;
    }
    .left-img-pop-up {
        width: 240px;
        display: none;
    }
    .pop-up-txt-left-p {
        font-size: 18px;
        margin-bottom: 0px;
    }
    .pop-up-container {
        max-width: 100%;
        position: relative;
        top: 120px;
    }
    .pop-up-form {
        padding: 0px 0px;
        width: auto;
    }
    .pop-up-row {
        display: flex;
        flex-direction: column;
    }
    .left-side-popup {
        width: 100%;
        position: relative;
        top: -60px;
    }
    .right-side-popup{
        width: 100%;
    }
    .pop-up-contact-form-wrapper {
        display: flex;
        justify-content: center;
        position: relative;
        top: -50px;
    }
    .pop-up-submit-btn {
        display: flex;
        border: 1px solid black;
        border-radius: 30px;
        background-color: white;
        padding: 5px;
        margin-top: 0px;
    }
    .input-pop-up {
        border: none;
        border-bottom: 1px solid #555;
        background-color: transparent;
        width: 100%;
        margin-bottom: 10px;
    }
    .pop-up-label-txt {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 3px;
    }
}
@media (min-width: 300px) and (max-width: 374px) {
    .pop-up-container-wrapper {
        padding: 0px 10px;
        width: 100%;
        background-color: #2ea5c430;
        height: 100vh;
        overflow: hidden;
    }
    .close-btn-wrapper-pop-up {
        width: 40px;
        height: 20px;
        position: relative;
        right: -4px;
        top: -130px;
    }
    .left-img-pop-up {
        width: 450px;
    }
    .left-side-heading-txt-pop-up {
        font-size: 25px;
        font-weight: 500;
        width: 300px;
        margin-bottom: 0px;
    }
    .left-img-pop-up {
        width: 240px;
        display: none;
    }
    .pop-up-txt-left-p {
        font-size: 18px;
        margin-bottom: 0px;
    }
    .pop-up-container {
        max-width: 100%;
        position: relative;
        top: 120px;
    }
    .pop-up-form {
        padding: 0px 0px;
        width: auto;
    }
    .pop-up-row {
        display: flex;
        flex-direction: column;
    }
    .left-side-popup {
        width: 100%;
        position: relative;
        top: -60px;
    }
    .right-side-popup{
        width: 100%;
    }
    .pop-up-contact-form-wrapper {
        display: flex;
        justify-content: center;
        position: relative;
        top: -50px;
    }
    .pop-up-submit-btn {
        display: flex;
        border: 1px solid black;
        border-radius: 30px;
        background-color: white;
        padding: 5px;
        margin-top: 0px;
    }
    .input-pop-up {
        border: none;
        border-bottom: 1px solid #555;
        background-color: transparent;
        width: 100%;
        margin-bottom: 10px;
    }
    .pop-up-label-txt {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 3px;
    }
}