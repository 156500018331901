.contact-us-container {
    margin-top: -130px;
    overflow-x: hidden;
}
.contact-us-img{
    padding: 20px
}
.we-are-rapid-heading{
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    top: -300px;
    left: 120px;
    position: relative;
}
.we-are-rapid-txt{
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    top: -300px;
    left: 120px;
    position: relative;
}
.custom-contact-container{
    max-width: 1280px;
    background-color: #000;
    margin: 5px;
    background-image: url("../images/contactus/contactcontainerbg.svg");
    border: .0625rem solid #222;
    border-radius: 12px;
    padding: 64px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: auto;
}
.centre-container{
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
    z-index: 11;
    position: relative;
}
.contact-us-container-heading{
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.contact-us-container-txt{
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
}
.contact-icon-txt-wrapper-main{
    margin-top: 3rem;
}
.contact-icon-txt-wrapper{
    display: flex;
    margin-bottom: 16px;
}
.contact-icon{
    height: 25px !important;
    filter: invert(1);
    width: auto;
    margin-right: 16px;
    margin-top: 2px;

}
.contact-txt{
    color: white;
}
.first-last-name-wrapper{
    display: flex;
    margin-bottom: 20px;
}
.label-txt{
    color: white;
}
.name-input{
    height: 100%;
    max-height: 3.25rem;
    border: .0625rem solid #919191;
    border-radius: .25rem;
    margin-bottom: .625rem;
    margin-right: 1rem;
    padding: .9rem 1rem;
    font-size: 1rem;
    line-height: 1.3;
    color: #fff;
    background-color: #151515;
    border-color: #333;
    width: 275px;
}
.name-input:hover {
    background-color: #222;
    border-color: #898989;
}
.d-flex{
    display: flex;
}
.radio-btn{
    width: 1.5rem; 
    min-height: 1.5rem;
    min-width: 1.5rem;
    background-color: #f9f9f9;
    border-color: #898989;
    border-radius: .25rem;
    margin-top: 0;
    margin-right: .75rem;
}
.label-txt-radio{
    color: #fff;
    margin-right: 60px;
}
.radio-wrapper{
    margin-bottom: 25px;
}
.email-input{
    height: 100%;
    max-height: 3.25rem;
    border: .0625rem solid #919191;
    border-radius: .25rem;
    margin-bottom: .625rem;
    margin-right: 1rem;
    padding: .9rem 1rem;
    font-size: 1rem;
    line-height: 1.3;
    color: #fff;
    background-color: #151515;
    border-color: #333;
    width: 565px;
}
.email-input:hover {
    background-color: #222;
    border-color: #898989;
}
.message-input{
    height: 130px;
    border: .0625rem solid #919191;
    border-radius: .25rem;
    margin-bottom: .625rem;
    margin-right: 1rem;
    padding: .9rem 1rem;
    font-size: 1rem;
    line-height: 1.3;
    color: #fff;
    background-color: #151515;
    border-color: #333;
    width: 565px;
}
.message-input:hover {
    background-color: #222;
    border-color: #898989;
}
.email-wrapper{
    margin-bottom: 20px;
}
.all-project-btn-inquiry{
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    height: 50px;
    width: 180px;
}
.contact-btn-submit-wrapper{
    border-radius: 30px;
    border: .0625rem solid #333;
    display: flex;
    height: 50px;
    width: 180px;
    background-color: #151515;
}
.contact-btn-submit-txt{
    padding-top: 11px;
    padding-left: 21px;
    color: white;
}
.right-arrow-span-contact{
    background-color: white;
    border-radius: 100px;
    margin-left: 59px;
    height: 40px;
    width: 40px;
    margin-top: 4px;
}
.right-arrow-contact{
    width: 30px;
    height: 30px !important;
    padding-left: 4px;
    margin-top: 5px;
    padding: 3px 3px 3px 6px;
}
.contact-btn-submit-wrapper:hover{
    background-color: white;
}
.contact-btn-submit-wrapper:hover .contact-btn-submit-txt{
    color: black;
}
.contact-btn-submit-wrapper:hover .right-arrow-span-contact{
    background-color: #151515;
}
.contact-btn-submit-wrapper:hover .right-arrow-contact{
    filter: invert(1);
}
#Sales{
      background-color: #171717;
    border: .0625rem solid #5a5a5a;
    accent-color: white;
    
}
#Service{
      background-color: #171717;
    border: .0625rem solid #5a5a5a;
    accent-color: white;
}
#Others{
      background-color: #171717;
    border: .0625rem solid #5a5a5a;
    accent-color: white;
}
.company-title{
    color: white;
    font-size: 28px;
    margin-top: 50px;
    margin-bottom: 20px;
    -webkit-text-stroke: 1px #000;
    text-shadow: 1px 1px #b8bfd6;
}
.error-message{
    color: red;
}
.pt-10{
    padding-top: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.map-of-address{
    border: 0px;
    margin-bottom: 100px ;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.animated-bic {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}
.fadeInUp-bic {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
@keyframes fadeInUp {
    from {
        transform: translate3d(0,70px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,70px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@media (min-width: 1400px) and (max-width: 1440px) {
    .we-are-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -300px;
        left: 120px;
        position: relative;
        width: fit-content;
    }
    .we-are-rapid-txt {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        top: -300px;
        left: 120px;
        position: relative;
        width: fit-content;
    }
}

.subpage-hero-section-mobile-contact-us {
    background-color: #fff;
    z-index: 99;
    display: block;
    position: relative;
    margin-top: 190px;
}

.subpageherobackground-mobile-contact-us{
    width: 100%;
    height: 290px !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 20px;
}
.hedings-wrapper-contact-us{

}
@media (min-width: 1280px) and (max-width: 1399px) {
    .contact-us-container {
        margin-top: -120px;
        overflow-x: hidden;
    }
    .we-are-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -240px;
        left: 120px;
        position: relative;
        width: fit-content;
    }
    .we-are-rapid-txt {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        top: -250px;
        left: 127px;
        position: relative;
        width: fit-content;
    }
    .custom-contact-container {
        position: relative;
        top: -130px;
        margin: 20px;
    }
    .name-input {
        width: 225px;
    }
    .email-input {
        width: 465px;
    }
    .message-input {
        width: 465px;
    }
    .map-container-rapid{
        position: relative;
        top: -200px;
    }
    .map-of-address {
        border: 0px;
        margin-bottom: -120px;
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .contact-us-container {
        margin-top: -125px;
        overflow-x: hidden;
    }
    .we-are-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -240px;
        left: 120px;
        position: relative;
        width: fit-content;
    }
    .we-are-rapid-txt {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        top: -250px;
        left: 127px;
        position: relative;
        width: fit-content;
    }
    .custom-contact-container {
        position: relative;
        top: -130px;
        margin: 20px;
    }
    .name-input {
        width: 225px;
    }
    .email-input {
        width: 465px;
    }
    .message-input {
        width: 465px;
    }
    .map-container-rapid{
        position: relative;
        top: -200px;
    }
    .map-of-address {
        border: 0px;
        margin-bottom: -120px;
    }
}
@media (min-width: 810px) and (max-width: 1079px) {
    .contact-us-container {
        margin-top: -100px;
        overflow-x: hidden;
    }
    .we-are-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -170px;
        left: 70px;
        position: relative;
        width: -moz-fit-content;
        width: fit-content;
    }
    .we-are-rapid-txt {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        top: -180px;
        left: 70px;
        position: relative;
        width: fit-content;
    }
    .custom-contact-container {
        position: relative;
        top: -130px;
        margin: 20px;
        padding: 30px;
    }
    .name-input {
        width: 170px;
    }
    .email-input {
        width: 355px;
    }
    .message-input {
        width: 355px;
    }
    .map-container-rapid{
        position: relative;
        top: -200px;
    }
    .map-of-address {
        border: 0px;
        margin-bottom: -120px;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .contact-us-container {
        margin-top: -90px;
        overflow-x: hidden;
    }
    .we-are-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -170px;
        left: 70px;
        position: relative;
        width: -moz-fit-content;
        width: fit-content;
    }
    .we-are-rapid-txt {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        top: -180px;
        left: 70px;
        position: relative;
        width: fit-content;
    }
    .custom-contact-container {
        position: relative;
        top: -130px;
        margin: 20px;
        padding: 30px;
    }
    .name-input {
        width: 160px;
    }
    .email-input {
        width: 335px;
    }
    .message-input {
        width: 335px;
    }
    .map-container-rapid{
        position: relative;
        top: -200px;
    }
    .map-of-address {
        border: 0px;
        margin-bottom: -120px;
    }
    .label-txt-radio {
        color: #fff;
        margin-right: 40px;
    }   
}

@media (min-width: 300px) and (max-width: 767px) {
    .we-are-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 0px;
        left: 0px;
        position: relative;
    }
    .subpageherobackground-mobile-contact-us {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: 0px;
    }
    .we-are-rapid-txt {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.4;
        top: 0px;
        left: 0px;
        position: relative;
    }
    .hedings-wrapper-contact-us {
        position: relative;
        top: 20px;
        z-index: 111;
        left: 20px;
    }
    .custom-contact-container {
        max-width: 1280px;
        background-color: #000;
        margin: 5px;
        border: .0625rem solid #222;
        border-radius: 12px;
        padding: 20px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: auto;
    }
    .contact-us-custom-row{
        display: flex;
        flex-direction: column;
    }
    .contact-us-custom-left{
        width: 100%;
    }
    .contact-us-custom-right {
        width: 100%;
        position: relative;
        margin-top: 50px;
    }
    .first-last-name-wrapper {
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .name-input {
        width: 100%;
    }
    .email-input {
        width: 100%;
    }
    .message-input {
        width: 100%;
    }
    .centre-container {
        display: flex;
        justify-content: center;
        margin-bottom: 120px;
        z-index: 11;
        position: relative;
        top: 0px;
        margin: 20px;
    }
    .contact-us-container-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
    .container.map-container-rapid {
        padding: 0px 20px;
    }
    .container.map-container-rapid {
        padding: 90px 20px 10px 20px;
        position: relative;
        top: -70px;
    }
    .map-of-address {
        border: 0px;
        margin-bottom: 0px;
    }
}
