img {
  height: 100% !important;
  width: 100%;
}
.map-img-wrapper {
  width: 100%;
  height: 900px;
    background-color: #fff;
    z-index: 99;
    position: relative;
}

.square-hotspot {
  height: 27px;
  background: #2ba4c3;
  transition: all 0.3s ease;
  width: 27px;
  position: absolute;
  top: 199px;
}
.square-hotspot:before {
  content: "";
  position: absolute;
  border-top: 8px solid #2ba4c3;
  border-right: 8px solid transparent;
  bottom: -8px;
  left: 0;
}
.plus-hotspot {
  padding: 5px;
  color: white;
  font-size: 25px;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 6;
  cursor: pointer;
  filter: invert();
}
.rotate-45 {
  transform: rotate(45deg);
}
.hotspot-info {
  width: 200px;
  height: 100px;
  background: #393e4a;
  color: white;
  text-align: justify;
  padding: 20px;
  font-size: 21px;
  line-height: 28px;
  transition: all 0.3s ease;
  position: absolute;
  top: 126px;
}
@media (min-width: 1024px) and (max-width: 1080px) {
  .map-img-wrapper {
    height: 600px;
    background-color: #fff;
    z-index: 99;
    position: relative;
    margin-bottom: 50px;
}
}
@media (min-width: 768px) and (max-width: 979px) {
  .map-img-wrapper {
    height: 400px;
    background-color: #fff;
    z-index: 99;
    position: relative;
    margin-bottom: 50px;
}

}

@media (resolution: 120dpi) {
  .world-map-txt {
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
    width: auto !important;
}
}