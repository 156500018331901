.mobile-bic-swiper-wrapper{
    background-color: #dde1ed8f;
}
.mobile-bic-img{
    padding: 50px 20px 50px  20px;
}
.mobile-bic-content-wrapper{
    padding: 20px 20px 20px 20px;
    height: 50vh;
    overflow-y: scroll;
}
.mobile-bic-content-heading{
    -webkit-text-stroke: .7px #000;
    color: #31a6c4;
    font-size: 48px;
    font-weight: 700;
    line-height: 57px;
    margin-bottom: 30px;

}
.mobile-bic-content-p{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
}
.mobile-bold-content{
    color: #31a6c4;
}
.mobile-p-o-i{
    color: #31a6c4;
    margin-bottom: 20px;
    margin-top: 40px;
}
.poi-p-wrapper{
    display: flex;
}
.p-heading{
    color: #31a6c4;
    margin-top: 20px;
}
.simik-mobile{
    width: 50%;
    height: auto !important;

}
.left-swipe{
    height: 20px !important;
    width: 20px;
    margin-right: 20px;
    filter: contrast(0);
}
.right-swipe{
    height: 20px !important;
    width: 20px;
    filter: contrast(0);
}
.swiper-img-wrapper{
    background-color: #eceef5;
    padding: 27px 30px 15px 15px;
    position: relative;
    display: flex;
    justify-content: end;
}
.swiper-controller{
    background-color: #eceef5;
    display: flex;
    justify-content: space-between;
}
.slide-name {
    font-weight: bold;
    color: #39a9c7;
    font-size: 32px;
    padding-left: 21px;
    margin-top: 18px;
}

@media (min-width: 425px) and (max-width: 600px) {
    .hotspot-swiper-th-model {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 50px 15px 50px;
    }
    .hotspot-swiper-th-cycles {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 12px 15px 12px;
    }

    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 18px 10px 18px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 16px;
        background-color: white;
        padding: 10px 40px 10px 40px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 16px;
        background-color: white;
        padding: 10px 70px 10px 70px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 20px 5px 20px;
    }
    .hotspot-swiper-td-pl {
        font-size: 16px;
        background-color: white;
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
    }
    .hotspot-swiper-th {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 13px 45px 13px 45px;
    }
    .hotspot-swiper-th-width {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 12px 65px 13px 65px;
    }
    .hotspot-swiper-td-first {
        font-size: 14px;
        background-color: white;
        padding: 10px 61px 10px 61px;
        border-radius: 10px;
    }
    .hotspot-swiper-td {
        font-size: 14px;
        background-color: white;
        padding: 10px 65px 10px 65px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 28px 56px 28px 56px;
    }
    .hotspot-swiper-th-fv {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 30px 16px 30px;
    }
    .hotspot-swiper-th-rs {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 35px 5px 35px;
    }
    .hotspot-swiper-td-fl {
        font-size: 14px;
        background-color: white;
        padding: 10px 45px 10px 45px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 14px;
        background-color: white;
        padding: 10px 37px 10px 37px;
        border-radius: 10px;
    }
    .special-size-ml {
        font-size: 10px;
        display: contents;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 39px 10px 42px;
    }
    .hotspot-swiper-td-RAPIDfill {
        font-size: 14px;
        background-color: white;
        padding: 10px 27px 10px 27px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 50px 16px 50px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 35px 5px 35px;
    }
    .hotspot-swiper-th-rs-2 {
        font-size: 16px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 33px 5px 33px;
    }
    .hotspot-swiper-td-RAPIDfill-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 25px 10px 25px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 50px 10px 50px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-RAPIDfill-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 22px 10px 22px;
        border-radius: 10px;
    }
    .mobile-bic-img {
        padding: 0px 60px 50px 20px;
    }
    .mobile-bic-content-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .mobile-bic-swiper-wrapper {
        background-color: #dde1ed8f;
        position: relative;
        margin-left: -30px;
        overflow: hidden;
    }
    .mobile-bic-content-wrapper {
        padding: 20px 50px 20px 20px;
        height: 50vh;
        overflow-y: scroll;
    }
}
@media (min-width: 393px) and (max-width: 424px) {
    .hotspot-swiper-th-model {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 43px 15px 43px;
    }
    .hotspot-swiper-th-cycles {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 6px 15px 6px;
    }

    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 18px 10px 18px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 16px;
        background-color: white;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 10px 15px 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 16px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-th {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 13px 22px 13px 22px;
    }
    .hotspot-swiper-th-width {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 12px 67px 13px 67px;
    }
    .hotspot-swiper-td-first {
        font-size: 14px;
        background-color: white;
        padding: 10px 32px 10px 32px;
        border-radius: 10px;
    }
    .hotspot-swiper-td {
        font-size: 14px;
        background-color: white;
        padding: 10px 65px 10px 65px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 28px 45px 28px 45px;
    }
    .hotspot-swiper-th-fv {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 30px 16px 30px;
    }
    .hotspot-swiper-th-rs {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 20px 5px 20px;
    }
    .hotspot-swiper-td-fl {
        font-size: 14px;
        background-color: white;
        padding: 10px 40px 10px 40px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 14px;
        background-color: white;
        padding: 10px 28px 10px 28px;
        border-radius: 10px;
    }
    .special-size-ml {
        font-size: 10px;
        display: contents;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 32px 10px 32px;
    }
    .hotspot-swiper-td-RAPIDfill {
        font-size: 14px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 46px 16px 46px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
    }
    .hotspot-swiper-th-rs-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 33px 5px 33px;
    }
    .hotspot-swiper-td-RAPIDfill-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 34px 10px 34px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 10px;
    }
    .mobile-bic-img {
        padding: 10px 30px 50px 10px;
    }
    .mobile-bic-content-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .mobile-bic-content-wrapper {
        padding: 20px 20px 0px 10px;
        height: 50vh;
        overflow-y: scroll;
    }
    .mobile-bic-swiper-wrapper {
        background-color: #dde1ed8f;
        position: relative;
        margin-left: 0px;
        overflow: hidden;
    }
}
@media (min-width: 375px) and (max-width: 392px){
    .hotspot-swiper-th-model {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 43px 15px 43px;
    }
    .hotspot-swiper-th-cycles {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 6px 15px 6px;
    }

    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 18px 10px 18px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 16px;
        background-color: white;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 10px 15px 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 16px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-th {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 13px 22px 13px 22px;
    }
    .hotspot-swiper-th-width {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 12px 67px 13px 67px;
    }
    .hotspot-swiper-td-first {
        font-size: 14px;
        background-color: white;
        padding: 10px 32px 10px 32px;
        border-radius: 10px;
    }
    .hotspot-swiper-td {
        font-size: 14px;
        background-color: white;
        padding: 10px 65px 10px 65px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 28px 45px 28px 45px;
    }
    .hotspot-swiper-th-fv {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 30px 16px 30px;
    }
    .hotspot-swiper-th-rs {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 20px 5px 20px;
    }
    .hotspot-swiper-td-fl {
        font-size: 14px;
        background-color: white;
        padding: 10px 40px 10px 40px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 14px;
        background-color: white;
        padding: 10px 28px 10px 28px;
        border-radius: 10px;
    }
    .special-size-ml {
        font-size: 10px;
        display: contents;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 32px 10px 32px;
    }
    .hotspot-swiper-td-RAPIDfill {
        font-size: 14px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 46px 16px 46px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
    }
    .hotspot-swiper-th-rs-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 33px 5px 33px;
    }
    .hotspot-swiper-td-RAPIDfill-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 34px 10px 34px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 10px;
    }
    .mobile-bic-img {
        padding: 10px 30px 50px 10px;
    }
    .mobile-bic-content-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .mobile-bic-content-wrapper {
        padding: 20px 20px 0px 10px;
        height: 50vh;
        overflow-y: scroll;
    }
    .mobile-bic-swiper-wrapper {
        background-color: #dde1ed8f;
        position: relative;
        margin-left: -30px;
        overflow: hidden;
    }
}
@media (min-width: 360px) and (max-width:374px) {
    .hotspot-swiper-th-model {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 43px 15px 43px;
    }
    .hotspot-swiper-th-cycles {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 6px 15px 6px;
    }

    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 18px 10px 18px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 16px;
        background-color: white;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 10px 15px 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 16px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 14px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-th {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 13px 22px 13px 22px;
    }
    .hotspot-swiper-th-width {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 12px 67px 13px 67px;
    }
    .hotspot-swiper-td-first {
        font-size: 14px;
        background-color: white;
        padding: 10px 32px 10px 32px;
        border-radius: 10px;
    }
    .hotspot-swiper-td {
        font-size: 14px;
        background-color: white;
        padding: 10px 65px 10px 65px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 28px 45px 28px 45px;
    }
    .hotspot-swiper-th-fv {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 30px 16px 30px;
    }
    .hotspot-swiper-th-rs {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 20px 5px 20px;
    }
    .hotspot-swiper-td-fl {
        font-size: 14px;
        background-color: white;
        padding: 10px 40px 10px 40px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 14px;
        background-color: white;
        padding: 10px 28px 10px 28px;
        border-radius: 10px;
    }
    .special-size-ml {
        font-size: 10px;
        display: contents;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 32px 10px 32px;
    }
    .hotspot-swiper-td-RAPIDfill {
        font-size: 14px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 46px 16px 46px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
    }
    .hotspot-swiper-th-rs-2 {
        font-size: 14px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 33px 5px 33px;
    }
    .hotspot-swiper-td-RAPIDfill-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 34px 10px 34px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 14px;
        background-color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 10px;
    }
    .mobile-bic-img {
        padding: 10px 30px 50px 10px;
    }
    .mobile-bic-content-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .mobile-bic-content-wrapper {
        padding: 20px 20px 0px 10px;
        height: 50vh;
        overflow-y: scroll;
    }
    .mobile-bic-swiper-wrapper {
        background-color: #dde1ed8f;
        position: relative;
        margin-left: 0px;
        overflow: hidden;
    }
}
@media (min-width: 300px) and (max-width: 359px) {
    
    .hotspot-swiper-th-model {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 25px 15px 25px;
    }
    .hotspot-swiper-th-cycles {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 3px 15px 3px;
    }

    .hotspot-swiper-td-RAPIDshrink {
        font-size: 11px;
        background-color: white;
        padding: 10px 18px 10px 18px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 11px;
        background-color: white;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 15px 10px 15px 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 11px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 11px;
        background-color: white;
        padding: 10px 3px 10px 3px;
        border-radius: 10px;
    }
    .hotspot-swiper-th {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 13px 22px 13px 22px;
    }
    .hotspot-swiper-th-width {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 12px 37px 13px 37px;
    }
    .hotspot-swiper-td-first {
        font-size: 11px;
        background-color: white;
        padding: 10px 35px 10px 35px;
        border-radius: 10px;
    }
    .hotspot-swiper-td {
        font-size: 11px;
        background-color: white;
        padding: 10px 39px 10px 39px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 28px 36px 28px 36px;
    }
    .hotspot-swiper-th-fv {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 20px 16px 20px;
    }
    .hotspot-swiper-th-rs {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 15px 5px 15px;
    }
    .hotspot-swiper-td-fl {
        font-size: 11px;
        background-color: white;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 11px;
        background-color: white;
        padding: 10px 28px 10px 28px;
        border-radius: 10px;
    }
    .special-size-ml {
        font-size: 10px;
        display: contents;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 32px 10px 32px;
    }
    .hotspot-swiper-td-RAPIDfill {
        font-size: 11px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 16px 37px 16px 37px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 20px 5px 20px;
    }
    .hotspot-swiper-th-rs-2 {
        font-size: 13px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 25px 5px 25px;
    }
    .hotspot-swiper-td-RAPIDfill-2 {
        font-size: 11px;
        background-color: white;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 11px;
        background-color: white;
        padding: 10px 34px 10px 34px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 11px;
        background-color: white;
        padding: 10px 25px 10px 25px;
        border-radius: 10px;
    }
    .mobile-bic-img {
        padding: 0px 20px 50px 20px;
    }
    .mobile-bic-content-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }
    .mobile-bic-swiper-wrapper {
        background-color: #dde1ed8f;
        position: relative;
        margin-left: -10px;
        overflow: hidden;
    }
}