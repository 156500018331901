.left-side-psbeer {
    width: 250px;
    height: auto;
    display: inline-block;
    float: left;
    position: sticky;
    top: 130px;
}

.main-section-psbeer {
    width: 1100px;
    margin: 50px auto;
    clear: both;
    padding-bottom: 100px;
}

.main-section-psbeer:before,
.main-section-psbeer:after {
    content: "";
    display: table;
    clear: both;
}

.right-side-psbeer {
    width: 700px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
}


.main-section-psbeer2 {
    width: 1200px;
    margin: 50px auto;
    clear: both;
}

.left-side-psbeer2 {
    width: 700px;
    display: inline-block;
    vertical-align: top;
    /* float: right; */
    height: auto;
    margin-left: 50px;
}
.bik-img-left {
    width: 100%;
}
.main-section-psbeer2:before,
.main-section-psbeer2:after {
    content: "";
    display: table;
    clear: both;
}

.right-side-psbeer2 {
    width: 250px;
    height: auto;
    display: inline-block;
    /* float: left; */
    position: sticky;
    top: 130px;
    margin-left: 100px;
}

.ps-beer-img-left-wrapper {
    width: 340px;
}

@media (min-width: 1280px) and (max-width: 1399px) {
    .main-section-psbeer {
        width: 100%;
        margin: 50px auto;
        clear: both;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -70px;
    }

    .main-section-psbeer2 {
        width: 100%;
        margin: 50px auto;
        clear: both;
        padding-left: 20px;
        padding-right: 20px;
    }

    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 20px;
    }

    .right-side-psbeer {
        width: 670px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-right: 20px;
    }

    .left-side-psbeer2 {
        width: 700px;
        display: inline-block;
        vertical-align: top;
        /* float: right; */
        height: auto;
        margin-left: 0px;
    }

    .left-side-psbeer {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: sticky;
        top: 130px;
        left: 100px;
    }

    .ps-beer-img-left-wrapper {
        width: 460px;
    }
}

@media (min-width: 1080px) and (max-width: 1279px) {
    .main-section-psbeer {
        width: 100%;
        margin: 50px auto;
        clear: both;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -70px;
    }

    .main-section-psbeer2 {
        width: 100%;
        margin: 50px auto;
        clear: both;
        padding-left: 20px;
        padding-right: 20px;
    }

    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 20px;
    }

    .right-side-psbeer {
        width: 670px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-right: 20px;
    }

    .left-side-psbeer2 {
        width: 700px;
        display: inline-block;
        vertical-align: top;
        /* float: right; */
        height: auto;
        margin-left: 0px;
    }
}

@media (min-width: 1024px) and (max-width: 1079px) {
    .main-section-psbeer {
        width: 100%;
        margin: 50px auto;
        clear: both;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -70px;
    }

    .main-section-psbeer2 {
        width: 100%;
        margin: 50px auto;
        clear: both;
        padding-left: 20px;
        padding-right: 20px;
    }

    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 40px;
    }

    .right-side-psbeer {
        width: 700px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
    }

    .right-side-psbeer {
        width: 700px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-right: 90px;
    }

    .left-side-psbeer {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: sticky;
        top: 100px;
        padding-left: 10px;
    }

    .left-side-psbeer2 {
        width: 670px;
        display: inline-block;
        vertical-align: top;
        /* float: right; */
        height: auto;
    }
}

@media (min-width: 810px) and (max-width: 1023px) {
    .main-section-psbeer {
        display: flex;
        flex-direction: column;
    }

    .left-side-psbeer {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: relative;
        top: -100px;
        left: 30px;
    }

    .right-side-psbeer {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: 0px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
    }

    .main-section-psbeer {
        width: auto;
        margin: 50px auto;
        clear: both;
        padding-bottom: 0px;
    }

    .right-side-psbeer {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
    }

    .left-side-psbeer2 {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
    }

    .left-side-psbeer2 {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
        margin-top: 50px;
        margin-left: 0px;
    }

    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        /* float: left; */
        position: relative;
        top: -20px;
        margin-left: 30px;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .main-section-psbeer {
        display: flex;
        flex-direction: column;
    }

    .left-side-psbeer {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: relative;
        top: -100px;
        left: 30px;
    }

    .right-side-psbeer {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: 0px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
    }

    .main-section-psbeer {
        width: auto;
        margin: 50px auto;
        clear: both;
        padding-bottom: 0px;
    }

    .right-side-psbeer {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
    }

    .left-side-psbeer2 {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
    }

    .left-side-psbeer2 {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
        margin-top: 50px;
        margin-left: 0px;
    }

    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        /* float: left; */
        position: relative;
        top: -20px;
        margin-left: 30px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .main-section-psbeer {
        display: flex;
        flex-direction: column;
    }

    .left-side-psbeer {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: relative;
        top: -100px;
        left: 30px;
    }

    .right-side-psbeer {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .ps-mobile-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
        width: 350px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: 0px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
    }

    .main-section-psbeer {
        width: auto;
        margin: 100px auto;
        clear: both;
        padding-bottom: 0px;
    }

    .right-side-psbeer {
        width: auto;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
    }

    .left-side-psbeer2 {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
    }

    .left-side-psbeer2 {
        width: auto;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
        margin-top: 50px;
        margin-left: 0px;
        padding-right: 20px;
    }

    /* .bik-wrapper-conatiner-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -210px;
        left: 40px;
        position: relative;
        z-index: 181;
    } */

    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 30px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: -130px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .new-mobile-ps-beer {
        padding: 20px;
        border-radius: 35px;

    }

    .new-poi-pas-beer {
        padding-bottom: 130px;
    }
}

@media (min-width: 375px) and (max-width: 424px) {
    .main-section-psbeer {
        display: flex;
        flex-direction: column;
    }

    .left-side-psbeer {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: relative;
        top: -100px;
        left: 0px;
    }

    .right-side-psbeer {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: 0px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
    }

    .main-section-psbeer {
        width: auto;
        margin: 100px auto;
        clear: both;
        padding-bottom: 0px;
    }

    .right-side-psbeer {
        width: auto;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
    }


    .left-side-psbeer2 {
        width: auto;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
        margin-top: 50px;
        margin-left: 0px;
        padding-right: 20px;
    }

    /* .bik-wrapper-conatiner-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -210px;
        left: 40px;
        position: relative;
        z-index: 181;
        width: 295px;
    } */
    /* .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -210px;
        left: 40px;
        position: relative;
        z-index: 181;
        width: 330px;
    } */
    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 0px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: -130px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .ps-mobile-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
        width: 350px;
    }

    .new-mobile-ps-beer {
        padding: 20px;
        border-radius: 35px;

    }

    .new-poi-pas-beer {
        padding-bottom: 130px;
    }
}

@media (min-width: 300px) and (max-width: 374px) {
    .main-section-psbeer {
        display: flex;
        flex-direction: column;
    }

    .left-side-psbeer {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: relative;
        top: -100px;
        left: 0px;
    }

    .right-side-psbeer {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: 0px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
    }

    .main-section-psbeer {
        width: auto;
        margin: 100px auto;
        clear: both;
        padding-bottom: 0px;
    }

    .right-side-psbeer {
        width: auto;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
    }

    .left-side-psbeer2 {
        width: auto;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
        margin-top: 50px;
        margin-left: 0px;
        padding-right: 20px;
    }

    /* .beer-in-can-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -240px;
        left: 40px;
        position: relative;
        z-index: 181;
        width: 270px;
    } */
    /* .bik-wrapper-conatiner-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: -210px;
        left: 40px;
        position: relative;
        z-index: 181;
        width: 295px;
    } */

    .right-side-psbeer2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 0px;
    }

    .main-section-psbeer2 {
        width: auto;
        margin: -130px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .ps-beer-img-left-wrapper {
        width: 310px;
    }

    .ps-mobile-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
        width: 300px;
    }

    .new-mobile-ps-beer {
        padding: 20px;
        border-radius: 35px;

    }

    .new-poi-pas-beer {
        padding-bottom: 130px;
    }
}