.big-heading-right{
    -webkit-text-stroke: 1px #fff;
    color: #000;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2;
    text-shadow: 1px 1px #b8bfd6;
}
.big-txt-p-right{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.ranging-big-warapper{
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}
.rangin-wrapper{
    padding: 20px;
    background-color: #2ba4c3;
    margin-right: 20px;
    border-radius: 10px;
}
.ranging-txt{
    color: white;
    font-size: 21px;
    font-weight: bold;
    line-height: 28px;
    text-align: left;
    margin-top: 10px;
}
.big-img-warapper{
    margin-left: -50px;
    width: 500px;
}
.big-left-img{
    width: 100%;
    height: auto !important;
}
.big-container-sectionone{
    padding-bottom: 100px;
}
.csd-sectionone-left{
    align-items: center;
    display: flex;
}
.csd-img-wrapper{
    margin-left: -50px;
    width: 310px;
}
.beer-in-glass-heading-mobile {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    top: -180px;
    left: 40px;
    position: relative;
    z-index: 181;
}
@media (min-width: 1400px) and (max-width: 1440px) {
    .csd-img-wrapper{
        margin-left: -50px;
        width: 310px;
    }
    .csd-sectionone-left{
        align-items: center;
        display: flex;
        justify-content: center;
    }
}
@media (min-width: 1024px) and (max-width: 1300px) {
    .big-img-warapper {
        margin-left: -30px;
        width: 400px;
        margin-top: 120px;
    }
    .csd-img-wrapper{
        margin-left: -50px;
        width: 310px;
    }
    .csd-sectionone-left{
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 768px) and (max-width: 1023px){
    .big-sectionone-row{
        display: flex;
        flex-direction: column-reverse;
    }
    .big-sectionone-left{
       width: 100%;
    }
    .big-sectionone-right{
      width: 100%;
    }
    .big-img-warapper {
        width: 600px;
        text-align: center;
        display: contents;
    }
    .big-container-sectionone {
        padding-bottom: 100px;
        margin-top: -50px;
    }
    .big-heading-right{
        -webkit-text-stroke: 1px #fff;
        color: #000;
        font-size: 40px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .csd-left{
        width: 40%;
    }
    .csd-img-wrapper{
        width: 310px;
    }
    .csd-sectionone-left {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .big-sectionone-row {
        display: flex;
        flex-direction: column;
        padding: 0 0px;
    }
    .big-sectionone-left{
       width: 100%;
    }
    .big-sectionone-right {
        width: 100%;
        margin-top: 30px;
        padding: 0px 15px;
    }
    .big-img-warapper {
        width: 600px;
        text-align: center;
        display: contents;
    }
    .big-container-sectionone {
        padding-bottom: 20px;
        margin-top: 0px;
    }
    .big-heading-right{
        -webkit-text-stroke: 0 #fff;
        color: #000;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .csd-left{
        width: 80%;
    }
    .csd-img-wrapper{
        width: 310px;
    }
    .csd-sectionone-left {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    .big-txt-p-right {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .beer-in-glass-heading-mobile {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
        width: 400px;
    }
    .big-new-mobile-img{
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
    }
}
@media (min-width: 300px) and (max-width:  424px) {
    .big-sectionone-row {
        display: flex;
        flex-direction: column;
        padding: 0 0;
    }
    .big-sectionone-left{
       width: 100%;
    }
    .big-sectionone-right {
        width: 100%;
        margin-top: 30px;
        padding: 0px 15px;
    }
    .big-img-warapper {
        width: 600px;
        text-align: center;
        display: contents;
    }
    .big-container-sectionone {
        padding-bottom: 20px;
        margin-top: 0px;
    }
    .big-heading-right{
        -webkit-text-stroke: 0 #fff;
        color: #000;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .beer-in-glass-heading-mobile {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 34px;
        font-weight: 600;
        left: 28px;
        line-height: 1.4;
        position: relative;
        text-shadow: 1px 1px #b8bfd6;
        top: -180px;
        z-index: 181;
        width: fit-content;
    }
    .csd-left{
        width: 70%;
    }
    .csd-img-wrapper{
        width: 310px;
    }
    .csd-sectionone-left {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    .big-txt-p-right {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .beer-in-glass-heading-mobile {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        top: 20px;
        left: 20px;
        position: relative;
        z-index: 181;
        width: fit-content;
    }
    .big-new-mobile-img{
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
    }
}