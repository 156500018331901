
/* div#root {
  overflow-y: scroll;
  scrollbar-width: none;
} */
.header-container {
  padding: 30px;
  width: 100%;
  top: 0;
  z-index: 990;
}
.header-container-white{
  padding: 30px;
  width: 100%;
  top: 0;
  z-index: 9999;
  position: relative;
  background-color: white;
}
.header-menu-txt-black{
  text-decoration: none;
  padding: 0px 25px;
  color: black;
  letter-spacing: 1.2px;
  font-weight: 600;
  position: relative;
}
.mega-menu-down-arrow-img-black{
  width: 20px;
  filter: invert(0);
  /* transform: rotate(180deg); */
}
.header-container.scrolled {
  background-color: white;
  position: fixed;
  padding: 15px 30px 15px 30px;
  transition: all ease-in-out 0.7s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}
.header-container-white.scrolled{
  background-color: white;
  position: fixed;
  padding: 15px 30px 15px 30px;
  /* transition: all ease-in-out 0.7s; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px; */
}
.header-menu-txt.scrolled {
  color: black;
  font-weight: 600;
}

.header-menus.scrolled {
  padding-top: 25px;
}

.rapidbevtechlogo.scrolled {
  width: 76%;
  transition: all ease-in-out 0.7s;
}

.header-send-inquire.scrolled {
  padding-top: 8px;
}
.hamburger-img.scrolled{
   position: relative;
}
.mega-menu-down-arrow-img.scrolled{
  filter: invert(0);
}
.rapidbevtechlogo {
  width: 85%;
  z-index: 5;
  position: relative;
}

.headermain-menu {
  z-index: 9;
}

.header-menus {
  display: flex;
  list-style-type: none;
  padding-top: 15px;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* font-family: "Orbitron"; */
}

.header-menu-txt {
  text-decoration: none;
  padding: 0px 25px;
  color: white;
  letter-spacing: 1.2px;
}

.header-send-inquire {
  padding-left: 110px;
  padding-top: 15px;
  z-index: 5;
  cursor: pointer;
}

.header-btn {
  height: 49px;
  width: 148px;
  border-radius: 30px;
  border: 1px solid black;
  background-color: white;
}

.header-btn:hover {
  background-color: #2ba4c3;
  color: white;
}

.all-project-btn-inquiry {
  border-radius: 30px;
  border: 1px solid black;
  display: flex;
  height: 50px;
  width: 180px;
}

.all-project-btn-txt-inquiry {
  padding-top: 11px;
  padding-left: 21px;
}

.right-arrow-span-inquiry {
  background-color: #2ba4c3;
  border-radius: 100px;
  margin-left: 28px;
  height: 40px;
  width: 40px;
  margin-top: 4px;
}

.right-arrow-inquiry {
  width: 30px;
  height: 30px !important;
  padding-left: 12px;
  padding-top: 10px;
  filter: invert(1);
}
.hamburger-img{
  display: none;
}
.sendmail-img{
  display: none;
}
.rapidbevtechlogo-wrapper {
  cursor: pointer;
}

.all-project-btn-inquiry:hover {
  background-color: #2ba4c3;
}

.all-project-btn-inquiry:hover .right-arrow-span-inquiry {
  background-color: white;
}

.all-project-btn-inquiry:hover .right-arrow-inquiry {
  filter: invert(0);
}

.all-project-btn-inquiry:hover .all-project-btn-txt-inquiry {
  color: white;
}
.header-two-wrapper {
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1211;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.header-two-logo {
  height: auto !important;
  width: 35%;
}

.header-two-burger {
  height: auto !important;
  width: 6%;
  position: absolute;
  left: 352px;
}
.mega-menu-down-arrow-img{
  width: 20px;
  filter: invert(1);
}
.mega-menu-special{
  padding-right: 0px;
}
.sidebar-main{
  display: none;
}
.hover-special:hover .sidebar-main{
  display: inline-block;
  text-align: left;
}
.hover-special:hover .header-send-inquire{
  display: none;
}
.send-mail-two{
  height: auto !important;
  width: 6%;
  position: absolute;
  left: 352px;
}
.rapidbevtechlogohighlights{
  width: 100%;
  display: flex;
  z-index: 111;
      position: relative;
    top: 7px;
}
@media (min-width: 1400px) and (max-width: 1440px) {
  .rapidbevtechlogo {
    width: 85%;
    z-index: 55;
    position: relative;
    top: -15px;
  }

  .header-send-inquire {
    padding-left: 70px;
    padding-top: 15px;
    z-index: 11;
    position: relative;
    bottom: 20px;
  }

  .right-arrow-span-inquiry {
    background-color: #2ba4c3;
    border-radius: 100px;
    margin-left: 5px;
    height: 35px;
    width: 35px;
    margin-top: 7px;
  }

  .all-project-btn-inquiry {
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    height: 50px;
    width: 150px !important;
  }

  .right-arrow-inquiry {
    width: 27px;
    height: 27px !important;
    padding-left: 10px;
    padding-top: 8px;
    filter: invert(1);
  }

  .all-project-btn-txt-inquiry {
    padding-top: 11px;
    padding-left: 18px;
  }

  .rapidbevtechlogo.scrolled {
    width: 65%;
    transition: all ease-in-out 0.7s;
    position: relative;
    top: 6px;
  }

  .header-send-inquire.scrolled {
    right: 22px;
    padding-top: 8px;
    position: relative;
    top: 6px;
  }

  .header-container.scrolled {
    background-color: white;
    position: fixed;
    padding: 15px 30px 15px 30px;
    transition: all ease-in-out 0.7s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}
}
@media (min-width: 1366px) and (max-width: 1399px) {
  .rapidbevtechlogo {
    width: 85%;
    z-index: 55;
    position: relative;
    top: -15px;
  }

  .header-send-inquire {
    padding-left: 70px;
    padding-top: 15px;
    z-index: 11;
    position: relative;
    bottom: 20px;
  }

  .right-arrow-span-inquiry {
    background-color: #2ba4c3;
    border-radius: 100px;
    margin-left: 5px;
    height: 35px;
    width: 35px;
    margin-top: 7px;
  }

  .all-project-btn-inquiry {
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    height: 50px;
    width: 150px !important;
  }

  .right-arrow-inquiry {
    width: 27px;
    height: 27px !important;
    padding-left: 10px;
    padding-top: 8px;
    filter: invert(1);
  }

  .all-project-btn-txt-inquiry {
    padding-top: 11px;
    padding-left: 18px;
  }

  .rapidbevtechlogo.scrolled {
    width: 65%;
    transition: all ease-in-out 0.7s;
    position: relative;
    top: 6px;
  }

  .header-container.scrolled {
    background-color: white;
    position: fixed;
    padding: 10px  30px 15px 30px;
    transition: all ease-in-out 0.7s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
  }

  .header-send-inquire.scrolled {
    right: 22px;
    padding-top: 5px;
    position: relative;
    top: 6px;
    padding-left: 70px;
}
.header-menu-txt {
  text-decoration: none;
  padding: 0px 16px;
  color: white;
  letter-spacing: 1.2px;
}
.header-menu-txt-black {
  text-decoration: none;
  padding: 0px 16px;
  color: black;
  letter-spacing: 1.2px;
  font-weight: 600;
  position: relative;
}
.mega-menu-down-arrow-img {
  width: 20px;
  filter: invert(1);
  position: relative;
  left: -17px;
}
.mega-menu-down-arrow-img-black.scrolled {
  position: relative;
  left: -15px;
}
}
@media (min-width: 1280px) and (max-width: 1365px) {
  .rapidbevtechlogo {
    width: 85%;
    z-index: 55;
    position: relative;
    top: -15px;
  }

  .header-send-inquire {
    padding-left: 70px;
    padding-top: 15px;
    z-index: 11;
    position: relative;
    bottom: 20px;
  }

  .right-arrow-span-inquiry {
    background-color: #2ba4c3;
    border-radius: 100px;
    margin-left: 5px;
    height: 35px;
    width: 35px;
    margin-top: 7px;
  }

  .all-project-btn-inquiry {
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    height: 50px;
    width: 150px !important;
  }

  .right-arrow-inquiry {
    width: 27px;
    height: 27px !important;
    padding-left: 10px;
    padding-top: 8px;
    filter: invert(1);
  }

  .all-project-btn-txt-inquiry {
    padding-top: 11px;
    padding-left: 18px;
  }

  .rapidbevtechlogo.scrolled {
    width: 65%;
    transition: all ease-in-out 0.7s;
    position: relative;
    top: 6px;
  }

  .header-container.scrolled {
    background-color: white;
    position: fixed;
    padding: 10px  30px 15px 30px;
    transition: all ease-in-out 0.7s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
  }

  .header-send-inquire.scrolled {
    right: 22px;
    padding-top: 5px;
    position: relative;
    top: 6px;
    padding-left: 70px;
}
.header-menu-txt {
  text-decoration: none;
  padding: 0px 16px;
  color: white;
  letter-spacing: 1.2px;
}
.header-menu-txt-black {
  text-decoration: none;
  padding: 0px 16px;
  color: black;
  letter-spacing: 1.2px;
  font-weight: 600;
  position: relative;
}
.mega-menu-down-arrow-img {
  width: 20px;
  filter: invert(1);
  position: relative;
  left: -15px;
}
}
@media (min-width: 1200px) and (max-width: 1279px) {
  .rapidbevtechlogo {
    width: 85%;
    z-index: 55;
    position: relative;
    top: -15px;
  }

  .header-send-inquire {
    padding-left: 70px;
    padding-top: 15px;
    z-index: 11;
    position: relative;
    bottom: 20px;
  }

  .right-arrow-span-inquiry {
    background-color: #2ba4c3;
    border-radius: 100px;
    margin-left: 5px;
    height: 35px;
    width: 35px;
    margin-top: 7px;
  }

  .all-project-btn-inquiry {
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    height: 50px;
    width: 150px !important;
  }

  .right-arrow-inquiry {
    width: 27px;
    height: 27px !important;
    padding-left: 10px;
    padding-top: 8px;
    filter: invert(1);
  }

  .all-project-btn-txt-inquiry {
    padding-top: 11px;
    padding-left: 18px;
  }

  .rapidbevtechlogo.scrolled {
    width: 65%;
    transition: all ease-in-out 0.7s;
    position: relative;
    top: 6px;
  }

  .header-container.scrolled {
    background-color: white;
    position: fixed;
    padding: 10px  30px 15px 30px;
    transition: all ease-in-out 0.7s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
  }

  .header-send-inquire.scrolled {
    right: 22px;
    padding-top: 5px;
    position: relative;
    top: 6px;
    padding-left: 70px;
}
.header-menu-txt {
  text-decoration: none;
  padding: 0px 14px;
  color: white;
  letter-spacing: 1.2px;
}
}
@media (min-width: 1080px) and (max-width: 1199px) {
  .header-send-inquire{
    display: block;
  }
  .headermain-menu {
    display: block;
}
.hero-img-container {
  margin-top: -126px;
  padding: 20px;
}
.rapidbevtechlogo {
  width: 85%;
  z-index: 5;
  position: relative;
  position: relative;
  top: -20px;
}
.header-menu-txt {
  text-decoration: none;
  padding: 0px 12px;
  color: white;
  letter-spacing: 0.8px;
}
.header-send-inquire {
  padding-left: 10px;
  padding-top: 15px;
  z-index: 5;
  cursor: pointer;
}
.all-project-btn-inquiry.all-project-btn-inquiry-header {
  width: 120px;
  position: relative;
  left: 40px;
  top: -23px;
}
.right-arrow-span-inquiry {
  background-color: #2ba4c3;
  border-radius: 100px;
  margin-left: 0px;
  height: 30px;
  width: 30px;
  margin-top: 10px;
}
.all-project-btn-txt-inquiry {
  padding-top: 14px;
  padding-left: 8px;
  font-size: 14px;
  padding-right: 3px;
}
.right-arrow-inquiry {
  width: 25px;
  height: 25px !important;
  padding-left: 6px;
  padding-top: 5px;
  filter: invert(1);
}
.header-menus {
  display: flex;
  list-style-type: none;
  padding: 5px 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.header-menu-txt.scrolled {
  color: black;
  font-weight: 600;
  padding: 0px 10px;
}
.rapidbevtechlogo.scrolled {
  width: 90%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
}
.header-send-inquire.scrolled {
  padding-top: 8px;
  position: relative;
  top: 20px;
}
.mega-menu-down-arrow-img {
  width: 20px;
  filter: invert(1);
  position: relative;
  left: -11px;
}
.header-menu-txt-black {
  text-decoration: none;
  padding: 0px 10px;
  color: black;
  letter-spacing: 1.2px;
  font-weight: 600;
  position: relative;
}
}
@media (min-width: 1024px) and (max-width: 1079px){
  .header-send-inquire{
    display: block;
  }
  .headermain-menu {
    display: block;
}
.hero-img-container {
  margin-top: -126px;
  padding: 20px;
}
.rapidbevtechlogo {
  width: 85%;
  z-index: 5;
  position: relative;
  position: relative;
  top: -20px;
}
.header-menu-txt {
  text-decoration: none;
  padding: 0px 9px;
  color: white;
  letter-spacing: 0.4px;
}
.header-send-inquire {
  padding-left: 10px;
  padding-top: 15px;
  z-index: 5;
  cursor: pointer;
}
.all-project-btn-inquiry.all-project-btn-inquiry-header {
  width: 120px;
  position: relative;
  left: 40px;
  top: -23px;
}
.right-arrow-span-inquiry {
  background-color: #2ba4c3;
  border-radius: 100px;
  margin-left: 0px;
  height: 30px;
  width: 30px;
  margin-top: 10px;
}
.all-project-btn-txt-inquiry {
  padding-top: 14px;
  padding-left: 8px;
  font-size: 14px;
  padding-right: 3px;
}
.right-arrow-inquiry {
  width: 25px;
  height: 25px !important;
  padding-left: 6px;
  padding-top: 5px;
  filter: invert(1);
}
.header-menus {
  display: flex;
  list-style-type: none;
  padding-top: 5px 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.header-menu-txt.scrolled {
  color: black;
  font-weight: 600;
  padding: 0px 10px;
}
.rapidbevtechlogo.scrolled {
  width: 90%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
}
.header-send-inquire.scrolled {
  padding-top: 8px;
  position: relative;
  top: 20px;
}
.mega-menu-down-arrow-img {
  width: 20px;
  filter: invert(1);
  position: relative;
  left: -11px;
}
.header-menu-txt-black {
  text-decoration: none;
  padding: 0px 5px;
  color: black;
  letter-spacing: 1.2px;
  font-weight: 600;
  position: relative;
}
}
@media (min-width: 810px) and (max-width: 1023px) {
  .header-send-inquire{
    display: none;
  }
  .headermain-menu {
    display: none;
}
/* temp */
.hero-img-container {
  margin-top: -94px;
  padding: 20px;
}
/* temp */
.hamburger-img {
  display: block;
  height: 25px !important;
  width: 25px;
  position: absolute;
  top: 19px;
  left: 758px;
  z-index: 11;
}
.rapidbevtechlogo {
  width: 95%;
  z-index: 55;
  position: relative;
  top: -15px;
}
.rapidbevtechlogo.scrolled {
  width: 100%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
}
img.hamburger-img.scrolled {
  position: fixed;
  display: flex;
  width: 25px;
  top: 18px;
  height: 25px !important;
  right: 17px;
  z-index: 1111;
}
.sendmail-img {
  display: block;
  height: 25px !important;
  width: 25px;
  position: absolute;
  top: 19px;
  left: 710px;
  z-index: 11;
}
.sendmail-img.scrolled {
  position: fixed;
  display: flex;
  width: 25px;
  top: 21px;
  height: 25px !important;
  right: 17px;
  z-index: 1111;
}
}
@media (min-width: 768px) and (max-width: 809px){
  .header-send-inquire{
    display: none;
  }
  .headermain-menu {
    display: none;
}
/* temp */
.hero-img-container {
  margin-top: -94px;
  padding: 20px;
}
/* temp */
.hamburger-img {
  display: block;
  height: 25px !important;
  width: 25px;
  position: absolute;
  top: 19px;
  left: 718px;
  z-index: 11;
}
.rapidbevtechlogo {
  width: 95%;
  z-index: 55;
  position: relative;
  top: -15px;
}
.rapidbevtechlogo.scrolled {
  width: 100%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
}
img.hamburger-img.scrolled {
  position: fixed;
  display: flex;
  width: 25px;
  top: 18px;
  height: 25px !important;
  right: 17px;
  z-index: 1111;
}
.sendmail-img {
  display: block;
  height: 25px !important;
  width: 25px;
  position: absolute;
  top: 19px;
  left: 680px;
  z-index: 11;
}
.sendmail-img.scrolled {
  position: fixed;
  display: flex;
  width: 25px;
  top: 21px;
  height: 25px !important;
  right: 17px;
  z-index: 1111;
}
}
@media (min-width: 600px) and (max-width: 767px){
  .header-send-inquire{
    display: none;
  }
  .headermain-menu {
    display: none;
}

.hero-img-container {
  margin-top: -94px;
  padding: 20px;
}

.hamburger-img {
  display: block;
  height: 20px !important;
  width: 20px;
  position: absolute;
  top: 30px;
  left: 535px;
  z-index: 1020;
}
.rapidbevtechlogo {
  width: 140%;
  z-index: 55;
  position: relative;
  top: 0px;
}
.rapidbevtechlogo.scrolled {
  width: 100%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: -2px;
}
.hamburger-img.scrolled {
  position: absolute;
  display: flex;
  width: 20px;
  top: 118px;
  height: 20px !important;
  right: 17px;
  z-index: 1111;
}
.header-container {
  padding: 20px;
  width: 100%;
  top: 0;
  z-index: 1000;
  position: relative;
  background-color: white;
}
.send-mail-two {
  height: auto !important;
  width: 7%;
  position: absolute;
  left: 326px;
  top: 26px;
}
}
@media (min-width: 425px) and (max-width: 599px){
  div#root {
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .header-send-inquire{
    display: none;
  }
  .headermain-menu {
    display: none;
}
.header-container {
  padding: 10px;
  width: 100%;
  top: 0;
  z-index: 1000;
  position: fixed;
}
.hamburger-img {
  display: block;
  height: 25px !important;
  width: 25px;
  position: fixed;
  top: 28px;
  left: 363px;
  z-index: 11;

}
.rapidbevtechlogo {
  width: 175%;
  z-index: 55;
  position: relative;
  top: 10px;
  left: 25px;
}
.rapidbevtechlogo-wrapper {
  cursor: pointer;
  padding: 0px;
}
.rapidbevtechlogo.scrolled {
  width: 150%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
  left: 0px;
}
.hamburger-img.scrolled {
  display: flex;
  width: 20px;
  top: 20px;
  height: 20px !important;
  right: 17px;
  z-index: 1111;
  position: fixed;

}
.header-container.scrolled {
  background-color: white;
  position: fixed;
  padding: 15px 30px 15px 30px;
  transition: all ease-in-out 0.7s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}
.header-two-burger {
  height: auto !important;
  width: 6%;
  position: absolute;
  left: 370px;
}
.header-two-logo {
  height: auto !important;
  width: 32%;
}
.send-mail-two {
  height: auto !important;
  width: 7%;
  position: absolute;
  left: 326px;
  top: 23px;
}
.header-two-burger {
  height: auto !important;
  width: 6%;
  position: absolute;
  left: 382px;
  top: 25px;
}
}
@media (min-width: 375px) and (max-width: 424px){
  div#root {
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .header-send-inquire{
    display: none;
  }
  .headermain-menu {
    display: none;
}
.header-container {
  padding: 10px;
  width: 100%;
  top: 0;
  z-index: 1000;
  position: fixed;
}
.hamburger-img {
  display: block;
  height: 25px !important;
  width: 25px;
  position: fixed;
  top: 28px;
  left: 315px;
  z-index: 11;

}
.rapidbevtechlogo {
  width: 175%;
  z-index: 55;
  position: relative;
  top: 10px;
  left: 25px;
}
.rapidbevtechlogo-wrapper {
  cursor: pointer;
  padding: 0px;
}
.rapidbevtechlogo.scrolled {
  width: 170%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
  left: 0px;
}
.hamburger-img.scrolled {
  display: flex;
  width: 20px;
  top: 20px;
  height: 20px !important;
  right: 17px;
  z-index: 1111;
  position: fixed;
}
.header-container.scrolled {
  background-color: white;
  position: fixed;
  padding: 15px 30px 15px 30px;
  transition: all ease-in-out 0.7s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 5px 0px;
}

.header-two-burger {
  height: auto !important;
  width: 6%;
  position: absolute;
  left: 325px;
  top: 25px;
}
.send-mail-two {
  height: auto !important;
  width: 8%;
  position: absolute;
  left: 282px;
  top: 24px;
}
}
@media (min-width: 300px) and (max-width: 374px) {
  div#root {
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .header-send-inquire{
    display: none;
  }
  .headermain-menu {
    display: none;
}
.rapidbevtechlogo {
  width: 385%;
  z-index: 55;
  position: relative;
  top: 20px;
  left: 15px;
}
.header-container {
  padding: 10px;
  width: 100%;
  top: 0;
  z-index: 1000;
}
.hamburger-img {
  display: block;
  height: 25px !important;
  width: 25px;
  position: fixed;
  top: 35px;
  left: 255px;
  z-index: 11;

}
.rapidbevtechlogo.scrolled {
  width: 350%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
  left: 0px;
}
.hamburger-img.scrolled {
  display: flex;
  width: 20px;
  top: 20px;
  height: 20px !important;
  right: 17px;
  z-index: 1111;
  position: fixed;
}
.header-two-burger {
  height: auto !important;
  width: 6%;
  position: absolute;
  left: 255px;
}
.header-two-burger {
  height: auto !important;
  width: 8%;
  position: absolute;
  left: 277px;
  top: 26px;
}
.header-two-logo {
  height: auto !important;
  width: 40%;
}
.send-mail-two {
  height: auto !important;
  width: 9%;
  position: absolute;
  left: 230px;
  top: 25px;
}   
}




@media (resolution: 120dpi) {
  .rapidbevtechlogo {
    width: 80%;
    z-index: 5;
    position: relative;
    top: -10px;
}
.header-send-inquire {
  padding-left: 80px;
  padding-top: 15px;
  z-index: 5;
  position: relative;
  top: -10px;
}
.rapidbevtechlogo.scrolled {
  width: 65%;
  transition: all ease-in-out 0.7s;
  position: relative;
  top: 0px;
}
.header-send-inquire.scrolled {
  padding-top: 15px;
  position: relative;
  top: -6px;
}
}