.subpage-hero-section-category{
    margin-top: -130px;
    overflow-x: hidden;
}
.subpageherobackground-category{
    width: 100%;
    padding: 20px
}
.subpage-hero-section-mobile-category{
    background-color: #fff;
    z-index: 99;
    display: block;
    position: relative;
    margin-top: 60px;
}
.subpageherobackground-mobile-category{
    width: 100%;
    height: 290px !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 20px;
}
.hero-section-title-category{
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2;
    text-shadow: 1px 1px #b8bfd6;
}
.hero-section-title-category-warpper {
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    top: -300px;
    left: 100px;
}
.category-section-one-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2;
    text-shadow: 1px 1px #b8bfd6;
}
.section-one-container{
    max-width: 1024px;
}
.category-section-second-txt{
    font-size: 28px;
    color: #555;
    font-weight: 500;
    line-height: 1.4;
    padding: 50px 0px;
}
.category-section-third-txt{
    font-size: 30px;
    color: #2ba4c3;
    font-weight: bold;
    line-height: 1.4;
    padding-bottom: 50px;
}
.category-one{
    max-width: 1407px;
}
.category-one-img-wrapper {
    padding-bottom: 100px;
    height: 93vh;
}
.first-img-heading-txt{
    color: white;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2;
    text-shadow: 1px 1px #b8bfd6;
    -webkit-text-stroke: 1px #000;
}
.first-img-txt-p-category{
    color: white;
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.category-first-image-txts-wrapper {
    width: 585px;
    position: relative;
    top: -620px;
    display: flex;
    flex-direction: column;
    left: 100px;
}

.category-btn-txt {
    color: black;
    margin-bottom: 0px;
    padding: 10px;
    font-size: 17px;
    z-index: 333;
    position: relative;
}
.category-img-btn{
    width: 30px;
    height: 30px !important;
    filter: invert(1);
    padding: 2px;
}
.category-btn{
    background-color: white;
    border: 1px solid black;
    display: flex;
    padding: 3px;
    border-radius: 30px;
}
.category-img-btn-wrapper {
    background-color: #2ba4c3;
    border-radius: 30px;
    padding: 3px;
    margin: 5px;
}

.category-btn:hover {
    background-color: #2ba4c3;
}
.category-btn:hover .category-btn-txt{
    color: white;
}
.category-btn:hover .category-img-btn-wrapper{
    background-color: white;
}
.category-btn:hover .category-img-btn{
    filter: invert(0);
}
.category-two {
    max-width: 1400px;
    margin-top: -480px;
    padding: 110px;
}
.category-blog-title{
    font-size: 22px;
    font-weight: 800;
    padding: 20px 0px;
    margin-bottom: 0px;
}
.category-blog-discription-txt{
    font-size: 18px;
    color: black;
    font-weight: 400;
}
.category-blog-design-wrapper{
    margin-bottom: 50px;
}
.category-three {
    max-width: 1407px;
    height: 90vh;
}
.category-three-img-wrapper {
    width: 100%;
    height: 80vh;
}
.category-third-image-txts-wrapper{
    width: 550px;
    position: relative;
    top: -670px;
    display: flex;
    flex-direction: column;
    left: 100px;
}
.third-img-heading-txt{
    color: white;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.2;
    text-shadow: 1px 1px #b8bfd6;
}
.third-img-txt-p-category{
    color: white;
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.category-contact-section-container {
    max-width: 100%;
    border-top: 2.5px solid #2ba4c3;
    margin-top: -95px;
}
.category-contact-heading{
    color: #2ba4c3;
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.2;
    text-shadow: 1px 1px #b8bfd6;
}
.category-contact-txt{
    color: #2ba4c3;
    font-size: 20px;
}
.category-contact-section-inner-container {
    padding-top: 140px;
    padding-bottom: 140px;
}
.second-right-txt-category{
    color: #2ba4c3;
    font-size: 18px;
}
.arrow-link-category {
    width: 25px;
    height: 25px;
    margin-right: 20px;
}
.arrow-right-txt-wrapper-category{
    display: flex;
    padding-top: 50px;
}
.Frequently-read-articles-section{
    max-width: 1400px;
}

.frequently-read-txt {
    font-size: 32px;
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
}
.frequently-read-txt:after {
    background: black;
    content: "";
    flex: 1 1;
    height: 1px;
    margin-left: 2rem;
}
.frequently-react-block-wapper{
 margin-top: 70px;
 width: 360px;
}
.down-arrow-wrapper-category{
    width: 60px;
}
.centre-down-arrow{
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}
.no-text-decoration{
    text-decoration: none;
}
.overlay-hightlights {
    position: absolute;
    top: 0px;
    left: 14px;
    width: 98%;
    height: 89%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
    opacity: 1;
    transition: opacity 0.3s ease;
    align-items: end;
    display: flex;
}
.coming-soon-container{
    margin-top: -150px;
}
.second-cs-banner-w{
    width: 740px;
}
@media (min-width: 1400px) and (max-width: 1440px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: -240px;
        left: 60px;
    }
    .category-one {
        max-width: 1250px;
    }
    .category-first-image-txts-wrapper {
        top: -590px;
    }
    .overlay-hightlights {
        position: absolute;
        top: 0px;
        left: 13px;
        width: 98%;
        height: 87%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
        opacity: 1;
        transition: opacity 0.3s ease;
        align-items: end;
        display: flex;
    }
}
@media (min-width: 1280px) and (max-width: 1399px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: -240px;
        left: 60px;
    }
    .category-one {
        max-width: 1100px;
    }
    .category-first-image-txts-wrapper {
        top: -520px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: 100vh;
    }
    .overlay-hightlights {
        position: absolute;
        top: 0px;
        left: 12px;
        width: 98%;
        height: 86%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
        opacity: 1;
        transition: opacity 0.3s ease;
        align-items: end;
        display: flex;
    }
}

@media (min-width: 1080px) and (max-width: 1279px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: -200px;
        left: 60px;
    }
    .category-one {
        max-width: 960px;
    }
    .category-first-image-txts-wrapper {
        top: -520px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: 80vh;
    }
    .section-one-container {
        max-width: 860px;
        margin-top: -50px;
    }
    .category-section-second-txt {
        font-size: 28px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 20px 0px;
    }
    .overlay-hightlights {
        position: absolute;
        top: 0px;
        left: 13px;
        width: 934px;
        height: 85%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
        opacity: 1;
        transition: opacity 0.3s ease;
        align-items: end;
        display: flex;
    }
    .coming-soon-container {
        margin-top: -100px;
    }
}

@media (min-width: 1024px) and (max-width: 1079px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: -200px;
        left: 60px;
    }
    .category-one {
        max-width: 960px;
    }
    .category-first-image-txts-wrapper {
        top: -520px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: 80vh;
    }
    .section-one-container {
        max-width: 860px;
        margin-top: -50px;
    }
    .category-section-second-txt {
        font-size: 28px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 20px 0px;
    }
    .overlay-hightlights {
        position: absolute;
        top: 0px;
        left: 13px;
        width: 934px;
        height: 85%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
        opacity: 1;
        transition: opacity 0.3s ease;
        align-items: end;
        display: flex;
    }
    .coming-soon-container {
        margin-top: -100px;
    }
}

@media (min-width: 810px) and (max-width: 1023px) {
    .subpage-hero-section-category {
        margin-top: -75px;
        overflow-x: hidden;
    }
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: -155px;
        left: 60px;
    }
    .section-one-container {
        max-width: 780px;
    }
    .section-one-container {
        max-width: 780px;
        margin-top: -60px;
    }
    .category-section-one-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .category-section-second-txt {
        font-size: 28px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 10px 0px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: 53vh;
    }
    .category-one {
        max-width: 777px;
    }
    .first-img-heading-txt {
        color: white;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
        -webkit-text-stroke: 1px #000;
    }
    .category-first-image-txts-wrapper {
        width: 585px;
        position: relative;
        top: -380px;
        display: flex;
        flex-direction: column;
        left: 50px;
    }
    .hero-section-title-category {
        -webkit-text-stroke: 1px black;
        color: white;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .overlay-hightlights {
        position: absolute;
        top: 0px;
        left: 13px;
        width: 97%;
        height: 81%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
        opacity: 1;
        transition: opacity 0.3s ease;
        align-items: end;
        display: flex;
    }
    .coming-soon-container {
        margin-top: -100px;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .subpage-hero-section-category {
        margin-top: -75px;
        overflow-x: hidden;
    }
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: -155px;
        left: 60px;
    }
    .section-one-container {
        max-width: 780px;
    }
    .section-one-container {
        max-width: 730px;
        margin-top: -60px;
    }
    .category-section-one-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .category-section-second-txt {
        font-size: 28px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 10px 0px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: 53vh;
    }
    .category-one {
        max-width: 740px;
    }
    .first-img-heading-txt {
        color: white;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
        -webkit-text-stroke: 1px #000;
    }
    .category-first-image-txts-wrapper {
        width: 585px;
        position: relative;
        top: -330px;
        display: flex;
        flex-direction: column;
        left: 50px;
    }
    .hero-section-title-category {
        -webkit-text-stroke: 1px black;
        color: white;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .first-img-txt-p-category {
        color: white;
        font-size: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .category-section-third-txt {
        font-size: 30px;
        color: #2ba4c3;
        font-weight: bold;
        line-height: 1.4;
        padding-bottom: 20px;
    }
    .overlay-hightlights {
        position: absolute;
        top: 0px;
        left: 11px;
        width: 97%;
        height: 78%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0.6, 0.6, 0.7));
        opacity: 1;
        transition: opacity 0.3s ease;
        align-items: end;
        display: flex;
    }
    .coming-soon-container {
        margin-top: -100px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 20px;
        left: 20px;
    }
    .subpageherobackground-mobile-category {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: 0px;
    }
    .section-one-container {
        max-width: 400px;
        margin-top: 0px;
        z-index: 995;
        position: relative;
    }
    .category-section-one-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .category-section-second-txt {
        font-size: 22px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 0px 0px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: auto;
    }
    .category-one {
        max-width: 400px;
    }
    .first-img-heading-txt {
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
        -webkit-text-stroke: 0px #000;
    }
    .category-first-image-txts-wrapper {
        width: 325px;
        position: relative;
        top: 30px;
        display: flex;
        flex-direction: column;
        left: 0px;
    }
    .hero-section-title-category {
        -webkit-text-stroke: 0px black;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .first-img-txt-p-category {
        color: black;
        font-size: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .category-section-third-txt {
        font-size: 26px;
        color: #2ba4c3;
        font-weight: bold;
        line-height: 1.4;
        padding-bottom: 20px;
    }
  
    .overlay-hightlights {
        align-items: end;
        background: linear-gradient(180deg, #0000, #000101b3);
        display: flex;
        height: 31%;
        left: 13px;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: opacity .3s ease;
        width: 94%;
    }
    .coming-soon-container {
        margin-top: -10px;
    }
}

@media (min-width: 393px) and (max-width: 424px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 20px;
        left: 20px;
    }
    .subpageherobackground-mobile-category {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: 0px;
    }
    .section-one-container {
        max-width: 400px;
        margin-top: 0px;
        z-index: 995;
        position: relative;
    }
    .category-section-one-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .category-section-second-txt {
        font-size: 22px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 0px 0px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: auto;
    }
    .category-one {
        max-width: 380px;
    }
    .first-img-heading-txt {
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
        -webkit-text-stroke: 0px #000;
    }
    .category-first-image-txts-wrapper {
        width: 325px;
        position: relative;
        top: 30px;
        display: flex;
        flex-direction: column;
        left: 0px;
    }
    .hero-section-title-category {
        -webkit-text-stroke: 0px black;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .first-img-txt-p-category {
        color: black;
        font-size: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .category-section-third-txt {
        font-size: 26px;
        color: #2ba4c3;
        font-weight: bold;
        line-height: 1.4;
        padding-bottom: 20px;
    }
    .overlay-hightlights {
        align-items: end;
        background: linear-gradient(180deg, #0000, #000101b3);
        display: flex;
        height: 30%;
        left: 12px;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: opacity .3s ease;
        width: 94%;
    }
    .coming-soon-container {
        margin-top: -10px;
    }
}

@media (min-width: 375px) and (max-width: 392px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 20px;
        left: 20px;
    }
    .subpageherobackground-mobile-category {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: 0px;
    }
    .section-one-container {
        max-width: 360px;
        margin-top: 0px;
        z-index: 995;
        position: relative;
    }
    .category-section-one-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .category-section-second-txt {
        font-size: 22px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 0px 0px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: auto;
    }
    .category-one {
        max-width: 360px;
    }
    .first-img-heading-txt {
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
        -webkit-text-stroke: 0px #000;
    }
    .category-first-image-txts-wrapper {
        width: 325px;
        position: relative;
        top: 30px;
        display: flex;
        flex-direction: column;
        left: 0px;
    }
    .hero-section-title-category {
        -webkit-text-stroke: 0px black;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .first-img-txt-p-category {
        color: black;
        font-size: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .category-section-third-txt {
        font-size: 26px;
        color: #2ba4c3;
        font-weight: bold;
        line-height: 1.4;
        padding-bottom: 20px;
    }
    .overlay-hightlights {
        align-items: end;
        background: linear-gradient(180deg, #0000, #000101b3);
        display: flex;
        height: 29%;
        left: 11px;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: opacity .3s ease;
        width: 94%;
    }
    .coming-soon-container {
        margin-top: -10px;
    }
}
@media (min-width: 360px) and (max-width: 374px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 20px;
        left: 20px;
    }
    .subpageherobackground-mobile-category {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: 0px;
    }
    .section-one-container {
        max-width: 340px;
        margin-top: 0px;
        z-index: 995;
        position: relative;
    }
    .category-section-one-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .category-section-second-txt {
        font-size: 22px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 0px 0px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: auto;
    }
    .category-one {
        max-width: 340px;
    }
    .first-img-heading-txt {
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
        -webkit-text-stroke: 0px #000;
    }
    .category-first-image-txts-wrapper {
        width: 325px;
        position: relative;
        top: 30px;
        display: flex;
        flex-direction: column;
        left: 0px;
    }
    .hero-section-title-category {
        -webkit-text-stroke: 0px black;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .first-img-txt-p-category {
        color: black;
        font-size: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .category-section-third-txt {
        font-size: 26px;
        color: #2ba4c3;
        font-weight: bold;
        line-height: 1.4;
        padding-bottom: 20px;
    }
    .overlay-hightlights {
        align-items: end;
        background: linear-gradient(180deg, #0000, #000101b3);
        display: flex;
        height: 28%;
        left: 11px;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: opacity .3s ease;
        width: 93%;
    }
    .coming-soon-container {
        margin-top: -10px;
    }
}

@media (min-width: 300px) and (max-width: 359px) {
    .hero-section-title-category-warpper {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        top: 20px;
        left: 20px;
    }
    .subpageherobackground-mobile-category {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: 0px;
    }
    .section-one-container {
        max-width: 340px;
        margin-top: 0px;
        z-index: 995;
        position: relative;
    }
    .category-section-one-heading {
        -webkit-text-stroke: 0px white;
        color: black;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .category-section-second-txt {
        font-size: 22px;
        color: #555;
        font-weight: 500;
        line-height: 1.4;
        padding: 0px 0px;
    }
    .category-one-img-wrapper {
        padding-bottom: 100px;
        height: auto;
    }
    .category-one {
        max-width: 300px;
    }
    .first-img-heading-txt {
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
        -webkit-text-stroke: 0px #000;
    }
    .category-first-image-txts-wrapper {
        width: 295px;
        position: relative;
        top: 30px;
        display: flex;
        flex-direction: column;
        left: 0px;
    }
    .hero-section-title-category {
        -webkit-text-stroke: 0px black;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.2;
        text-shadow: 1px 1px #b8bfd6;
    }
    .first-img-txt-p-category {
        color: black;
        font-size: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .category-section-third-txt {
        font-size: 26px;
        color: #2ba4c3;
        font-weight: bold;
        line-height: 1.4;
        padding-bottom: 20px;
    }
    .overlay-hightlights {
        align-items: end;
        background: linear-gradient(180deg, #0000, #000101b3);
        display: flex;
        height: 24%;
        left: 11px;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: opacity .3s ease;
        width: 93%;
    }
    .coming-soon-container {
        margin-top: -10px;
    }
}