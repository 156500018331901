.about-us-main{
    margin-top: -130px;
    overflow-x: hidden;
}
.about-us-img{
    padding: 20px;
    width: 100%;
}
.custom-container-aboutus-first{
    margin-bottom: 100px;
    margin-top: -50px;
}
.about-first-section{
    top: -330px;
    left: 120px;
    position: relative;
    display: flex;
}
.about-us-rapid-heading{
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}
.about-us-rapid-txt{
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    position: relative;
}
.sucess-img-wrapper img{
    width: 100%;
}
.rapid-belief-img{
    width: 100%;
}
.rapid-story-img-wrapper img{
    width: 100%;
}
.how-we-start {
    -webkit-text-stroke: 1px #fff;
    color: #000;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}
.how-we-start-txt{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.list-what-is-setup{
    display: flex;
}
.list-wos-txt{
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
}
.about-third-section-wrapper{
    display: flex;
    margin-top: 100px;

}
.about-third-section-threediv{
    text-align: center;
    padding: 40px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    margin: 5px;
    width: 420px;
}
.onethree-div{
    text-align: center;
    padding: 40px;
    background-color: #2ba4c3;;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    margin: 5px;
    width: 420px;
}
.onethree-div-txt{
    font-size: 21px;
    line-height: 28px;
    color: white;
    font-weight: 400;
}
.onethree-div-title{
    font-size: 40px;
    color: white;
}

.about-third-section-threediv:hover{
   background-color: #2ba4c3;
}
.about-third-section-threediv:hover .thrid-section-title{
    color: white;
}
.about-third-section-threediv:hover .third-section-txt{
    color: white;
}
.about-first-container{
    margin-bottom: 100px;
}
.about-second-container{
    margin-bottom: 100px;
}
.thrid-section-title{
    font-size: 40px;
}
.third-section-txt{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}
.boxes-wrapper{
    position: absolute;
    right: 498px;
    top: -150px;
    display: flex;
    padding: 50px;
}
.box {
    transition: transform 100ms linear, opacity 500ms linear,;
    color: white;
    font-size: 24px;
    position: relative;
    opacity: 1; /* Initial opacity */
  }
  .boxes-wrapper:hover {
    animation: none; /* Disable default animation on hover */
    transition: transform 3s ease; /* Add transition effect */
}

  @keyframes move {
    0% {
      transform: translate(-50px, 40px,);
      opacity: 1;
    }
    25% {
      transform: translate(100px, 50px);
      opacity: 0.9;
    }
    50% {
      transform: translate(-100px, -50px);
      opacity: 0.5;
    }
    75% {
      transform: translate(100px, -50px);
      opacity: 0.8;
    }
    100% {
      transform: translate(-100px, 50px);
      opacity: 1;
    }
  }

  .background {
    display: grid;
    place-items: center;
    height: 60vh;
    padding: 0 0 0 0;
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 1320px;
  }
  
  .img-card {
    position: relative;
    left: 0px;
    width: 285px;
    border-radius: 1em;
    height: 315px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    transition: 0.3s ease-in-out;
    flex: 0.25; 
    background: beige;
  }
  
  .img-card img {
    /* min-height: 80vh; */
    width: auto;
    /* height: auto !important; */
    height: 380px !important;
  }
  
  .img-card:hover {
    flex: 0.6 1;
    cursor: pointer;
  }
  .img-card-heading{
    position: absolute;
    font-size: 24px;
    color: white;
    font-weight: 700;
    bottom: 5px;
    left: 20px;
  }
  .img-card-txt{
    position: absolute;
     font-size: 18px;
     color: white;
     font-weight: 500;
     bottom: -50px;
     left: 20px;
     display: none;
  }
.img-card:hover .img-card-txt{
    display: block;
    bottom: 10px;
    transition: all 0.5s ease;
}
.img-card:hover .img-card-heading{
    bottom: 70px;
}

.default-img-card{
    position: relative;
    left: 0px;
    width: 285px;
    border-radius: 1em;
    height: 315px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    transition: 0.3s ease-in-out;
    flex: 0.6 1;
    background: beige;
}
.default-img-card img{
    /* min-height: 80vh; */
    width: auto;
    /* height: auto !important; */
    height: 380px !important;
}
.custom-why-should-you{
    margin-top: 100px;
    margin-bottom: 100px;
}
.default-img-card-heading{
    position: absolute;
    font-size: 24px;
    color: white;
    font-weight: 700;
    bottom: 40px;
    left: 20px;
}
.default-img-card-txt{
    position: absolute;
    font-size: 18px;
    color: white;
    font-weight: 500;
    bottom: 5px;
    left: 20px;
    display: block;
}
.values-heading{
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    display: flex;
    top: 80px;
    left: 290px;
}
.large-small-img-wrapper{
    background: #31a6c4;
    overflow-x: hidden;
}
.board-of-director-section{
    max-width: 1800px;
}
.bod-txt-1 {
    background-color: #fbfaf9;
    border-radius: 30px;
    border: 1px solid #ebeae9;
    padding: 40px;
    height: 638px;
    margin-left: 5px;
    margin-right: 12px;
    width: 24%;
}
.bod-img-1{
    border-radius: 30px;
}
.director-img{
    border-radius: 30px;
    border: 1px solid #ebeae9;
    width: 100%;
}
.bod-inner-txt{
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}
.bod-inner-txt-p{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.bod-inner-txt-title{
    font-size: 45px;
    color: black;
    font-weight: bold;
}
.director-name{
    font-size: 24px;
    color: white;
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 2px;
}
.director-position{
    font-size: 16px;
    color: white;
    margin-bottom: 10px;

}
.director-detail-wrapper{
    background-color: rgba(161, 163, 167, 0.29);
    border-color: rgba(255, 255, 255, 0.12);
    padding: 8px 0px 8px 20px;
    position: relative;
    bottom: 150px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 20px;
    backdrop-filter: blur(2rem);
    border: 0.1px solid #acadb1;
}

.work-culture-section{
    background-color: #31a6c4;
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url("../images/aboutus/Work-Culture-at-Rapid-Bevtech.webp");
    background-repeat: no-repeat;
    background-size: cover;
}
.work-culture-heading{
    -webkit-text-stroke: 1px black;
    color: white;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    text-align: center;
    margin-bottom: 80px;
}


/* ------------ images ------------------*/

.hero-faces[data-v-3d7514a1] {
    display: flex;
    align-items: center;
}
.mt-4 {
    margin-top: 1rem !important;
}
.hero-faces .face-column[data-v-3d7514a1] {
    width: 16.6%;
    padding: 0 .75rem;
}
.hero-faces .face-column .face-element[data-v-3d7514a1] {
    position: relative;
    min-height: 280px;
    width: 100%;
    border-radius: 1.5rem;
    box-shadow: var(--light-box-shadow);
    background-size: cover;
    background-repeat: no-repeat;
}
.hero-faces .face-column[data-v-3d7514a1] {
    width: 16.6%;
    padding: 0 .75rem;
}
.hero-faces .face-column .face-element[data-v-3d7514a1]:not(:last-child) {
    margin-bottom: 1.5rem;
}
.hero-faces .face-column .face-element[data-v-3d7514a1] {
    position: relative;
    min-height: 280px;
    width: 100%;
    border-radius: 1.5rem;
    box-shadow: var(--light-box-shadow);
    background-size: cover;
    background-repeat: no-repeat;
}
.hero-faces .face-column .face-element.is-size-md[data-v-3d7514a1] {
    min-height: 340px;
}
.hero-faces .face-column.is-2x[data-v-3d7514a1] {
    width: 33.2%;
}
.hero-faces .face-column .face-element[data-v-3d7514a1]:not(:last-child) {
    margin-bottom: 1.5rem;
}
.hero-faces .face-column.is-4x[data-v-3d7514a1] {
    width: 66.4%;
}
.hero-faces .face-column .face-element.is-size-sm[data-v-3d7514a1] {
    min-height: 180px;
}
.hero-faces .face-column.is-2x[data-v-3d7514a1] {
    width: 33.2%;
}
.hero-faces .face-column .face-element.is-size-xs[data-v-3d7514a1] {
    min-height: 100px;
}
.hero-faces .face-column .face-element.is-size-md[data-v-3d7514a1] {
    min-height: 340px;
}
.hero-faces .face-column .face-element.is-size-sm[data-v-3d7514a1] {
    min-height: 180px;
}
.img-one{
    background-image: url("../images/aboutus/image-1.webp");
}
.img-two{
    background-image: url("../images/aboutus/image-2.webp");
}
.img-three{
    background-image: url("../images/aboutus/image-3.webp");
}
.img-four{
    background-image: url("../images/aboutus/image-4.webp");
}
.img-five{
    background-image: url("../images/aboutus/image-5.webp");
}
.img-six{
    background-image: url("../images/aboutus/image-6.webp");
}
.img-seven{
    background-image: url("../images/aboutus/image-7.webp");
}
.img-eight{
    background-image: url("../images/aboutus/image-8.webp");
}
.img-nine{
    background-image: url("../images/aboutus/image-9.webp");
}
.img-ten{
    background-image: url("../images/aboutus/image-10.webp");
}
.rapid-belief-main{
    background-color: rgba(247, 247, 247, 1);
    margin-bottom: 100px;
}
.rapid-belief-story-container{
    padding: 100px 0px;
    max-width: 1400px;
}
.rapid-belief-story-left {
    background-color: white;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06)
  
}
.rapid-belief-story-right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rapid-story-img-wrapper{
    width: 600px;
    display:flex;
    
}
.bule-title-belief{
    color: black;
    font-size: 25px;
    font-weight: 500;
}
.title-second-left-belief{
    font-size: 36px;
    font-weight: 700;
    color: #2ba4c3;
}
.txt-belief-left{
    color: #3d3d3d;
    font-size: 21px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;

}
.text-wrapper-belief{
    padding: 30px;
}
.can-bottle-mix{
    width: 260px;
}
.can-bottle-mix-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -40px;
}
.custom-about-us-video-yt{
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}
.how-we-start-first-heading{
    -webkit-text-stroke: 1px #fff;
    color: #000;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
    margin-bottom: 25px;
}
.sucess-img-wrapper{
    padding: 20px;
}

@media (min-width: 1400px) and (max-width: 1440px) {
    .about-first-section {
        top: -270px;
        left: 120px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 24%;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 40px;
        height: 468px !important;
        width: 23%;
        margin-left: 35px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 120px;
        margin-left: 30px;
        margin-right: 30px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .values-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: 80px;
        left: 60px;
        width: fit-content;
    }
    .rapid-belief-story-container {
        padding: 100px 0px;
        max-width: 1200px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 47%;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        position: relative;
        left: 50px;
    }
}
@media (min-width: 1280px) and (max-width: 1399px) {
    .about-first-section {
        top: -230px;
        left: 100px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 24%;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 20px !important;
        height: 348px !important;
        width: 23%;
        margin-left: 35px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 120px;
        margin-left: 30px;
        margin-right: 30px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .values-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: 80px;
        left: 20px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .about-us-main {
        margin-top: -120px;
        overflow-x: hidden;
    }
    .about-us-first-left {
        width: 35%;
        display: flex;
        align-items: center;
    }
    .about-us-first-right{
      width: 65%;
    }
    .custom-container-aboutus-first {
        max-width: 1050px;
    }
    .about-second-container {
        margin-bottom: 100px;
        max-width: 1050px;
    }
    .onethree-div {
        text-align: center;
        padding: 30px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 340px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 30px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 340px;
    }
    .custom-container-third-aboutus{
        width: 1050px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 110px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .work-culture-container{
        width: 1050px;
    }
    .hero-faces .face-column[data-v-3d7514a1] {
        width: 16.6%;
        padding: 0 0.25rem;
    }
    .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-width: 1050px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 70px;
        left: 20px;
    }
    .rapid-belief-story-container {
        padding: 100px 0px;
        max-width: 1180px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 47%;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        position: relative;
        left: 50px;
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .about-first-section {
        top: -230px;
        left: 100px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 24%;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 20px !important;
        height: 348px !important;
        width: 23%;
        margin-left: 35px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 120px;
        margin-left: 30px;
        margin-right: 30px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .values-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: 80px;
        left: 20px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .about-us-main {
        margin-top: -130px;
        overflow-x: hidden;
    }
    .about-us-first-left {
        width: 35%;
        display: flex;
        align-items: center;
    }
    .about-us-first-right{
      width: 65%;
    }
    .custom-container-aboutus-first {
        max-width: 1050px;
        margin-top: -100px;
    }
    .about-second-container {
        margin-bottom: 100px;
        max-width: 1050px;
    }
    .onethree-div {
        text-align: center;
        padding: 30px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 340px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 30px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 340px;
    }
    .custom-container-third-aboutus{
        width: 1050px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 110px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .work-culture-container{
        width: 1050px;
    }
    .hero-faces .face-column[data-v-3d7514a1] {
        width: 16.6%;
        padding: 0 0.25rem;
    }
    .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-width: 1050px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 70px;
        left: 20px;
    }
    .rapid-belief-story-container {
        padding: 100px 0px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 50%;
        padding: 0px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
}

@media (min-width: 1024px) and (max-width: 1079px) {
    .about-first-section {
        top: -230px;
        left: 100px;
        position: relative;
        display: flex;
        width: fit-content;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 24%;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 20px !important;
        height: 332px !important;
        width: 23%;
        margin-left: 35px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 120px;
        margin-left: 30px;
        margin-right: 30px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .values-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: 80px;
        left: 20px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .about-us-main {
        margin-top: -120px;
        overflow-x: hidden;
    }
    .about-us-first-left {
        width: 35%;
        display: flex;
        align-items: center;
    }
    .about-us-first-right{
      width: 65%;
    }
    .custom-container-aboutus-first {
        max-width: 990px;
    }
    .about-second-container {
        margin-bottom: 100px;
        max-width: 990px;
    }
    .onethree-div {
        text-align: center;
        padding: 30px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 320px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 30px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 320px;
    }
    .custom-container-third-aboutus{
        width: 990px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 110px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .work-culture-container{
        width: 990px;
    }
    .hero-faces .face-column[data-v-3d7514a1] {
        width: 16.6%;
        padding: 0 0.25rem;
    }
    .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-width: 990px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 70px;
        left: 20px;
    }
    .bod-inner-txt-title {
        font-size: 35px;
        color: black;
        font-weight: bold;
    }
    .rapid-belief-story-container {
        padding: 100px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 60%;
        padding: 0px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 50%;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        position: relative;
        left: 0px;
    }
}

@media (min-width: 810px) and (max-width: 1023px) {
    .about-us-main {
        margin-top: -95px;
        overflow-x: hidden;
    }
    .about-first-section {
        top: -180px;
        left: 90px;
        position: relative;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
    }
    .about-us-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-row-aboutus-first{
        flex-direction: column;
    }
    .about-us-first-left{
        width: 100%;
    }
    .about-us-first-right{
        width: 100%;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -100px;
    }
    .how-we-start {
        -webkit-text-stroke: 1px #fff;
        color: #000;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .onethree-div {
        text-align: center;
        padding: 20px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 230px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 20px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 230px;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 15px;
        height: 254px;
        margin-left: 20px;
        width: 24%;
    }
    .bod-inner-txt-title {
        font-size: 22px;
        color: black;
        font-weight: bold;
    }
    .bod-inner-txt-p {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 150px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .director-name {
        font-size: 16px;
        color: white;
        font-weight: bold;
        margin-bottom: 1px;
        margin-top: 2px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 90px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .work-culture-container{
        width: 800px;
    }
    .hero-faces .face-column[data-v-3d7514a1] {
        width: 16.6%;
        padding: 0 0.25rem;
    }
    .hero-faces .face-column .face-element[data-v-3d7514a1] {
        position: relative;
        min-height: 190px;
        width: 100%;
        border-radius: 1.5rem;
        box-shadow: var(--light-box-shadow);
        background-size: cover;
        background-repeat: no-repeat;
    }
    .hero-faces .face-column .face-element.is-size-md[data-v-3d7514a1] {
        min-height: 250px;
    }
    .hero-faces .face-column .face-element.is-size-xs[data-v-3d7514a1] {
        min-height: 70px;
    }
    .values-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: 80px;
        left: 40px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-width: 720px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 90px;
        left: 20px;
    }
    .img-card:hover .img-card-heading {
        bottom: 100px;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 24%;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -100px;
        max-width: 770px;
    }
    .rapid-belief-story-container {
        padding: 100px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 100%;
        padding: 70px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
        padding-top: 100px;
    }
    .rapid-belief-story-row {
        display: flex;
        flex-direction: column;
    }
    .sucess-img-wrapper {
        padding: 20px;
        width: 400px;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .about-us-main {
        margin-top: -95px;
        overflow-x: hidden;
    }
    .about-first-section {
        top: -180px;
        left: 90px;
        position: relative;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
    }
    .about-us-rapid-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-row-aboutus-first{
        flex-direction: column;
    }
    .about-us-first-left{
        width: 100%;
    }
    .about-us-first-right{
        width: 100%;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -100px;
    }
    .how-we-start {
        -webkit-text-stroke: 1px #fff;
        color: #000;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .onethree-div {
        text-align: center;
        padding: 20px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 230px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 20px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 5px;
        width: 230px;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 8px;
        height: 254px;
        margin-left: 20px;
        width: 24%;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 23%;
    }

    .bod-inner-txt-title {
        font-size: 22px;
        color: black;
        font-weight: bold;
    }
    .bod-inner-txt-p {
        color: #3d3d3d;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 150px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .director-name {
        font-size: 16px;
        color: white;
        font-weight: bold;
        margin-bottom: 1px;
        margin-top: 2px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 90px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .work-culture-container{
        width: 800px;
    }
    .hero-faces .face-column[data-v-3d7514a1] {
        width: 16.6%;
        padding: 0 0.25rem;
    }
    .hero-faces .face-column .face-element[data-v-3d7514a1] {
        position: relative;
        min-height: 190px;
        width: 100%;
        border-radius: 1.5rem;
        box-shadow: var(--light-box-shadow);
        background-size: cover;
        background-repeat: no-repeat;
    }
    .hero-faces .face-column .face-element.is-size-md[data-v-3d7514a1] {
        min-height: 250px;
    }
    .hero-faces .face-column .face-element.is-size-xs[data-v-3d7514a1] {
        min-height: 70px;
    }
    .values-heading {
        -webkit-text-stroke: 1px #000;
        color: #fff;
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: 80px;
        left: 40px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        max-width: 720px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 90px;
        left: 20px;
    }
    .img-card:hover .img-card-heading {
        bottom: 100px;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -100px;
        max-width: 740px;
    }
    .rapid-belief-story-container {
        padding: 100px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 100%;
        padding: 50px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
        padding-top: 100px;
    }
    .rapid-belief-story-row {
        display: flex;
        flex-direction: column;
    }
    .sucess-img-wrapper {
        padding: 20px;
        width: 400px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .about-us-img-mobile {
        border-radius: 35px;
        height: auto !important;
        margin-top: -110px;
        padding: 20px;
        width: 100%;
    }
    .about-us-main {
        margin-top: 60px;
        overflow-x: hidden;
    }
    .about-first-section {
        top: 30px;
        left: 20px;
        position: relative;
        display: flex;
        width: 330px;
    }
    .about-us-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: #000;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-row-aboutus-first {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .about-us-first-left{
        width: 100%;
    }
    .about-us-first-right{
        width: 100%;
    }
    .custom-container-third-aboutus {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 50px !important;
    }
    .how-we-start {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .about-second-container {
        margin-bottom: 100px;
        margin-top: -50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .onethree-div {
        text-align: center;
        padding: 40px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 390px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 40px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 390px;
    }
    .about-third-section-wrapper {
        display: flex;
        margin-top: 20px;
        margin-left: -18px;
    }
    .custom-container-third-aboutus {
        padding-left: 15px;
        padding-right: 15px;
    }
    .custom-row-third-aboutus-left {
        width: 100%;
        margin-top: -70px;
    }
    .work-culture-section {
        background-color: #31a6c4;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .custom-row-third-aboutus-right{
        width: 100%;
    }
    .board-of-director-row {
        display: flex;
        flex-direction: column;
        margin: 0px;
        margin-top: -60px;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 40px;
        height: 520px;
        width: 93%;
        margin-bottom: 30px;
        margin-left: 14px;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 100%;
    }
    .director-img-wrapper {
        height: auto;
        margin-bottom: -80px;
    }
    .work-culture-heading {
        -webkit-text-stroke: 0px black;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
    }
    .hero-faces .face-column[data-v-3d7514a1] {
        width: 100%;
        padding: 0 .75rem;
        margin-bottom: 10px;
    }
    .hero-faces[data-v-3d7514a1] {
        display: block;
        align-items: center;
    }
    .hero-faces .face-column .face-element[data-v-3d7514a1]:not(:last-child) {
        margin-bottom: 10px;
    }
    .hero-faces .face-column.is-2x[data-v-3d7514a1] {
        width: 100%;
    }
    .hero-faces .face-column.is-4x[data-v-3d7514a1] {
        width: 106%;
        margin-left: -12px;
    }
    .hero-faces .face-column .face-element[data-v-3d7514a1] {
        position: relative;
        min-height: 280px;
        width: 100%;
        border-radius: 1.5rem;
        box-shadow: var(--light-box-shadow);
        background-size: cover;
        background-repeat: no-repeat;
    }
    .hero-faces .face-column .face-element.is-size-md[data-v-3d7514a1] {
        min-height: 280px;
    }
    .hero-faces .face-column .face-element.is-size-sm[data-v-3d7514a1] {
        min-height: 280px;
    }
    .hero-faces .face-column .face-element.is-size-xs[data-v-3d7514a1] {
        min-height: 280px;
    }
    .values-heading {
        -webkit-text-stroke: 0px #000;
        color: #fff;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: -10px;
        left: 30px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .gallery {
        display: flex;
        flex-wrap: initial;
        gap: 16px;
        max-width: 1210px;
        flex-direction: column;
    }
    .background {
        display: grid;
        place-items: center;
        height: auto;
        padding: 0 0 0 0;
    }
    .default-img-card {
        position: relative;
        left: 0px;
        width: 380px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.6 1;
        background: beige;
    }
    .img-card {
        position: relative;
        left: 0px;
        width: 380px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.25 1;
        background: beige;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 80px;
        left: 20px;
    }
    .large-small-img-wrapper {
        background: #31a6c4;
        overflow-x: hidden;
        padding-bottom: 50px;
    }
    .custom-why-should-you-row{
        display: flex;
        flex-direction: column;
    }
    .custom-why-should-you-left{
        width: 100%;
    }
    .custom-why-should-you-right{
        width: 100%;
    }
    .custom-why-should-you {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .how-we-start-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .onethree-div-txt {
        font-size: 18px;
        line-height: 28px;
        color: white;
        font-weight: 400;
    }
    .third-section-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    .bod-inner-txt-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .list-wos-txt {
        font-size: 18px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .onethree-div-title {
        font-size: 2rem;
        color: white;
    }
    .thrid-section-title {
        font-size: 2rem;
    }
    .bod-inner-txt-title {
        font-size: 2rem;
        color: black;
        font-weight: bold;
    }
    .wc-img-warpper{
        display: flex;
        padding: 10px;
    }
    .img-wrapper {
        width: 175px;
        height: auto !important;
        margin: 11px;
    }
    .img-wrapper img {
        border-radius: 20px;
    }
    .rapid-belief-story-container {
        padding: 50px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 100%;
        padding: 0px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
        padding-top: 50px;
    }
    .rapid-belief-story-row {
        display: flex;
        flex-direction: column;
    }
    .custom-container-aboutus-first {
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .how-we-start-first-heading {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-bottom: 25px;
    }
    .sucess-img-wrapper {
        padding: 60px;
    }
}
@media (min-width: 393px) and (max-width:424px) {
    .about-us-img-mobile {
        border-radius: 35px;
        height: auto !important;
        margin-top: -120px;
        padding: 20px;
        width: 100%;
    }
    .about-us-main {
        margin-top: 60px;
        overflow-x: hidden;
    }
    .about-first-section {
        top: 20px;
        left: 20px;
        position: relative;
        display: flex;
        width: 320px;
    }
    .about-us-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: black;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-row-aboutus-first {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .about-us-first-left{
        width: 100%;
    }
    .about-us-first-right{
        width: 100%;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -200px;
    }
    .how-we-start {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .about-second-container {
        margin-bottom: 100px;
        margin-top: -50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .onethree-div {
        text-align: center;
        padding: 30px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 330px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 30px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 330px;
    }
    .about-third-section-wrapper {
        display: flex;
        margin-top: 20px;
    }
    .custom-container-third-aboutus {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px !important;
    }
    .custom-row-third-aboutus-left {
        width: 100%;
        margin-top: -60px;
    }
    .work-culture-section {
        background-color: #31a6c4;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .custom-row-third-aboutus-right{
        width: 100%;
    }
    .board-of-director-row {
        display: flex;
        flex-direction: column;
        margin: 0px;
        margin-top: -60px;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 40px;
        height: 520px;
        width: 92%;
        margin-bottom: 30px;
        margin-left: 10px;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 100%;
    }
    .director-img-wrapper {
        height: auto;
        margin-bottom: -80px;
    }
    .work-culture-heading {
        -webkit-text-stroke: 0px black;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
    }

    .values-heading {
        -webkit-text-stroke: 0px #000;
        color: #fff;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: -10px;
        left: 30px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .gallery {
        display: flex;
        flex-wrap: initial;
        gap: 16px;
        max-width: 1210px;
        flex-direction: column;
        margin-left: -15px;
    }
    .background {
        display: grid;
        place-items: center;
        height: auto;
        padding: 0 0 0 0;
    }
    .default-img-card {
        position: relative;
        left: 0px;
        width: 330px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.6 1;
        background: beige;
        margin-left: 12px;
    }
    .img-card {
        position: relative;
        left: 0px;
        width: 330px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.25 1;
        background: beige;
        margin-left: 12px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 80px;
        left: 20px;
    }
    .large-small-img-wrapper {
        background: #31a6c4;
        overflow-x: hidden;
        padding-bottom: 50px;
    }
    .custom-why-should-you-row{
        display: flex;
        flex-direction: column;
    }
    .custom-why-should-you-left{
        width: 100%;
    }
    .custom-why-should-you-right{
        width: 100%;
    }
    .custom-why-should-you {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .how-we-start-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .onethree-div-txt {
        font-size: 18px;
        line-height: 28px;
        color: white;
        font-weight: 400;
    }
    .third-section-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    .bod-inner-txt-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .list-wos-txt {
        font-size: 18px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .onethree-div-title {
        font-size: 2rem;
        color: white;
    }
    .thrid-section-title {
        font-size: 2rem;
    }
    .bod-inner-txt-title {
        font-size: 2rem;
        color: black;
        font-weight: bold;
    }
    .wc-img-warpper{
        display: flex;
        padding: 10px;
    }
    .img-wrapper {
        width: 175px;
        height: auto !important;
        margin: 11px;
    }
    .img-wrapper img {
        border-radius: 20px;
    }
    .rapid-belief-story-container {
        padding: 50px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 100%;
        padding: 0px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
        padding-top: 50px;
    }
    .rapid-belief-story-row {
        display: flex;
        flex-direction: column;
    }
    .text-wrapper-belief {
        padding: 15px;
    }
    .custom-container-aboutus-first {
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .how-we-start-first-heading {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-bottom: 25px;
    }
    .sucess-img-wrapper {
        padding: 60px;
    }
}

@media (min-width: 375px) and (max-width: 392px) {
    .about-us-img-mobile {
        border-radius: 35px;
        height: auto !important;
        margin-top: -120px;
        padding: 20px;
        width: 100%;
    }
    .about-us-main {
        margin-top: 60px;
        overflow-x: hidden;
    }
    .about-first-section {
        top: 20px;
        left: 20px;
        position: relative;
        display: flex;
        width: 320px;
    }
    .about-us-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: black;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-row-aboutus-first {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .about-us-first-left{
        width: 100%;
    }
    .about-us-first-right{
        width: 100%;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -200px;
    }
    .how-we-start {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .about-second-container {
        margin-bottom: 100px;
        margin-top: -50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .onethree-div {
        text-align: center;
        padding: 30px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 330px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 30px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 330px;
    }
    .about-third-section-wrapper {
        display: flex;
        margin-top: 20px;
    }
    .custom-container-third-aboutus {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px !important;
    }
    .custom-row-third-aboutus-left {
        width: 100%;
        margin-top: -60px;
    }
    .work-culture-section {
        background-color: #31a6c4;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .custom-row-third-aboutus-right{
        width: 100%;
    }
    .board-of-director-row {
        display: flex;
        flex-direction: column;
        margin: 0px;
        margin-top: -60px;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 40px;
        height: 520px;
        width: 92%;
        margin-bottom: 30px;
        margin-left: 10px;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 100%;
    }
    .director-img-wrapper {
        height: auto;
        margin-bottom: -80px;
    }
    .work-culture-heading {
        -webkit-text-stroke: 0px black;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
    }

    .values-heading {
        -webkit-text-stroke: 0px #000;
        color: #fff;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: -10px;
        left: 30px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .gallery {
        display: flex;
        flex-wrap: initial;
        gap: 16px;
        max-width: 1210px;
        flex-direction: column;
        margin-left: -15px;
    }
    .background {
        display: grid;
        place-items: center;
        height: auto;
        padding: 0 0 0 0;
    }
    .default-img-card {
        position: relative;
        left: 0px;
        width: 330px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.6 1;
        background: beige;
        margin-left: 12px;
    }
    .img-card {
        position: relative;
        left: 0px;
        width: 330px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.25 1;
        background: beige;
        margin-left: 12px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 80px;
        left: 20px;
    }
    .large-small-img-wrapper {
        background: #31a6c4;
        overflow-x: hidden;
        padding-bottom: 50px;
    }
    .custom-why-should-you-row{
        display: flex;
        flex-direction: column;
    }
    .custom-why-should-you-left{
        width: 100%;
    }
    .custom-why-should-you-right{
        width: 100%;
    }
    .custom-why-should-you {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .how-we-start-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .onethree-div-txt {
        font-size: 18px;
        line-height: 28px;
        color: white;
        font-weight: 400;
    }
    .third-section-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    .bod-inner-txt-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .list-wos-txt {
        font-size: 18px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .onethree-div-title {
        font-size: 2rem;
        color: white;
    }
    .thrid-section-title {
        font-size: 2rem;
    }
    .bod-inner-txt-title {
        font-size: 2rem;
        color: black;
        font-weight: bold;
    }
    .wc-img-warpper{
        display: flex;
        padding: 10px;
    }
    .img-wrapper {
        width: 175px;
        height: auto !important;
        margin: 11px;
    }
    .img-wrapper img {
        border-radius: 20px;
    }
    .rapid-belief-story-container {
        padding: 50px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 100%;
        padding: 0px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
        padding-top: 50px;
    }
    .rapid-belief-story-row {
        display: flex;
        flex-direction: column;
    }
    .text-wrapper-belief {
        padding: 15px;
    }
    .custom-container-aboutus-first {
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .how-we-start-first-heading {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-bottom: 25px;
    }
    .sucess-img-wrapper {
        padding: 50px;
    }
}
@media (min-width: 360px) and (max-width: 374px) {
    .about-us-img-mobile {
        border-radius: 35px;
        height: auto !important;
        margin-top: -120px;
        padding: 20px;
        width: 100%;
    }
    .about-us-main {
        margin-top: 60px;
        overflow-x: hidden;
    }
    .about-first-section {
        top: 20px;
        left: 20px;
        position: relative;
        display: flex;
        width: 310px;
    }
    .about-us-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: black;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-row-aboutus-first {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .about-us-first-left{
        width: 100%;
    }
    .about-us-first-right{
        width: 100%;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -200px;
    }
    .how-we-start {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .about-second-container {
        margin-bottom: 100px;
        margin-top: -50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .onethree-div {
        text-align: center;
        padding: 20px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 320px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 20px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 320px;
    }
    .about-third-section-wrapper {
        display: flex;
        margin-top: 20px;
        margin-left: -16px;
    }
    .custom-container-third-aboutus {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px !important;
    }
    .custom-row-third-aboutus-left {
        width: 100%;
        margin-top: -60px;
    }
    .work-culture-section {
        background-color: #31a6c4;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .custom-row-third-aboutus-right{
        width: 100%;
    }
    .board-of-director-row {
        display: flex;
        flex-direction: column;
        margin: 0px;
        margin-top: -60px;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 40px;
        height: 520px;
        width: 92%;
        margin-bottom: 30px;
        margin-left: 10px;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 100%;
    }
    .director-img-wrapper {
        height: auto;
        margin-bottom: -80px;
    }
    .work-culture-heading {
        -webkit-text-stroke: 0px black;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
    }

    .values-heading {
        -webkit-text-stroke: 0px #000;
        color: #fff;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: -10px;
        left: 30px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .gallery {
        display: flex;
        flex-wrap: initial;
        gap: 16px;
        max-width: 1210px;
        flex-direction: column;
        margin-left: -21px;
    }
    .background {
        display: grid;
        place-items: center;
        height: auto;
        padding: 0 0 0 0;
    }
    .default-img-card {
        position: relative;
        left: 0px;
        width: 310px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.6 1;
        background: beige;
        margin-left: 20px;
    }
    .img-card {
        position: relative;
        left: 0px;
        width: 310px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.25 1;
        background: beige;
        margin-left: 21px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 80px;
        left: 20px;
    }
    .large-small-img-wrapper {
        background: #31a6c4;
        overflow-x: hidden;
        padding-bottom: 50px;
    }
    .custom-why-should-you-row{
        display: flex;
        flex-direction: column;
    }
    .custom-why-should-you-left{
        width: 100%;
    }
    .custom-why-should-you-right{
        width: 100%;
    }
    .custom-why-should-you {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 110px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .how-we-start-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .onethree-div-txt {
        font-size: 18px;
        line-height: 28px;
        color: white;
        font-weight: 400;
    }
    .third-section-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    .bod-inner-txt-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .list-wos-txt {
        font-size: 18px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .onethree-div-title {
        font-size: 2rem;
        color: white;
    }
    .thrid-section-title {
        font-size: 2rem;
    }
    .bod-inner-txt-title {
        font-size: 2rem;
        color: black;
        font-weight: bold;
    }
    .wc-img-warpper{
        display: flex;
        padding: 10px;
    }
    .img-wrapper {
        width: 175px;
        height: auto !important;
        margin: 11px;
    }
    .img-wrapper img {
        border-radius: 20px;
    }
    .rapid-belief-story-container {
        padding: 50px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 100%;
        padding: 0px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
        padding-top: 50px;
    }
    .rapid-belief-story-row {
        display: flex;
        flex-direction: column;
    }
    .text-wrapper-belief {
        padding: 15px;
    }
    .custom-container-aboutus-first {
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .how-we-start-first-heading {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-bottom: 25px;
    }
    .sucess-img-wrapper {
        padding: 50px;
    }
}
@media (min-width: 300px) and (max-width: 359px) {
    .about-us-img-mobile{
        border-radius: 35px;
        height: auto !important;
        margin-top: -120px;
        padding: 20px;
        width: 100%;
    }
    .about-us-main {
        margin-top: 60px;
        overflow-x: hidden;
    }
    .about-first-section {
        top: 20px;
        left: 20px;
        position: relative;
        display: flex;
        width: 310px;
    }
    .about-us-rapid-heading {
        -webkit-text-stroke: 0px #000;
        color: black;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .custom-row-aboutus-first {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }
    .about-us-first-left{
        width: 100%;
    }
    .about-us-first-right{
        width: 100%;
    }
    .custom-container-aboutus-first {
        margin-bottom: 50px;
        margin-top: -200px;
    }
    .how-we-start {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
    }
    .about-second-container {
        margin-bottom: 100px;
        margin-top: -50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .onethree-div {
        text-align: center;
        padding: 20px;
        background-color: #2ba4c3;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 280px;
    }
    .about-third-section-threediv {
        text-align: center;
        padding: 20px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        margin: 15px;
        width: 280px;
    }
    .about-third-section-wrapper {
        display: flex;
        margin-top: 20px;
        margin-left: -16px;
    }
    .custom-container-third-aboutus {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px !important;
    }
    .custom-row-third-aboutus-left {
        width: 100%;
        margin-top: -60px;
    }
    .work-culture-section {
        background-color: #31a6c4;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .custom-row-third-aboutus-right{
        width: 100%;
    }
    .board-of-director-row {
        display: flex;
        flex-direction: column;
        margin: 0px;
        margin-top: -60px;
    }
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 40px;
        height: 520px;
        width: 92%;
        margin-bottom: 30px;
        margin-left: 10px;
    }
    .bod-img-1 {
        border-radius: 30px;
        width: 100%;
    }
    .director-img-wrapper {
        height: auto;
        margin-bottom: -80px;
    }
    .work-culture-heading {
        -webkit-text-stroke: 0px black;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
    }

    .values-heading {
        -webkit-text-stroke: 0px #000;
        color: #fff;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        display: flex;
        top: -10px;
        left: 30px;
        width: -moz-fit-content;
        width: fit-content;
    }
    .gallery {
        display: flex;
        flex-wrap: initial;
        gap: 16px;
        max-width: 1210px;
        flex-direction: column;
        margin-left: -21px;
    }
    .background {
        display: grid;
        place-items: center;
        height: auto;
        padding: 0 0 0 0;
    }
    .default-img-card {
        position: relative;
        left: 0px;
        width: 280px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.6 1;
        background: beige;
        margin-left: 20px;
    }
    .img-card {
        position: relative;
        left: 0px;
        width: 280px;
        border-radius: 1em;
        height: 315px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        flex: 0.25 1;
        background: beige;
        margin-left: 21px;
    }
    .default-img-card-heading {
        position: absolute;
        font-size: 24px;
        color: white;
        font-weight: 700;
        bottom: 80px;
        left: 20px;
    }
    .large-small-img-wrapper {
        background: #31a6c4;
        overflow-x: hidden;
        padding-bottom: 50px;
    }
    .custom-why-should-you-row{
        display: flex;
        flex-direction: column;
    }
    .custom-why-should-you-left{
        width: 100%;
    }
    .custom-why-should-you-right{
        width: 100%;
    }
    .custom-why-should-you {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .director-detail-wrapper {
        background-color: rgba(161, 163, 167, 0.29);
        border-color: rgba(255, 255, 255, 0.12);
        padding: 8px 0px 8px 20px;
        position: relative;
        bottom: 110px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 20px;
        -webkit-backdrop-filter: blur(2rem);
        backdrop-filter: blur(2rem);
        border: 0.1px solid #acadb1;
    }
    .how-we-start-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .onethree-div-txt {
        font-size: 18px;
        line-height: 28px;
        color: white;
        font-weight: 400;
    }
    .third-section-txt {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
    }
    .bod-inner-txt-p {
        color: #3d3d3d;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }
    .list-wos-txt {
        font-size: 18px;
        line-height: 28px;
        color: rgb(61, 61, 61);
        font-weight: 400;
    }
    .onethree-div-title {
        font-size: 2rem;
        color: white;
    }
    .thrid-section-title {
        font-size: 2rem;
    }
    .bod-inner-txt-title {
        font-size: 2rem;
        color: black;
        font-weight: bold;
    }
    .wc-img-warpper{
        display: flex;
        padding: 10px;
    }
    .img-wrapper {
        width: 175px;
        height: auto !important;
        margin: 11px;
    }
    .img-wrapper img {
        border-radius: 20px;
    }
    .rapid-belief-story-container {
        padding: 50px 20px;
        max-width: 1010px;
    }
    .rapid-belief-story-left {
        background-color: white;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
        width: 100%;
        padding: 0px;
    }
    .rapid-belief-story-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .rapid-story-img-wrapper {
        width: 400px;
        display: flex;
        padding-top: 50px;
    }
    .rapid-belief-story-row {
        display: flex;
        flex-direction: column;
    }
    .text-wrapper-belief {
        padding: 15px;
    }
    .custom-container-aboutus-first {
        margin-bottom: 30px;
        margin-top: 0px;
    }
    .how-we-start-first-heading {
        -webkit-text-stroke: 0px #fff;
        color: #000;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1;
        text-shadow: 1px 1px #b8bfd6;
        position: relative;
        margin-bottom: 25px;
    }
    .sucess-img-wrapper {
        padding: 50px;
    }
}

@media (resolution: 120dpi) {
    .bod-txt-1 {
        background-color: #fbfaf9;
        border-radius: 30px;
        border: 1px solid #ebeae9;
        padding: 40px;
        height: 538px;
        margin-left: 5px;
        margin-right: 12px;
        width: 23%;
    }
}