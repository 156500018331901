.hotspot-swiper-container-big {
    height: 100vh;
    background-color: #dde1ed8f;
    max-width: 1920px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.beer-container-big {
    display: none;
    max-width: 1550px;
}

.beer-container-big.active {
    display: block;
}

.heading-txt-wrapper-big {
    display: flex;
}

.upper-swiper-wrapper-big {
    display: flex;
    margin-left: 521px;
    cursor: pointer;
}

.back-to-map-img-big {
    height: 15px !important;
    width: auto;
    margin-top: 27px;
    margin-right: 10px;
}

.return-txt-p-big {
    font-size: 21px;
    line-height: 28px;
    color: #31a6c4;
    font-weight: 400;
    padding-top: 20px;
}

.hotspot-heading-big {
    font-size: 48px;
    line-height: 57px;
    font-weight: bold;
    color: #31a6c4;
    -webkit-text-stroke: 0.7px black;
}

.shrink-packing-img-big {
    width: 600px;
    aspect-ratio: auto 600 / 361;
    height: 361px !important;
    margin-top: 40px;
}

.right-swipe-txt-big {
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
}

.img-txt-row-big {
    padding-top: 80px;
}

.right-div-big {
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.swipe-to-left-img-big {
    width: 20px;
    height: 20px !important;
}

.swipe-to-right-img-big {
    width: 20px;
    height: 20px !important;
}

.swipe-to-left-img-div-big {
    border: 0.5px solid black;
    padding: 20px;
    margin-right: 1px;
}

.swipe-to-right-img-div-big {
    border: 0.5px solid black;
    padding: 20px;
}

.swiper-control-arrow-big {
    display: flex;
    margin-left: 192px;
    margin-top: 100px;
}

.swiper-counter-big {
    display: flex;
    justify-content: center;
    padding-top: 90px;
}

.swiper-counter-big p {
    padding: 0px 35px;
    cursor: pointer;
    color: #31a6c4;
}

.swiper-counter-big p.active {
    font-weight: bold;
}

.hotspot-swiper-th-big {
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 45px 5px 45px;
}

.hotspot-swiper-th-width-big {
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 30px 5px 30px;
}

.hotspot-swiper-td-big {
    font-size: 18px;
    background-color: white;
    padding: 10px 35px 10px 35px;
    border-radius: 10px;
}

.tr-body-big:hover .hotspot-swiper-td-big {
    background-color: #31a6c4;
    color: white;
}

.list-point-of-interest-big {
    display: flex;
}

.point-of-your-interest-big {
    margin-bottom: 50px;
}

.list-poi-txt-big {
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
}

.bold-content-big {
    color: #31a6c4;
}

.p-o-i-h3-big {
    margin-top: 40px;
    margin-bottom: 20px;
    color: #31a6c4;
}

.hotspot-heading-right-big {
    font-size: 48px;
    line-height: 57px;
    font-weight: bold;
    color: #31a6c4;
    -webkit-text-stroke: 0.7px black;
    margin-bottom: 30px;
}

.animated-delay{
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}
.animated {
    animation-duration: 1.3s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1.3s;
    -webkit-animation-fill-mode: both
}
.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
.animatedFadeInUp {
    opacity: 0
}
@keyframes fadeInUp {
    from {
        transform: translate3d(0,100px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,100px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}
.hotspot-img-in-out-big {
    animation: zoom-out 1.3s ease-out forwards;
   
  }
  
  @keyframes zoom-out {
    from {
         transform: scale(1.1);
         opacity: 0;
    }
    to {
         transform: scale(1);
         width: 80%;
         opacity: 1;
    }
}
/* ------------  table unpacking --------- */ 
.big-table-main-1{
    margin: 40px 0;
}
.tr-head-big-1{
    display: flex;
}
.tr-head-div-1{
    margin-bottom: 2px;
    margin-right: 4px;
}
.th-model-big-1{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 23px 79px 23px 79px;
}
.th-model-big-2{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 47px;
}
.tr-body-div-big-1{
    padding: 2px;
}
.RAPIDunpack-td-1{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 16px;
    width: 220px;
}
.RAPIDunpack-td-2{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 24px 90px 24px 90px ;
}
.special-size-ml{
    font-size: 20px;
    display: contents;
}
.tr-head-big-1:hover .RAPIDunpack-td-1{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-1:hover .RAPIDunpack-td-1-1{
    background-color: #31a6c4;
    color: white;
}
.RAPIDunpack-td-1-1{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 24px 108px 24px 108px;
}
/* ------------  table 2 pre-washing --------- */ 
.big-table-main-2{
    margin: 40px 0;
}
.tr-head-big-2{
    display: flex;
}
.tr-head-div-2{
    margin-bottom: 2px;
    margin-right: 4px;
}
.th-model-big-2{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 64px 5px 64px;
}
.th-pitch-big-2{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 20px 5px 20px;
}
.th-rc-big-2{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 10px 5px 10px;
}
.tr-body-div-big-2{
    padding: 2px;
}
.big-RAPIDwash-2{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
}
.big-mm-2{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 20px 10px 20px;
}
.big-bph-2{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 85px 10px 85px;
}
.special-p{
    padding: 10px 87px 10px 90px
}
.tr-head-big-2:hover .big-RAPIDwash-2{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-2:hover .big-mm-2{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-2:hover .big-bph-2{
    background-color: #31a6c4;
    color: white;
}
/* ------------  table 5 fillig --------- */ 

.big-table-main-5{
    margin: 40px 0;
}
.tr-head-big-5{
    display: flex;
}
.tr-head-div-big-5{
    margin-bottom: 2px;
    margin-right: 4px;
}
.tr-body-div-big-5{
    padding: 2px;
}
.th-model-fs-big-5{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 30px 64px 30px 64px;
}
.th-fv-big-5{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 30px 30px 30px 30px;
}
.th-rs-big-5{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 34px 5px 34px;
}
.td-RAPIDfill-big-5{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 35px 10px 35px;
}
.td-twocl-big-5{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 85px 10px 85px;
}
.td-thirdcl-big-5{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 85px 10px 85px;
}
.special-padding-flling{
    padding: 10px 90px 10px 90px;
}
.tr-head-big-5:hover .td-RAPIDfill-big-5{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-5:hover .td-twocl-big-5{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-5:hover .td-thirdcl-big-5{
    background-color: #31a6c4;
    color: white;
}
/* ------------  table 5 fillig 2 table --------- */ 

.big-table-main-5-2 {
    margin: 40px 0;
}
.tr-head-big-5-2{
    display: flex;
}
.tr-head-div-big-5-2{
    margin-bottom: 2px;
    margin-right: 4px;
}
.tr-body-div-big-5-2{
    padding: 2px;
}
.th-model-fs-big-5-2{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 64px 5px 64px;
}
.th-fv-big-5-2{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 20px 5px 20px;
}
.th-rs-big-5-2{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 20px 5px 20px;
}
.td-RAPIDfill-big-5-2{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 35px 10px 35px;
}
.td-twocl-big-5-2{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 76px 10px 76px;
}
.td-thirdcl-big-5-2{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 58px 10px 58px;
}
.tr-head-big-5-2:hover .td-RAPIDfill-big-5-2{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-5-2:hover .td-twocl-big-5-2{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-5-2:hover .td-thirdcl-big-5-2{
    background-color: #31a6c4;
    color: white;
}


/* ------------  table Pasteurising --------- */ 
.big-table-main-7 {
    margin: 40px 0;
}
.tr-head-big-7{
    display: flex;
}
.tr-head-div-big-7{
    margin-bottom: 2px;
    margin-right: 4px;
}
.tr-body-div-big-7{
    padding: 2px;
}
.th-model-fs-big-7{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 20px 5px 20px;
}
.th-width-big-7{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 70px 5px 70px;
}

.td-RAPIDpas-big-7{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 53px 10px 53px;
}
.td-mtr-big-7{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 76px 10px 76px;
}

.tr-head-big-7:hover .td-RAPIDpas-big-7{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-7:hover .td-mtr-big-7{
    background-color: #31a6c4;
    color: white;
}

/* ------------  table Case Packing --------- */ 
.big-table-main-9 {
    margin: 40px 0;
}
.tr-head-big-9{
    display: flex;
}
.tr-head-div-big-9{
    margin-bottom: 2px;
    margin-right: 4px;
}
.tr-body-div-big-9{
    padding: 2px;
}
.th-model-fs-big-9{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 30px 121px 30px 120px;
}
.th-fv-big-9{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 20px 5px 20px;
}
.th-rs-big-9{
    background-color: #31a6c4;
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    padding: 5px 20px 5px 20px;
}
.td-RAPIDfill-big-9{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 10px 35px 10px 35px;
    width: 300px;
}
.td-twocl-big-9{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 23px 76px 23px 76px;
}
.td-thirdcl-big-9{
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 23px 58px 23px 58px;
}
.tr-head-big-9:hover .td-RAPIDfill-big-9{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-9:hover .td-twocl-big-9{
    background-color: #31a6c4;
    color: white;
}
.tr-head-big-9:hover .td-thirdcl-big-9{
    background-color: #31a6c4;
    color: white;
}

@media (min-width: 1400px) and (max-width: 1440px) {
    .swiper-counter-big {
        display: flex;
        justify-content: center;
        padding-top: 140px;
    }
    .img-txt-row-big {
        padding-top: 20px;
    }
    .hotspot-swiper-container-big {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .shrink-packing-img-big {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper-big {
        display: flex;
        margin-left: 398px;
        cursor: pointer;
    }
    .hotspot-heading-big {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
    }
    .swiper-counter-big p {
        padding: 0px 15px;
        cursor: pointer;
        color: #31a6c4;
    }
}

@media (min-width: 1300px) and (max-width: 1399px) {
    .swiper-counter-big {
        display: flex;
        justify-content: center;
        padding-top: 90px;
    }
    .img-txt-row-big {
        padding-top: 20px;
    }
    .hotspot-swiper-container-big {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 50px;
        padding-bottom: 100px;
    }
    .shrink-packing-img-big {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper-big {
        display: flex;
        margin-left: 350px;
        cursor: pointer;
    }
    .hotspot-heading-big {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
    }
    .swiper-counter-big p {
        padding: 0px 10px;
        cursor: pointer;
        color: #31a6c4;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 25px 10px 25px;
        width: 290px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 115px 30px 115px;
    }
}
@media (min-width: 1280px) and (max-width: 1299px) {
    .swiper-counter-big {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
    .img-txt-row-big {
        padding-top: 0px;
    }
    .hotspot-swiper-container-big {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 10px;
        padding-bottom: 0px;
    }
    .shrink-packing-img-big {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper-big {
        display: flex;
        margin-left: 310px;
        cursor: pointer;
    }
    .hotspot-heading-big {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
        margin-top: 20px;
    }
    .swiper-counter-big p {
        padding: 0px 9px;
        cursor: pointer;
        color: #31a6c4;
        font-size: 13px;
    }
    .th-model-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 11px 61px 11px 61px;
    }
    .th-pitch-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 10px 10px 10px 10px;
    }
    .th-rc-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 10px 10px 10px 10px;
    }
    .big-RAPIDwash-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }
    .big-mm-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 75px 10px 75px;
    }
    td.big-bph-2.special-p {
        padding: 10px 79px 10px 79px;
    }
    .th-model-fs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 40px 30px 40px;
    }
    .th-rs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 5px 10px 5px 10px;
    }
    .td-thirdcl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 60px 10px 60px;
    }
    .td-twocl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 65px 10px 65px;
    }
    .th-fv-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 10px 30px 10px;
    }
    .td-RAPIDfill-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 10px 10px 10px;
    }
    .special-padding-flling {
        padding: 10px 66px 10px 66px;
    }
    .td-twocl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 62px 10px 62px;
    }
    .td-thirdcl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 40px 10px 40px;
    }
    .th-model-fs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 23px 64px 23px 64px;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
        width: 230px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 27px 88px 27px 88px;
    }
    .td-twocl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 23px 60px 23px 60px;
    }
    .th-rs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 5px 10px 5px 10px;
    }
    .td-thirdcl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 23px 38px 23px 38px;
    }
    .th-fv-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 5px 13px 5px 13px;
    }
   @keyframes zoom-out {
        from {
             transform: scale(0.7);
             opacity: 0;
        }
        to {
             transform: scale(1);
             width: 80%;
             opacity: 1;
        }
    }
}
@media (min-width: 1080px) and (max-width: 1279px) {
    .swiper-counter-big {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
    .img-txt-row-big {
        padding-top: 0px;
    }
    .hotspot-swiper-container-big {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 90px;
        padding-bottom: 0px;
    }
    .shrink-packing-img-big {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper-big {
        display: flex;
        margin-left: 240px;
        cursor: pointer;
    }
    .hotspot-heading-big {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
        margin-top: 20px;
    }
    .swiper-counter-big p {
        padding: 0px 9px;
        cursor: pointer;
        color: #31a6c4;
        font-size: 13px;
    }
    .th-model-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 11px 61px 11px 61px;
    }
    .th-pitch-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 10px 10px 10px 10px;
    }
    .th-rc-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 10px 10px 10px 10px;
    }
    .big-RAPIDwash-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }
    .big-mm-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 75px 10px 75px;
    }
    td.big-bph-2.special-p {
        padding: 10px 79px 10px 79px;
    }
    .th-model-fs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 40px 30px 40px;
    }
    .th-rs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 5px 10px 5px 10px;
    }
    .td-thirdcl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 60px 10px 60px;
    }
    .td-twocl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 65px 10px 65px;
    }
    .th-fv-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 10px 30px 10px;
    }
    .td-RAPIDfill-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 10px 10px 10px;
    }
    .special-padding-flling {
        padding: 10px 66px 10px 66px;
    }
    .td-twocl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 62px 10px 62px;
    }
    .td-thirdcl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 40px 10px 40px;
    }
    .th-model-fs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 23px 64px 23px 64px;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
        width: 230px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 27px 88px 27px 88px;
    }
    .td-twocl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 23px 60px 23px 60px;
    }
    .th-rs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 5px 10px 5px 10px;
    }
    .td-thirdcl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 23px 38px 23px 38px;
    }
    .th-fv-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 5px 13px 5px 13px;
    }
   @keyframes zoom-out {
        from {
             transform: scale(0.7);
             opacity: 0;
        }
        to {
             transform: scale(1);
             width: 80%;
             opacity: 1;
        }
    }
}
@media (min-width: 1024px) and (max-width: 1079px) {
    .swiper-counter-big {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
    .img-txt-row-big {
        padding-top: 0px;
    }
    .hotspot-swiper-container-big {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 10px;
        padding-bottom: 0px;
    }
    .shrink-packing-img-big {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper-big {
        display: flex;
        margin-left: 240px;
        cursor: pointer;
    }
    .hotspot-heading-big {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
        margin-top: 20px;
    }
    .swiper-counter-big p {
        padding: 0px 9px;
        cursor: pointer;
        color: #31a6c4;
        font-size: 13px;
    }
    .th-model-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 11px 61px 11px 61px;
    }
    .th-pitch-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 10px 10px 10px 10px;
    }
    .th-rc-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 10px 10px 10px 10px;
    }
    .big-RAPIDwash-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }
    .big-mm-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 75px 10px 75px;
    }
    td.big-bph-2.special-p {
        padding: 10px 79px 10px 79px;
    }
    .th-model-fs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 40px 30px 40px;
    }
    .th-rs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 5px 10px 5px 10px;
    }
    .td-thirdcl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 60px 10px 60px;
    }
    .td-twocl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 65px 10px 65px;
    }
    .th-fv-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 10px 30px 10px;
    }
    .td-RAPIDfill-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 10px 10px 10px;
    }
    .special-padding-flling {
        padding: 10px 66px 10px 66px;
    }
    .td-twocl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 62px 10px 62px;
    }
    .td-thirdcl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 40px 10px 40px;
    }
    .th-model-fs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 23px 64px 23px 64px;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px 10px 10px 10px;
        width: 230px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 27px 88px 27px 88px;
    }
    .td-twocl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 23px 60px 23px 60px;
    }
    .th-rs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 5px 10px 5px 10px;
    }
    .td-thirdcl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 16px;
        padding: 23px 38px 23px 38px;
    }
    .th-fv-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        padding: 5px 13px 5px 13px;
    }
   @keyframes zoom-out {
        from {
             transform: scale(0.7);
             opacity: 0;
        }
        to {
             transform: scale(1);
             width: 80%;
             opacity: 1;
        }
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    @keyframes zoom-out {
        from {
             transform: scale(0.2);
             opacity: 0;
        }
        to {
             transform: scale(1);
             width: 80%;
             opacity: 1;
        }
    }
   
    .upper-swiper-wrapper-big {
        display: flex;
        margin-left: 135px;
        cursor: pointer;
    }
    .shrink-packing-img-big {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 70px;
        margin-left: 70px;
    }
    .img-txt-row-big {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
    }
    .left-side-img-bic-big {
        width: 100%;
        margin-top: -70px;
    }
    .right-div-big {
        max-height: 350px;
        overflow-y: scroll;
        scrollbar-width: thin;
        width: 100%;
        padding-left: 80px;
        padding-right: 80px;
        margin-top: 30px;
    }
    .beer-container-big.active {
        display: grid;
        align-items: center;
        padding-top: 145px;
    }

    .hotspot-swiper-container-big {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow-y: hidden !important;
    }
    .hotspot-heading-big {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 70px;
    }
    .heading-txt-wrapper-big {
        display: flex;
        position: relative;
        top: -30px;
    }
    .swiper-counter-big {
        display: flex;
        justify-content: center;
        padding-top: 30px;
    }
    .swiper-counter-big p {
        padding: 0px 6px;
        cursor: pointer;
        color: #31a6c4;
        font-size: 10px;
    }
    .th-fv-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 23px 30px 23px;
    }
    .td-twocl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 80px 10px 80px;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 18px;
        padding: 10px 15px 10px 15px;
        width: 270px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 24px;
        padding: 30px 105px 30px 105px;
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .th-model-big-1 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 16px 89px 16px 89px;
    }
    .th-model-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 36px 5px 36px;
    }
    .special-size-ml {
        font-size: 14px;
        display: contents;
    }
    .RAPIDunpack-td-1 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 16px;
        width: 220px;
    }
    .RAPIDunpack-td-1-1 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 68px 20px 68px;
    }
    .th-pitch-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px 5px 20px;
    }
    .th-rc-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
    .big-RAPIDwash-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .big-mm-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 20px 10px 20px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 85px 10px 85px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 69px 10px 69px;
    }
    td.big-bph-2.special-p {
        padding: 10px 73px 10px 73px;
    }
    .th-model-fs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 25px 38px 25px 38px;
    }
    .th-fv-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 25px 10px 26px 10px;
    }
    .th-rs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 14px 5px 14px;
    }
    .td-RAPIDfill-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .td-twocl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 45px 10px 45px;
    }
    .td-thirdcl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 45px 10px 45px;
    }
    .th-model-fs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 17px 64px 17px 64px;
    }
    .th-fv-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px 5px 20px;
    }
    .th-rs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px 5px 20px;
    }
    .td-RAPIDfill-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 35px 10px 35px;
    }
    .td-twocl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 46px 10px 46px;
    }
    .td-thirdcl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 28px 10px 28px;
    }
    .th-model-fs-big-7 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px 5px 20px;
    }
    .th-width-big-7 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 70px 5px 70px;
    }
    .td-RAPIDpas-big-7 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 37px 10px 36px;
    }
    .td-mtr-big-7 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 66px 10px 76px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 37px 81px 37px 81px;
    }
    .th-fv-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 13px 5px 13px;
    }
    .th-rs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 17px 10px 17px 10px;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 15px 10px 15px;
        width: 200px;
    }
    .td-twocl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 23px 36px 23px 36px;
    }
    .td-thirdcl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 23px 28px 23px 28px;
    }
}
@media (min-width: 375px) and (max-width: 424px) {
    .th-model-big-1 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 16px 62px 16px 62px;
    }
    .th-model-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 15px 5px 15px;
    }
    .special-size-ml {
        font-size: 14px;
        display: contents;
    }
    .RAPIDunpack-td-1 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px;
        width: 160px;
    }
    .RAPIDunpack-td-1-1 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 46px 20px 46px;
    }
    .th-pitch-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 29px 10px 29px 10px;
    }
    .th-rc-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 15px 5px 15px;
        width: 70px;
    }
    .big-RAPIDwash-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .big-mm-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 20px 10px 20px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 85px 10px 85px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    td.big-bph-2.special-p {
        padding: 10px 10px 10px 17px;
    }
    .th-model-fs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 25px 38px 25px 38px;
    }
    .th-fv-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 12px 10px 13px 10px;
        width: 70px;
    }
    .th-rs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 12px 5px 11px;
    }
    .td-RAPIDfill-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .td-twocl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 25px 10px 25px;
    }
    .td-thirdcl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 45px 10px 45px;
    }
    .th-model-fs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 35px 5px 32px;
    }
    .th-fv-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
    .th-rs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
    .td-RAPIDfill-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .td-twocl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 46px 10px 46px;
    }
    .td-thirdcl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 25px 10px 25px;
    }
    .th-model-fs-big-7 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px 5px 20px;
    }
    .th-width-big-7 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 40px 5px 40px;
    }
    .td-RAPIDpas-big-7 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 37px 10px 36px;
    }
    .td-mtr-big-7 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 36px 10px 36px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 37px 68px 37px 68px;
    }
    .th-fv-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 5px 5px 5px;
        width: 70px;
    }
    .th-rs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 17px 10px 17px 10px;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 5px 10px 5px;
        width: 180px;
    }
    .td-twocl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 25px 20px 25px;
    }
    .td-thirdcl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 28px 20px 28px;
    }
    th.th-model-big-2.th-model-mobile {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 29px 57px 29px 57px;
    }
    th.th-pitch-big-2.th-mobile-pitch {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 29px 22px 29px 23px;
    }
    td.td-thirdcl-big-5.special-padding-flling {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 49px 10px 49px;
    }
    .mobile-big-content-wrapper {
        padding: 20px 15px 20px 15px;
        height: 50vh;
        overflow-y: scroll;
    }
}
@media (min-width: 300px) and (max-width: 374px) {
    .th-model-big-1 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 16px 62px 16px 62px;
    }
    .th-model-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 3px 5px 3px;
    }
    .special-size-ml {
        font-size: 14px;
        display: contents;
    }
    .RAPIDunpack-td-1 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px;
        width: 160px;
    }
    .RAPIDunpack-td-1-1 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 38px 20px 38px;
    }
    .th-pitch-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 29px 10px 29px 10px;
    }
    .th-rc-big-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px 5px 10px;
        width: 70px;
    }
    .big-RAPIDwash-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .big-mm-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 20px 10px 20px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 85px 10px 85px;
    }
    .big-bph-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    td.big-bph-2.special-p {
        padding: 10px 10px 10px 17px;
    }
    .th-model-fs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 25px 18px 25px 18px;
    }
    .th-fv-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 12px 10px 13px 10px;
        width: 70px;
    }
    .th-rs-big-5 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
    .td-RAPIDfill-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .td-twocl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 25px 20px 25px;
    }
    .td-thirdcl-big-5 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 45px 20px 45px;
    }
    .th-model-fs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 17px 32px 17px 32px;
    }
    .th-fv-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
    .th-rs-big-5-2 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
    .td-RAPIDfill-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 10px 10px 10px;
    }
    .td-twocl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 26px 10px 26px;
    }
    .td-thirdcl-big-5-2 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 15px 10px 15px;
    }
    .th-model-fs-big-7 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px 5px 20px;
    }
    .th-width-big-7 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 40px 5px 40px;
    }
    .td-RAPIDpas-big-7 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 37px 10px 36px;
    }
    .td-mtr-big-7 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 36px 10px 36px;
    }
    .th-model-fs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 37px 30px 37px 30px;
    }
    .th-fv-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 5px 5px 5px 5px;
        width: 70px;
    }
    .th-rs-big-9 {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 17px 10px 17px 10px;
    }
    .td-RAPIDfill-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 5px 10px 5px;
        width: 100px;
    }
    .td-twocl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 23px 25px 23px 25px;
    }
    .td-thirdcl-big-9 {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 23px 28px 23px 28px;
    }
    th.th-model-big-2.th-model-mobile {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 29px 45px 29px 45px;
    }
    th.th-pitch-big-2.th-mobile-pitch {
        background-color: #31a6c4;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 29px 10px 29px 10px;
    }
    td.td-thirdcl-big-5.special-padding-flling {
        background-color: #fff;
        border-radius: 10px;
        font-size: 14px;
        padding: 20px 49px 20px 49px;
    }
    .mobile-big-content-wrapper {
        padding: 20px 15px 20px 15px;
        height: 50vh;
        overflow-y: scroll;
    }
}