.terms-and-conditions {
  margin-top: -130px;
  overflow-x: hidden;
}

.terms-and-conditions-img {
  padding: 20px;
  width: 100%;
}

.terms-first-section {
  top: -330px;
  left: 120px;
  position: relative;
  display: flex;
}

.terms-rapid-heading {
  -webkit-text-stroke: 1px #000;
  color: #fff;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
}

.user-agreement-heading {
  -webkit-text-stroke: 1px white;
  color: black;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  position: relative;
}

.terms-p-txt {
  color: #3d3d3d;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
}

.terms-second-heading {
  font-size: 32px;
  color: black;
  font-weight: bold;
}

.terms-wrapper {
  padding: 40px 30px 40px 30px;
}

.custom-terms-container {
  display: flex;
}

.table-of-content-txt {
  font-size: 32px;
  color: black;
  font-weight: bold;
}

.left-side {
  width: 360px;
  height: 610px;
  background: #f4f8ff;
  display: inline-block;
  float: left;
  position: sticky;
  top: 150px;
}

.main-section {
  width: 1300px;
  margin: 50px auto;
  clear: both;
}

.main-section:before,
.main-section:after {
  content: "";
  display: table;
  clear: both;
}

.right-side {
  width: 65%;
  display: inline-block;
  vertical-align: top;
  float: right;
}

.box {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.box span:nth-child(2) {
  animation-delay: -0.2s;
}

.box span:nth-child(3) {
  animation-delay: -0.4s;
}

.terms-ul li {
  color: #2ba4c3;
  cursor: pointer;
  font-size: 18px;
  list-style-type: none;
  padding: 10px 24px 10px 18px;
}

.terms-ul {
  margin-left: -60px;
}

.list-wrapper {
  background-color: #f4f8ff;
  padding: 10px;
}

.table-wrapper {
  padding: 35px 0px 0px 35px;
}

.active-section {
  background-color: #2ba4c3;
  color: white !important;
}

@media (min-width: 1400px) and (max-width: 1440px) {
  .terms-first-section {
    top: -250px;
    left: 120px;
    position: relative;
    display: flex;
    width: fit-content;
  }

  .main-section {
    width: 1300px;
    margin: 50px auto;
    clear: both;
    margin-top: -30px;
  }
}
@media (min-width: 1280px) and (max-width: 1399px) {
  .terms-first-section {
    top: -250px;
    left: 120px;
    position: relative;
    display: flex;
    width: fit-content;
  }

  .main-section {
    width: 1300px;
    margin: 50px auto;
    clear: both;
    margin-top: -30px;
  }

  .terms-and-conditions {
    margin-top: -120px;
    overflow-x: hidden;
}

.terms-first-section {
  top: -240px;
  left: 120px;
  position: relative;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}

  .main-section {
    width: 100%;
    margin: 50px auto;
    clear: both;
    margin-top: -50px;
  }

  .left-side {
    width: 31%;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    left: 50px;
  }

  .terms-ul li {
    color: #2ba4c3;
    cursor: pointer;
    font-size: 16px;
    list-style-type: none;
    padding: 10px 24px 10px 18px;
  }

  .right-side {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    right: 20px;
  }
}
@media (min-width: 1080px) and (max-width: 1279px) {
  .terms-first-section {
    top: -250px;
    left: 120px;
    position: relative;
    display: flex;
    width: fit-content;
  }

  .main-section {
    width: 1300px;
    margin: 50px auto;
    clear: both;
    margin-top: -30px;
  }

  .terms-and-conditions {
    margin-top: -103px;
    overflow-x: hidden;
  }

  .terms-first-section {
    top: -210px;
    left: 120px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }

  .main-section {
    width: 100%;
    margin: 50px auto;
    clear: both;
    margin-top: -50px;
  }

  .left-side {
    width: 31%;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    left: 50px;
  }

  .terms-ul li {
    color: #2ba4c3;
    cursor: pointer;
    font-size: 16px;
    list-style-type: none;
    padding: 10px 24px 10px 18px;
  }

  .right-side {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    right: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1079px) {
  .terms-first-section {
    top: -250px;
    left: 120px;
    position: relative;
    display: flex;
    width: fit-content;
  }

  .main-section {
    width: 100%;
    margin: 50px auto;
    clear: both;
    margin-top: -30px;
  }

  .terms-and-conditions {
    margin-top: -103px;
    overflow-x: hidden;
  }

  .terms-first-section {
    top: -200px;
    left: 70px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }

  .main-section {
    width: 100%;
    margin: 50px auto;
    clear: both;
    margin-top: -50px;
  }

  .left-side {
    width: 30%;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    left: 50px;
  }

  .terms-ul li {
    color: #2ba4c3;
    cursor: pointer;
    font-size: 16px;
    list-style-type: none;
    padding: 10px 24px 10px 18px;
  }

  .right-side {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    right: 20px;
  }
}

@media (min-width: 810px) and (max-width: 1023px) {
  .terms-first-section {
    top: -250px;
    left: 120px;
    position: relative;
    display: flex;
    width: fit-content;
  }

  .terms-and-conditions {
    margin-top: -96px;
    overflow-x: hidden;
  }

  .terms-first-section {
    top: -160px;
    left: 70px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }

  .main-section {
    width: 100%;
    margin: 50px auto;
    clear: both;
    margin-top: -50px;
  }

  .left-side {
    width: 37%;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    left: 23px;
  }

  .terms-ul li {
    color: #2ba4c3;
    cursor: pointer;
    font-size: 16px;
    list-style-type: none;
    padding: 10px 0px 10px 10px;
  }

  .right-side {
    width: 57%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    right: 20px;
  }

  .terms-rapid-heading {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .user-agreement-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .terms-wrapper {
    padding: 20px 30px 20px 30px;
  }

  .table-wrapper {
    padding: 50px 0px 0px 35px;
  }
}

@media (min-width: 768px) and (max-width: 809px) {
  .terms-first-section {
    top: -250px;
    left: 120px;
    position: relative;
    display: flex;
    width: fit-content;
  }

  .terms-and-conditions {
    margin-top: -96px;
    overflow-x: hidden;
  }

  .terms-first-section {
    top: -160px;
    left: 70px;
    position: relative;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
  }

  .main-section {
    width: 100%;
    margin: 50px auto;
    clear: both;
    margin-top: -50px;
  }

  .left-side {
    width: 37%;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    left: 23px;
  }

  .terms-ul li {
    color: #2ba4c3;
    cursor: pointer;
    font-size: 16px;
    list-style-type: none;
    padding: 10px 0px 10px 10px;
  }

  .right-side {
    width: 57%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    right: 20px;
  }

  .terms-rapid-heading {
    -webkit-text-stroke: 1px #000;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .user-agreement-heading {
    -webkit-text-stroke: 1px white;
    color: black;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .terms-wrapper {
    padding: 20px 10px 20px 10px;
  }

  .table-wrapper {
    padding: 30px 0px 0px 25px;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .subpageherobackground-mobile-terms {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}

  .terms-and-conditions {
    margin-top: 60px;
    overflow-x: hidden;
  }

  .terms-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}
.terms-first-section {
  top: 10px;
  left: 20px;
  position: relative;
  display: flex;
  width: 335px;
}

  .left-side {
    width: 360px;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    display: none;
  }

  .main-section {
    width: 100%;
    margin: 140px 0px 0px 0px;
    clear: both;
    display: flex;
}

  .user-agreement-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .right-side {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    margin-top: -150px;
  }

  .terms-wrapper {
    padding: 10px 20px 10px 20px;
  }

  .terms-p-txt {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .terms-second-heading {
    font-size: 2rem;
    color: black;
    font-weight: bold;
  }
}

@media (min-width: 393px) and (max-width: 424px) {
  .subpageherobackground-mobile-terms {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}
  .terms-and-conditions {
    margin-top: 60px;
    overflow-x: hidden;
  }
  .terms-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}

.terms-first-section {
  top: 10px;
  left: 20px;
  position: relative;
  display: flex;
  width: 335px;
}

  .left-side {
    width: 360px;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    display: none;
  }

  .main-section {
    width: 100%;
    margin: 140px 0px 0px 0px;
    clear: both;
    display: flex;
}

  .user-agreement-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .right-side {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    margin-top: -160px;
}

  .terms-wrapper {
    padding: 10px 20px 10px 20px;
  }

  .terms-p-txt {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .terms-second-heading {
    font-size: 2rem;
    color: black;
    font-weight: bold;
  }
}

@media (min-width: 375px) and (max-width: 392px) {
  .subpageherobackground-mobile-terms {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}

  .terms-and-conditions {
    margin-top: 60px;
    overflow-x: hidden;
  }
  .terms-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}

.terms-first-section {
  top: 10px;
  left: 20px;
  position: relative;
  display: flex;
  width: 335px;
}

  .left-side {
    width: 360px;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    display: none;
  }

  .main-section {
    width: 100%;
    margin: 140px 0px 0px 0px;
    clear: both;
    display: flex;
}

  .user-agreement-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .right-side {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    margin-top: -150px;
}

  .terms-wrapper {
    padding: 10px 20px 10px 20px;
  }

  .terms-p-txt {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .terms-second-heading {
    font-size: 2rem;
    color: black;
    font-weight: bold;
  }
}

@media (min-width: 300px) and (max-width: 374px) {
  .subpageherobackground-mobile-terms {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 0px;
}

  .terms-and-conditions {
    margin-top: 60px;
    overflow-x: hidden;
  }

  .terms-rapid-heading {
    -webkit-text-stroke: 0px #000;
    color: #000;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
}

.terms-first-section {
  top: 10px;
  left: 20px;
  position: relative;
  display: flex;
  width: 325px;
}

  .left-side {
    width: 360px;
    height: 610px;
    background: #f4f8ff;
    display: inline-block;
    float: left;
    position: sticky;
    top: 150px;
    display: none;
  }
  .main-section {
    width: 100%;
    margin: 140px 0px 0px 0px;
    clear: both;
    display: flex;
}

  .user-agreement-heading {
    -webkit-text-stroke: 0px white;
    color: black;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
    position: relative;
  }

  .right-side {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    float: right;
    position: relative;
    margin-top: -160px;
}

  .terms-wrapper {
    padding: 10px 20px 10px 20px;
  }

  .terms-p-txt {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }

  .terms-second-heading {
    font-size: 2rem;
    color: black;
    font-weight: bold;
  }
}