/* for sticky left and scroll right */

.left-side-big {
  width: 250px;
  height: auto;
  display: inline-block;
  float: left;
  position: sticky;
  top: 130px;
}

.main-section-big {
  width: 1100px;
  margin: 50px auto;
  clear: both;
  padding-bottom: 100px;
}

.main-section-big:before,
.main-section-big:after {
  content: "";
  display: table;
  clear: both;
}

.right-side-big {
  width: 700px;
  display: inline-block;
  vertical-align: top;
  float: right;
  height: auto;
}
.bik-img-left-wrapper{
  width: 340px;

}
/* sticky scroll css end */

.big-img-heading {
  font-size: 35px;
  color: black;
  font-weight: bold;
  -webkit-text-stroke: 0px white;
}
.right-txt-bik-p {
  color: #3d3d3d;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}
.custom-container-poi-big {
  padding-top: 10px;
  padding-bottom: 10px;
}
.points-heading-txt-main-kig {
  font-size: 24px;
  color: black;
  font-weight: bold;
  -webkit-text-stroke: 0px white;
}

/* big sticky 2 */

.main-section-big-2 {
  width: 1200px;
  margin: 50px auto;
  clear: both;
}
.left-side-big-2 {
  width: 700px;
  display: inline-block;
  vertical-align: top;
  height: auto;
  margin-left: 50px;
}
.main-section-big-2:before,
.main-section-big-2:after {
  content: "";
  display: table;
  clear: both;
}

.right-side-big-2 {
  width: 250px;
  height: auto;
  display: inline-block;
  position: sticky;
  top: 130px;
  margin-left: 50px;
}

.bik-img-left-wrapper:hover .bik-img-left {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

@media (min-width: 1080px) and (max-width: 1399px) {
  .main-section-big {
    width: 1100px;
    margin: 50px auto;
    clear: both;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -70px;
  }
  .main-section-big-2 {
    width: 1100px;
    margin: 50px auto;
    clear: both;
    padding-left: 20px;
    padding-right: 20px;
  }
  .right-side-big-2 {
    width: 250px;
    height: auto;
    display: inline-block;
    position: sticky;
    top: 100px;
    margin-left: 30px;
}
  .right-side-big {
    width: 670px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-right: 20px;
}
.left-side-big-2 {
  width: 670px;
  display: inline-block;
  vertical-align: top;
  height: auto;
  margin-left: 0px;
}
}

@media (min-width: 1024px) and (max-width: 1079px) {
  .main-section-big {
    width: 1100px;
    margin: 50px auto;
    clear: both;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -70px;
  }
  .main-section-big-2 {
    width: 1100px;
    margin: 50px auto;
    clear: both;
    padding-left: 20px;
    padding-right: 20px;
  }
  .right-side-big-2 {
    width: 250px;
    height: auto;
    display: inline-block;
    position: sticky;
    top: 100px;
    margin-left: -20px;
  }
  .right-side-big {
    width: 700px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
  }
  .right-side-big {
    width: 670px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-right: 90px;
}
  .left-side-big {
    width: 250px;
    height: auto;
    display: inline-block;
    float: left;
    position: sticky;
    top: 100px;
    padding-left: 10px;
  }

  .left-side-big-2 {
    width: 670px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    margin-left: 10px;
}
}
@media (min-width: 810px) and (max-width: 1023px) {
  .main-section-big {
    display: flex;
    flex-direction: column;
  }
  .left-side-big {
    width: 250px;
    height: auto;
    display: inline-block;
    float: left;
    position: relative;
    top: -100px;
    left: 30px;
  }
  .right-side-big {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-section-big-2 {
    width: auto;
    margin: 0px auto;
    clear: both;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }
  .main-section-big {
    width: auto;
    margin: 50px auto;
    clear: both;
    padding-bottom: 0px;
  }
  .right-side-big {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -50px;
  }
  .left-side-big-2 {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    padding-left: 20px;
  }
  .left-side-big-2 {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    padding-left: 20px;
    margin-top: 50px;
    margin-left: 0px;
    padding-right: 20px;
}
.right-side-big-2 {
  width: 250px;
  height: auto;
  display: inline-block;
  /* float: left; */
  position: relative;
  top: -20px;
  margin-left: 30px;
}
}
@media (min-width: 768px) and (max-width: 809px) {
  .main-section-big {
    display: flex;
    flex-direction: column;
  }
  .left-side-big {
    width: 250px;
    height: auto;
    display: inline-block;
    float: left;
    position: relative;
    top: -100px;
    left: 30px;
  }
  .right-side-big {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-section-big-2 {
    width: auto;
    margin: 0px auto;
    clear: both;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }
  .main-section-big {
    width: auto;
    margin: 50px auto;
    clear: both;
    padding-bottom: 0px;
  }
  .right-side-big {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -50px;
  }
  .left-side-big-2 {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    padding-left: 20px;
  }
  .left-side-big-2 {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    padding-left: 20px;
    margin-top: 50px;
    margin-left: 0px;
    padding-right: 20px;
}
  .right-side-big-2 {
    width: 250px;
    height: auto;
    display: inline-block;
    /* float: left; */
    position: relative;
    top: -20px;
    margin-left: 30px;
}
}
@media (min-width: 425px) and (max-width: 767px) {
  .main-section-big {
    display: flex;
    flex-direction: column;
  }
  .left-side-big {
    width: 250px;
    height: auto;
    display: inline-block;
    float: left;
    position: relative;
    top: -100px;
    left: 30px;
  }
  .right-side-big {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-section-big-2 {
    width: auto;
    margin: 0px auto;
    clear: both;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }
  .main-section-big {
    width: auto;
    margin: 140px auto;
    clear: both;
    padding-bottom: 0px;
}
  .right-side-big {
    width: auto;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -50px;
  }
  .left-side-big-2 {
    width: 750px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    padding-left: 20px;
  }
  .left-side-big-2 {
    width: auto;
    display: inline-block;
    vertical-align: top;
    height: auto;
    padding-left: 20px;
    margin-top: 50px;
    margin-left: 0px;
    padding-right: 20px;
}
.bik-wrapper-conatiner-mobile {
  -webkit-text-stroke: 0px #000;
  color: #000;
  font-size: 34px;
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px #b8bfd6;
  top: 20px;
  left: 20px;
  position: relative;
  z-index: 181;
  width: 300px;
}
  .right-side-big-2 {
    width: 250px;
    height: auto;
    display: inline-block;
    position: sticky;
    top: 100px;
    margin-left: 30px;
  }
  .main-section-big-2 {
    width: auto;
    margin: -180px auto;
    clear: both;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 50px;
    padding-top: 50px;
}
  .right-txt-bik-p {
    color: #3d3d3d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.big-img-heading {
  font-size: 2rem;
  color: black;
  font-weight: bold;
  -webkit-text-stroke: 0px white;
}
.custom-container-poi-big {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
}
.pswater-img-left-wrapper {
  width: 370px;
  position: relative;
  left: -5px;
}
.subpageherobackground-mobile-beerinkeg {
  width: 100%;
  height: auto !important;
  padding: 20px;
  border-radius: 35px;
  margin-top: 10px;
}
.poi-kig-new{
  padding-bottom: 150px;
}
}
@media (min-width: 375px) and (max-width: 424px) {
    .main-section-big {
        display: flex;
        flex-direction: column;
      }
      .left-side-big {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: relative;
        top: -100px;
        left: 30px;
      }
      .right-side-big {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
      }
    
      .main-section-big-2 {
        width: auto;
        margin: 0px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
      }
      .main-section-big {
        width: auto;
        margin: 140px auto;
        clear: both;
        padding-bottom: 0px;
    }
      .right-side-big {
        width: auto;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
      }
      .left-side-big-2 {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
      }
      .left-side-big-2 {
        width: auto;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
        margin-top: 50px;
        margin-left: 0px;
        padding-right: 20px;
    }
    .bik-wrapper-conatiner-mobile {
      -webkit-text-stroke: 0px #000;
      color: #000;
      font-size: 34px;
      font-weight: 600;
      line-height: 1.4;
      text-shadow: 1px 1px #b8bfd6;
      top: 20px;
      left: 20px;
      position: relative;
      z-index: 181;
      width: 295px;
  }
      .right-side-big-2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 30px;
      }
      .main-section-big-2 {
        width: auto;
        margin: -180px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
        padding-top: 50px;
    }
      .bik-img-left-wrapper {
        width: 316px;
    }
    .right-txt-bik-p {
      color: #3d3d3d;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
  }
  .big-img-heading {
    font-size: 2rem;
    color: black;
    font-weight: bold;
    -webkit-text-stroke: 0px white;
}
.custom-container-poi-big {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
}
.subpageherobackground-mobile-beerinkeg {
  width: 100%;
  height: auto !important;
  padding: 20px;
  border-radius: 35px;
  margin-top: 10px;
}
.poi-kig-new{
  padding-bottom: 150px;
}
}
@media (min-width: 300px) and (max-width: 374px) {
    .main-section-big {
        display: flex;
        flex-direction: column;
      }
      .left-side-big {
        width: 250px;
        height: auto;
        display: inline-block;
        float: left;
        position: relative;
        top: -100px;
        left: 30px;
      }
      .right-side-big {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
      }
    
      .main-section-big-2 {
        width: auto;
        margin: 0px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
      }
      .main-section-big {
        width: auto;
        margin: 140px auto;
        clear: both;
        padding-bottom: 0px;
    }
      .right-side-big {
        width: auto;
        display: inline-block;
        vertical-align: top;
        float: right;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -50px;
      }
      .left-side-big-2 {
        width: 750px;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
      }
      .left-side-big-2 {
        width: auto;
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-left: 20px;
        margin-top: 50px;
        margin-left: 0px;
        padding-right: 20px;
    }
    .bik-wrapper-conatiner-mobile {
      -webkit-text-stroke: 0px #000;
      color: #000;
      font-size: 34px;
      font-weight: 600;
      line-height: 1.4;
      text-shadow: 1px 1px #b8bfd6;
      top: 20px;
      left: 20px;
      position: relative;
      z-index: 181;
      width: 295px;
  }
      .right-side-big-2 {
        width: 250px;
        height: auto;
        display: inline-block;
        position: sticky;
        top: 100px;
        margin-left: 30px;
      }
      .main-section-big-2 {
        width: auto;
        margin: -180px auto;
        clear: both;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 50px;
        padding-top: 50px;
    }
      .bik-img-left-wrapper {
        width: 260px;
    }
    .right-txt-bik-p {
      color: #3d3d3d;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
  }
  .big-img-heading {
    font-size: 2rem;
    color: black;
    font-weight: bold;
    -webkit-text-stroke: 0px white;
}
.custom-container-poi-big {
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
}
.subpageherobackground-mobile-beerinkeg {
  width: 100%;
  height: auto !important;
  padding: 20px;
  border-radius: 35px;
  margin-top: 10px;
}
.poi-kig-new{
  padding-bottom: 150px;
}
}