.about-people-container{
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding-right: 0px;
}
.left-side-about-people-txt {
    -webkit-text-stroke: 1px #000;
    color: white;
    font-size: 45px;
    font-weight: 600;
    line-height: 1.4;
    text-shadow: 1px 1px #b8bfd6;
}
.about-people-left-side {
    display: flex;
    align-items: center;
    padding: 60px;
    background-color: #01a2c4;
}
.about-people-right-side{
    padding: 0px;
}
.rapid-hash-txt{
    font-size: 21px;
    color: white;
    font-weight: 600;
}

@media (min-width: 1400px) and (max-width: 1440px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 40px;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 0px #000;
        color: white;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
}

@media (min-width: 1280px) and (max-width: 1399px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 30px;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 0px #000;
        color: white;
        font-size: 32px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
}

@media (min-width: 1080px) and (max-width: 1279px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 1px white;
        color: white;
        font-size: 28px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
}
@media (min-width: 1024px) and  (max-width: 1079px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 1px white;
        color: white;
        font-size: 27px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
    }
}

@media (min-width: 810px) and (max-width: 1023px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 20px;
        width: 100%;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 1px black;
        color: white;
        font-size: 38px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding-left: 20px;
        padding-right: 20px;
    }
    .about-people-container-row{
        display: flex;
        flex-direction: column;
    }
    .about-people-right-side {
        width: 100%;
        padding: 0px;
    }
    .rapid-hash-txt {
        font-size: 21px;
        color: white;
        font-weight: 600;
        padding-left: 20px;
    }
}

@media (min-width: 768px) and (max-width: 809px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 20px;
        width: 100%;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 1px black;
        color: white;
        font-size: 36px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding-left: 20px;
        padding-right: 20px;
    }
    .about-people-container-row{
        display: flex;
        flex-direction: column;
    }
    .about-people-right-side {
        width: 100%;
        padding: 0px;
    }
    .rapid-hash-txt {
        font-size: 21px;
        color: white;
        font-weight: 600;
        padding-left: 20px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 20px;
        width: 100%;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 0px white;
        color: white;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding-left: 0px;
        padding-right: 0px;
    }
    .about-people-container-row{
        display: flex;
        flex-direction: column;
    }
    .about-people-right-side {
        width: 100%;
        padding: 0px;
    }
}
@media (min-width: 393px) and  (max-width: 424px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 20px;
        width: 100%;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 0px white;
        color: white;
        font-size: 30px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding-left: 0px;
        padding-right: 0px;
    }
    .about-people-container-row{
        display: flex;
        flex-direction: column;
    }
    .about-people-right-side {
        width: 100%;
        padding: 0px;
    }
}

@media (min-width: 300px) and (max-width: 390px) {
    .about-people-left-side {
        display: flex;
        align-items: center;
        padding: 20px;
        width: 100%;
    }
    .left-side-about-people-txt {
        -webkit-text-stroke: 0px white;
        color: white;
        font-size: 21px;
        font-weight: 600;
        line-height: 1.4;
        text-shadow: 1px 1px #b8bfd6;
        padding-left: 0px;
        padding-right: 0px;
    }
    .about-people-container-row{
        display: flex;
        flex-direction: column;
    }
    .about-people-right-side {
        width: 100%;
        padding: 0px;
    }
}