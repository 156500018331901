.testimonials-container {
  background-image: url("../images/rapid-testimonial-_1_.webp");
  width: 100%;
  height: 75vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  margin-top: 100px;
}
.custom-container-testimonial {
  max-width: 1500px;
}
.testimonial-wrapper {
  display: flex;
  margin-left: 200px;
}
.testimonial-card {
  width: 95%;
  margin-left: 40px;
  height: 370px;
  display: flex;
  justify-content: space-between;
}
.testimonial-img {
  width: 70px;
  height: 70px !important;
  border-radius: 100px;
}
.img-name-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 30px 20px 20px 20px;
}
.name-test {
  margin-left: 10px;
  margin-top: 10px;
  font-size: 21px;
  line-height: 28px;
  color: rgb(61, 61, 61);
}
.description-testimonial {
  padding: 30px 30px 10px 30px;
  font-size: 21px;
  line-height: 28px;
  color: rgb(61, 61, 61);
}
.testimonial-heading {
  font-size: 48px;
  line-height: 57px;
  font-weight: bold;
  color: #2ba4c3;
  -webkit-text-stroke: 0.7px #000;
}

.test-heading {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  margin-top: 150px;
}
.test-arrow {
  width: 20px;
  height: 20px !important;
}
.description-testimonial-company {
  padding: 38px 0px 0px 0px;
  font-size: 24px;
  line-height: 28px;
  color: rgb(61, 61, 61);
  margin-bottom: 0px;
}
.t-m-company {
  width: 90px;
  height: 90px !important;
  padding: 10px;
  margin: 10px;
  border-radius: 60px;
}
.t-m-wrapper-inner {
  display: flex;
}
@media (min-width: 1400px) and (max-width: 1440px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 360px;
    margin-left: 40px;
    height: 460px;
  }
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 20px;
  }
  .description-testimonial {
    padding: 20px;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 75vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    background-position-x: center;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: 290px;
  }
  .description-testimonial-company {
    padding: 30px 0px 0px 0px;
    font-size: 24px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
  }
}
@media (min-width: 1280px) and (max-width: 1399px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 380px;
    margin-left: 10px;
    height: 380px;
  }
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 75vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    background-position: -300px center;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: 350px;
    left: 40px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .description-testimonial-company {
    padding: 40px 0px 0px 0px;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
  }
}
@media (min-width: 1080px) and (max-width: 1279px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 330px;
    margin-left: 10px;
    height: 400px;
  }
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 75vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    background-position: -300px center;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: 350px;
    left: 40px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 6px;
    font-size: 17px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .description-testimonial-company {
    padding: 28px 0px 0px 0px;
    font-size: 24px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1079px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 300px;
    margin-left: 10px;
    height: 400px;
  }
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 75vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    background-position: -300px center;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: 350px;
    left: 40px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 22px;
    font-size: 16px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .description-testimonial-company {
    padding: 30px 30px 0px 20px;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
  }
}
@media (min-width: 810px) and (max-width: 1023px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 370px;
    margin-left: 10px;
    height: 450px;
  }
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 75vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    background-position: -390px center;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: 300px;
    left: 40px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 7px;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
}
.description-testimonial-company {
    padding: 38px 10px 0px 0px;
    font-size: 23px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
}
}

@media (min-width: 768px) and (max-width: 809px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 360px;
    margin-left: 0px;
    height: 440px;
  }
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
}
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 75vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    background-position: -390px center;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: 280px;
    left: 40px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 13px;
    font-size: 18px;
    line-height: 28px;
    color: rgb(61, 61, 61);
}
.description-testimonial-company {
    padding: 38px 10px 0px 0px;
    font-size: 22px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
}
}

@media (min-width: 425px) and (max-width: 767px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 330px;
    margin-left: 0px;
    height: 430px;
}
  .img-name-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 87vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background-position: right;
    background-color: #05b9e3;
    background-image: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: -20px;
    left: 30px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 28px;
    color: rgb(61, 61, 61);
}
   .testimonial-wrapper {
    display: flex;
    margin-left: 0px;
    position: relative;
    left: 35px;
  }
  .custom-row-testimonial {
    flex-direction: column;
  }
  .testimonial-heading {
    font-size: 32px;
    line-height: 1.4;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 0px #000;
  }
  .tetimonial-left-right {
    display: flex;
  }
  .test-arrow {
    filter: invert(1);
  }

  .description-testimonial-company {
    padding: 30px 0px 0px 0px;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
}
}

@media (min-width: 375px) and (max-width: 424px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 330px;
    margin-left: 0px;
    height: 430px;
}
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 87vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background-position: right;
    background-color: #05b9e3;
    background-image: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: -20px;
    left: 10px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 28px;
    color: rgb(61, 61, 61);
}
 
  .testimonial-wrapper {
    display: flex;
    margin-left: -60px;
    position: relative;
    left: 70px;
  }
  .custom-row-testimonial {
    flex-direction: column;
  }
  .testimonial-heading {
    font-size: 32px;
    line-height: 1.4;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 0px #000;
  }
  .tetimonial-left-right {
    display: flex;
  }
  .test-arrow {
    filter: invert(1);
  }

}
@media (min-width: 360px) and (max-width: 374px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 330px;
    margin-left: 4px;
    height: 430px;
}
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 87vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background-position: right;
    background-color: #05b9e3;
    background-image: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: -20px;
    left: 0px;
  }
  .name-test {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 18px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }

  .testimonial-wrapper {
    display: flex;
    margin-left: -60px;
    position: relative;
    left: 60px;
  }
  .custom-row-testimonial {
    flex-direction: column;
  }
  .testimonial-heading {
    font-size: 48px;
    line-height: 1.4;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 0.7px #000;
  }
  .tetimonial-left-right {
    display: flex;
  }
  .test-arrow {
    filter: invert(1);
  }

  .testimonial-heading {
    font-size: 32px;
    line-height: 1.4;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 0px #000;
  }
}
@media (min-width: 300px) and (max-width: 359px) {
  .custom-container-testimonial {
    max-width: 1250px;
  }
  .testimonial-card {
    width: 300px;
    margin-left: 0px;
    height: 430px;
}
  .img-name-wrapper {
    display: flex;
    justify-content: start;
    padding: 10px;
  }
  .description-testimonial {
    padding: 20px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
  .testimonials-container {
    width: 100%;
    height: 87vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background-position: right;
    background-color: #05b9e3;
    background-image: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .test-heading {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    position: relative;
    top: -20px;
    left: 0px;
  }
  .name-test {
    margin-left: 0px;
    margin-top: 10px;
    font-size: 15px;
    line-height: 28px;
    color: rgb(61, 61, 61);
  }
 
  .testimonial-wrapper {
    display: flex;
    margin-left: -60px;
    position: relative;
    left: 57px;
  }
  .custom-row-testimonial {
    flex-direction: column;
  }
  .testimonial-heading {
    font-size: 48px;
    line-height: 1.4;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 0.7px #000;
  }
  .tetimonial-left-right {
    display: flex;
  }
  .test-arrow {
    filter: invert(1);
  }

  .testimonial-heading {
    font-size: 32px;
    line-height: 1.4;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 0px #000;
  }
  .description-testimonial-company {
    padding: 30px 0px 0px 0px;
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    margin-bottom: 0px;
}
}
