.hotspot-swiper-container {
    height: 100vh;
    background-color: #dde1ed8f;
    max-width: 1920px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.beer-container {
    display: none;
    max-width: 1550px;
}

.beer-container.active {
    display: block;
}

.heading-txt-wrapper {
    display: flex;

}

.upper-swiper-wrapper {
    display: flex;
    margin-left: 521px;
    cursor: pointer;
}

.back-to-map-img {
    height: 15px !important;
    width: auto;
    margin-top: 27px;
    margin-right: 10px;
}

.return-txt-p {
    font-size: 21px;
    line-height: 28px;
    color: #31a6c4;
    font-weight: 400;
    padding-top: 20px;
}

.hotspot-heading {
    font-size: 48px;
    line-height: 57px;
    font-weight: bold;
    color: #31a6c4;
    -webkit-text-stroke: 0.7px black;
}

.shrink-packing-img {
    width: 600px;
    aspect-ratio: auto 600 / 361;
    height: 361px !important;
    margin-top: 40px;
}

.right-swipe-txt {
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
}

.img-txt-row {
    padding-top: 80px;
}

.right-div {
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.swipe-to-left-img {
    width: 20px;
    height: 20px !important;
}

.swipe-to-right-img {
    width: 20px;
    height: 20px !important;
}

.swipe-to-left-img-div {
    border: 0.5px solid black;
    padding: 20px;
    margin-right: 1px;
}

.swipe-to-right-img-div {
    border: 0.5px solid black;
    padding: 20px;
}

.swiper-control-arrow {
    display: flex;
    margin-left: 192px;
    margin-top: 100px;
}

.swiper-counter {
    display: flex;
    justify-content: center;
    padding-top: 90px;
}

.swiper-counter p {
    padding: 0px 48px;
    cursor: pointer;
    color: #31a6c4;
}
.swiper-counter p.active{
    font-weight: bold;
}
.hotspot-swiper-th{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 45px 5px 45px;
}
.hotspot-swiper-th-width{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 30px 5px 30px;
}
.hotspot-swiper-td{
    font-size: 18px;
    background-color: white;
    padding: 10px 35px 10px 35px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-table{
    margin: 40px 0px;
}
.list-point-of-interest{
  display: flex;
}
.point-of-your-interest{
    margin-bottom: 50px;
}
.list-poi-txt{
    font-size: 21px;
    line-height: 28px;
    color: rgb(61, 61, 61);
    font-weight: 400;
}
.bold-content{
    color: #31a6c4;
}
.p-o-i-h3{
    margin-top: 40px;
    margin-bottom: 20px;
    color: #31a6c4;
}
.hotspot-heading-right{
    font-size: 48px;
    line-height: 57px;
    font-weight: bold;
    color: #31a6c4;
    -webkit-text-stroke: 0.7px black;
    margin-bottom: 30px;
}
.tr-head{
    display: flex;
}
.tr-body{
    display: flex;
}
.tr-body-div{
    padding: 2px
}
.tr-head-div{
    margin-right: 4px;
    margin-bottom: 2px;

}
.hotspot-swiper-td-first{
    font-size: 18px;
    background-color: white;
    padding: 10px 79px 10px 79px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-first {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-th-model{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 76px 5px 76px ;
}
.hotspot-swiper-th-cycles{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 35px 5px 36px;
}
.hotspot-swiper-th-pl{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 40px 5px 40px;
}
.hotspot-swiper-td-RAPIDshrink{
    font-size: 18px;
    background-color: white;
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-RAPIDshrink {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-td-cycles {
    font-size: 18px;
    background-color: white;
    padding: 10px 80px 10px 90px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-cycles {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-td-pl{
    font-size: 18px;
    background-color: white;
    padding: 10px 70px 10px 70px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-pl {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-th-model-fs{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 47px 5px 47px;
}
.hotspot-swiper-th-fv {
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 38px 5px 38px;
}
.hotspot-swiper-th-rs{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    display: flex;
    padding: 5px 35px 5px 35px;
}
.hotspot-swiper-td-RAPIDfill{
    font-size: 18px;
    background-color: white;
    padding: 10px 16px 10px 16px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-RAPIDfill {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-td-fl{
    font-size: 18px;
    background-color: white;
    padding: 10px 95px 10px 95px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-fl {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-td-rs{
    font-size: 18px;
    background-color: white;
    padding: 10px 118px 10px 118px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-rs{
    background-color: #31a6c4;
    color: white;
}
.special-size-ml{
    font-size: 20px;
    display: contents;
}
.p-122{
    font-size: 18px;
    background-color: white;
    padding: 10px 123px 10px 123px;
    border-radius: 10px;
}
.hotspot-swiper-th-model-fs-2{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 56px 5px 56px;
}
.hotspot-swiper-th-fv-2{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    padding: 5px 35px 5px 35px;
}
.hotspot-swiper-th-rs-2{
    font-size: 24px;
    color: white;
    background-color: #31a6c4;
    border-radius: 10px;
    display: flex;
    padding: 5px 33px 5px 33px;
}
.hotspot-swiper-td-RAPIDfill-2{
    font-size: 18px;
    background-color: white;
    padding: 10px 25px 10px 25px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-RAPIDfill-2 {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-td-fl-2{
    font-size: 18px;
    background-color: white;
    padding: 10px 93px 10px 93px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-fl-2 {
    background-color: #31a6c4;
    color: white;
}
.hotspot-swiper-td-rs-2{
    font-size: 18px;
    background-color: white;
    padding: 10px 70px 10px 70px;
    border-radius: 10px;
}
.tr-body:hover .hotspot-swiper-td-rs-2 {
    background-color: #31a6c4;
    color: white;
}
.simik-logo{
    height: auto !important;
    width: auto;
}
.animated-delay{
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}
.animated {
    animation-duration: 1.3s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1.3s;
    -webkit-animation-fill-mode: both
}
.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
.animatedFadeInUp {
    opacity: 0
}
@keyframes fadeInUp {
    from {
        transform: translate3d(0,100px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,100px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}
.hotspot-img-in-out {
    animation: zoom-out 1.3s ease-out forwards;
   
  }
  
  @keyframes zoom-out {
    from {
         transform: scale(1.1);
         opacity: 0;
    }
    to {
         transform: scale(1);
         width: 80%;
         opacity: 1;
    }
}

@media (min-width: 1400px) and (max-width: 1440px) {
    .swiper-counter {
        display: flex;
        justify-content: center;
        padding-top: 90px;
    }
    .img-txt-row {
        padding-top: 20px;
    }
    .hotspot-swiper-container {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 50px;
        padding-bottom: 100px;
    }
    .shrink-packing-img {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper {
        display: flex;
        margin-left: 420px;
        cursor: pointer;
    }
    .hotspot-heading {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
    }
    .swiper-counter p {
        padding: 0px 32px;
        cursor: pointer;
        color: #31a6c4;
    }
}
@media (min-width: 1300px) and (max-width: 1399px) {
    .swiper-counter {
        display: flex;
        justify-content: center;
        padding-top: 90px;
    }
    .img-txt-row {
        padding-top: 20px;
    }
    .hotspot-swiper-container {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 50px;
        padding-bottom: 100px;
    }
    .shrink-packing-img {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper {
        display: flex;
        margin-left: 350px;
        cursor: pointer;
    }
    .hotspot-heading {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
    }
    .swiper-counter p {
        padding: 0px 26px;
        cursor: pointer;
        color: #31a6c4;
    }
    .hotspot-swiper-th-model {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 75px 5px 75px;
    }
    .hotspot-swiper-th-pl {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 24px 5px 24px;
    }
    .hotspot-swiper-td-pl {
        font-size: 18px;
        background-color: white;
        padding: 10px 55px 10px 55px;
        border-radius: 10px;
    }
    .p-122 {
        font-size: 18px;
        background-color: white;
        padding: 10px 100px 10px 100px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 18px;
        background-color: white;
        padding: 10px 98px 10px 98px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 22px 47px 22px 47px;
    }
    .hotspot-swiper-td-fl {
        font-size: 18px;
        background-color: white;
        padding: 10px 88px 10px 88px;
        border-radius: 10px;
    }
}
@media (min-width: 1280px) and (max-width: 1299px) {
    .swiper-counter {
        display: flex;
        justify-content: center;
        padding-top: 40px;
    }
    .img-txt-row {
        padding-top: 0px;
    }
    .hotspot-swiper-container {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 10px;
        padding-bottom: 100px;
    }
    .shrink-packing-img {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper {
        display: flex;
        margin-left: 338px;
        cursor: pointer;
    }
    .hotspot-heading {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
        margin-top: 20px;
    }
    .swiper-counter p {
        padding: 0px 12px;
        cursor: pointer;
        color: #31a6c4;
    }
    .hotspot-swiper-th-cycles {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 23px 20px 23px 20px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 18px;
        background-color: white;
        padding: 10px 65px 10px 65px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 18px;
        background-color: white;
        padding: 10px 40px 10px 40px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 30px 5px 30px;
    }
    .hotspot-swiper-th-model {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 23px 55px 23px 55px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 18px;
        background-color: white;
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl {
        font-size: 18px;
        background-color: white;
        padding: 10px 55px 10px 56px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-fv {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 21px 5px 21px;
    }
    .p-122 {
        font-size: 18px;
        background-color: white;
        padding: 10px 85px 10px 85px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 18px;
        background-color: white;
        padding: 10px 80px 10px 80px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 22px 47px 22px 47px;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 85px 10px 85px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 15px 5px 15px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 18px;
        background-color: white;
        padding: 10px 64px 10px 64px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 18px;
        background-color: white;
        padding: 10px 60px 10px 60px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 23px 56px 23px 56px;
    }
    /* --------------- special for tab view ----------------- */
    .hotspot-swiper-container {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 30px;
        padding-bottom: 0px;
    }
    @keyframes zoom-out {
        from {
             transform: scale(0.7);
             opacity: 0;
        }
        to {
             transform: scale(1);
             width: 80%;
             opacity: 1;
        }
    }
}
@media (min-width: 1024px) and (max-width: 1279px) {
    .swiper-counter {
        display: flex;
        justify-content: center;
        padding-top: 40px;
    }
    .img-txt-row {
        padding-top: 0px;
    }
    .hotspot-swiper-container {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 10px;
        padding-bottom: 100px;
    }
    .shrink-packing-img {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 75px;
        margin-left: 50px;
    }
    .upper-swiper-wrapper {
        display: flex;
        margin-left: 240px;
        cursor: pointer;
    }
    .hotspot-heading {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 53px;
        margin-top: 20px;
    }
    .swiper-counter p {
        padding: 0px 12px;
        cursor: pointer;
        color: #31a6c4;
    }
    .hotspot-swiper-th-cycles {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 23px 20px 23px 20px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 18px;
        background-color: white;
        padding: 10px 65px 10px 65px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 18px;
        background-color: white;
        padding: 10px 40px 10px 40px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 30px 5px 30px;
    }
    .hotspot-swiper-th-model {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 23px 55px 23px 55px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 18px;
        background-color: white;
        padding: 10px 20px 10px 20px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl {
        font-size: 18px;
        background-color: white;
        padding: 10px 55px 10px 56px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-fv {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 21px 5px 21px;
    }
    .p-122 {
        font-size: 18px;
        background-color: white;
        padding: 10px 85px 10px 85px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 18px;
        background-color: white;
        padding: 10px 80px 10px 80px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 22px 47px 22px 47px;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 85px 10px 85px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 15px 5px 15px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 18px;
        background-color: white;
        padding: 10px 64px 10px 64px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 18px;
        background-color: white;
        padding: 10px 60px 10px 60px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 24px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 23px 56px 23px 56px;
    }
    /* --------------- special for tab view ----------------- */
    .hotspot-swiper-container {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 90px;
        padding-bottom: 0px;
    }
    @keyframes zoom-out {
        from {
             transform: scale(0.7);
             opacity: 0;
        }
        to {
             transform: scale(1);
             width: 80%;
             opacity: 1;
        }
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    @keyframes zoom-out {
        from {
             transform: scale(0.2);
             opacity: 0;
        }
        to {
             transform: scale(1);
             width: 80%;
             opacity: 1;
        }
    }
   
    .upper-swiper-wrapper {
        display: flex;
        margin-left: 135px;
        cursor: pointer;
    }
    .shrink-packing-img {
        width: 600px;
        aspect-ratio: auto 600 / 361;
        height: 361px !important;
        margin-top: 70px;
        margin-left: 70px;
    }
    .img-txt-row {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
    }
    .left-side-img-bic {
        width: 100%;
        margin-top: -70px;
    }
    .right-div {
        max-height: 350px;
        overflow-y: scroll;
        scrollbar-width: thin;
        width: 100%;
        padding-left: 80px;
        padding-right: 80px;
        margin-top: 30px;
    }
    .beer-container.active {
        display: grid;
        align-items: center;
        padding-top: 145px;
    }
    .hotspot-swiper-container {
        height: 100vh;
        background-color: #dde1ed8f;
        max-width: 1920px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow-y: hidden !important;
    }
    .swiper-counter p {
        padding: 0px 10px;
        cursor: pointer;
        color: #31a6c4;
        font-size: 12px;
    }
    .hotspot-heading {
        font-size: 48px;
        line-height: 57px;
        font-weight: bold;
        color: #31a6c4;
        -webkit-text-stroke: 0.7px black;
        margin-left: 70px;
    }
    .hotspot-swiper-th-model {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 19px 44px 19px 44px;
    }
    .hotspot-swiper-th-cycles {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 20px 10px 20px 10px;
    }
    .hotspot-swiper-th-pl {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 20px 10px 20px 10px;
    }
    .hotspot-swiper-td-RAPIDshrink {
        font-size: 16px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-cycles {
        font-size: 16px;
        background-color: white;
        padding: 10px 50px 10px 50px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-pl {
        font-size: 16px;
        background-color: white;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
    }
    .hotspot-swiper-th {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 45px 5px 45px;
    }
    .hotspot-swiper-th-width {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 33px 5px 33px;
    }
    .hotspot-swiper-td-first {
        font-size: 18px;
        background-color: white;
        padding: 10px 63px 10px 63px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-fv {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 20px 10px 20px 10px;
    }
    .hotspot-swiper-th-rs {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 20px 10px 20px 10px;
    }
    .hotspot-swiper-td-fl {
        font-size: 16px;
        background-color: white;
        padding: 10px 59px 10px 59px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs {
        font-size: 18px;
        background-color: white;
        padding: 10px 80px 10px 80px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-RAPIDfill {
        font-size: 16px;
        background-color: white;
        padding: 10px 16px 10px 16px;
        border-radius: 10px;
    }
    .hotspot-swiper-th-model-fs {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 20px 47px 20px 47px;
    }
    td.hotspot-swiper-td-rs.p-122 {
        padding: 10px 85px 10px 85px;
    }
    .hotspot-swiper-th-model-fs-2 {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 30px 5px 30px;
    }
    .hotspot-swiper-th-fv-2 {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
    }
    .hotspot-swiper-th-rs-2 {
        font-size: 20px;
        color: white;
        background-color: #31a6c4;
        border-radius: 10px;
        display: flex;
        padding: 5px 10px 5px 10px;
    }
    .hotspot-swiper-td-RAPIDfill-2 {
        font-size: 16px;
        background-color: white;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-fl-2 {
        font-size: 16px;
        background-color: white;
        padding: 10px 50px 10px 50px;
        border-radius: 10px;
    }
    .hotspot-swiper-td-rs-2 {
        font-size: 16px;
        background-color: white;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
    }
    .heading-txt-wrapper {
        display: flex;
        position: relative;
        top: -30px;
    }
    .swiper-counter {
        display: flex;
        justify-content: center;
        padding-top: 30px;
    }
}