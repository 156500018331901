.highlight-header-container{
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    background-color: transparent;
    align-items: center;
    transition: all ease-in-out 0.4s;
    width: 100%;
    z-index: 111;
}
.highlight-header-container.scrolled {
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    background-color: white;
    position: fixed;
    transition: all ease-in-out 0.4s;
    width: 100%;
    top: 0px;
}
.hamburger-menu-hl-wrapper{
    width: 35px;
    height: 35px;
    position: relative;
    right: 154px;
}
.hamburger-menu-hl-wrapper.scrolled {
    width: 35px;
    height: 35px;
    position: relative;
    right: 130px;
}
.rapid-hight-light-logo-wrapper {
    width: 360px;
    padding: 20px 0px 20px 0px;
    position: relative;
    /* left: 94px; */
    left: 0px;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
}
.rapid-hight-light-logo-wrapper.scrolled {
    width: 260px;
    padding: 20px 0px 20px 0px;
    position: relative;
    left: 0px;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
}
.custom-header-container {
    max-width: 100%;
    width: 100%;
    padding: 0px;
}
.custom-header-container.scrolled {
    max-width: 100%;
    background-color: transparent;
}

@media (min-width: 1080px) and (max-width: 1399px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 300px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    } 
    .hamburger-menu-hl-wrapper {
        width: 35px;
        height: 35px;
        position: relative;
        right: 40px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 35px;
        height: 35px;
        position: relative;
        right: 30px;
    }
}
@media (min-width: 1024px) and (max-width: 1079px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 300px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper {
        width: 35px;
        height: 35px;
        position: relative;
        right: 30px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 35px;
        height: 35px;
        position: relative;
        right: 30px;
    }
}
@media (min-width: 810px) and (max-width: 1023px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 300px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    } 
    .hamburger-menu-hl-wrapper {
        width: 35px;
        height: 35px;
        position: relative;
        right: 30px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 35px;
        height: 35px;
        position: relative;
        right: 30px;
    }
}
@media (min-width: 768px) and (max-width: 809px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 300px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    } 
    .hamburger-menu-hl-wrapper {
        width: 35px;
        height: 35px;
        position: relative;
        right: 30px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 20px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 35px;
        height: 35px;
        position: relative;
        right: 30px;
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 250px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper {
        width: 30px;
        height: 30px;
        position: relative;
        right: 34px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 30px;
        height: 30px;
        position: relative;
        right: 30px;
    }
}

@media (min-width: 393px) and (max-width: 424px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 250px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper {
        width: 30px;
        height: 30px;
        position: relative;
        right: 34px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 30px;
        height: 30px;
        position: relative;
        right: 30px;
    }
}
@media (min-width: 375px) and (max-width: 392px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 250px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper {
        width: 30px;
        height: 30px;
        position: relative;
        right: 34px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 30px;
        height: 30px;
        position: relative;
        right: 30px;
    }
}
@media (min-width: 320px) and (max-width: 364px) {
    .highlight-header-container {
        display: flex;
        justify-content: center;
        background-color: white;
        align-items: center;
        transition: all ease-in-out 0.4s;
        width: 100%;
        z-index: 111;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .rapid-hight-light-logo-wrapper {
        width: 250px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper {
        width: 30px;
        height: 30px;
        position: relative;
        right: 34px;
    }
    .rapid-hight-light-logo-wrapper.scrolled {
        width: 200px;
        padding: 20px 0px 20px 0px;
        position: relative;
        left: 10px;
        transition: all ease-in-out 0.4s;
    }
    .hamburger-menu-hl-wrapper.scrolled {
        width: 30px;
        height: 30px;
        position: relative;
        right: 30px;
    }
}