.left-side-csd {
    width: 250px;
    height: auto;
    display: inline-block;
    float: left;
    position: sticky;
    top: 130px;
  }
  
  .main-section-csd {
    width: 1100px;
    margin: 50px auto;
    clear: both;
    padding-bottom: 100px;
  }
  
  .main-section-csd:before,
  .main-section-csd:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .right-side-csd {
    width: 700px;
    display: inline-block;
    vertical-align: top;
    float: right;
    height: auto;
  }

  .main-section-csd2 {
    width: 1200px;
    margin: 50px auto;
    clear: both;
  }
  .left-side-csd2 {
    width: 700px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    margin-left: 50px;

  }
  .main-section-csd2:before,
  .main-section-csd2:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .right-side-csd2 {
    width: 250px;
    height: auto;
    display: inline-block;
    position: sticky;
    top: 130px;
    margin-left: 100px;
  }
.pscsd-img-left-wrapper{
  width: 340px;
}
  @media (min-width: 1080px) and (max-width: 1399px) {
    .main-section-csd {
      width: 1100px;
      margin: 50px auto;
      clear: both;
      padding-bottom: 0px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -70px;
    }
    .main-section-csd2 {
      width: 1100px;
      margin: 50px auto;
      clear: both;
      padding-left: 20px;
      padding-right: 20px;
    }
    .right-side-csd2 {
      width: 250px;
      height: auto;
      display: inline-block;
      position: sticky;
      top: 100px;
      margin-left: 40px;
    }
    .right-side-csd {
      width: 670px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-right: 20px;
  }
  .left-side-csd2 {
    width: 670px;
    display: inline-block;
    vertical-align: top;
    height: auto;
    margin-left: 0px;
}
  }
  
  @media (min-width: 1024px) and (max-width: 1079px) {
    .main-section-csd {
      width: 1100px;
      margin: 50px auto;
      clear: both;
      padding-bottom: 0px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -70px;
    }
    .main-section-csd2 {
      width: 1100px;
      margin: 50px auto;
      clear: both;
      padding-left: 20px;
      padding-right: 20px;
    }
    .right-side-csd2 {
      width: 250px;
      height: auto;
      display: inline-block;
      position: sticky;
      top: 100px;
      margin-left: 0px;
  }
    .right-side-csd {
      width: 700px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
    }
    .right-side-csd {
      width: 700px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-right: 90px;
    }
    .left-side-csd {
      width: 250px;
      height: auto;
      display: inline-block;
      float: left;
      position: sticky;
      top: 100px;
      padding-left: 10px;
    }
  
    .left-side-csd2 {
      width: 650px;
      display: inline-block;
      vertical-align: top;
      height: auto;
      margin-left: 0px;
  }
  }
  @media (min-width: 810px) and (max-width: 1023px) {
    .main-section-csd {
      display: flex;
      flex-direction: column;
    }
    .left-side-csd {
      width: 250px;
      height: auto;
      display: inline-block;
      float: left;
      position: relative;
      top: -100px;
      left: 30px;
    }
    .right-side-csd {
      width: 750px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .main-section-csd2 {
      width: auto;
      margin: 0px auto;
      clear: both;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 50px;
    }
    .main-section-csd {
      width: auto;
      margin: 50px auto;
      clear: both;
      padding-bottom: 0px;
    }
    .right-side-csd {
      width: 750px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -50px;
    }
   
    .left-side-csd2 {
      width: 750px;
      display: inline-block;
      vertical-align: top;
      height: auto;
      padding-left: 20px;
      margin-top: 50px;
      margin-left: 0px;
      padding-right: 20px;
  }
    .right-side-csd2 {
      width: 250px;
      height: auto;
      display: inline-block;
      /* float: left; */
      position: relative;
      top: -20px;
      margin-left: 30px;
  }
  }
  @media (min-width: 768px) and (max-width: 809px) {
    .main-section-csd {
      display: flex;
      flex-direction: column;
    }
    .left-side-csd {
      width: 250px;
      height: auto;
      display: inline-block;
      float: left;
      position: relative;
      top: -100px;
      left: 30px;
    }
    .right-side-csd {
      width: 750px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .main-section-csd2 {
      width: auto;
      margin: 0px auto;
      clear: both;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 50px;
    }
    .main-section-csd {
      width: auto;
      margin: 50px auto;
      clear: both;
      padding-bottom: 0px;
    }
    .right-side-csd {
      width: 750px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -50px;
    }
   
    .left-side-csd2 {
      width: 750px;
      display: inline-block;
      vertical-align: top;
      height: auto;
      padding-left: 20px;
      margin-top: 50px;
      margin-left: 0px;
      padding-right: 20px;
    }
    .right-side-csd2 {
      width: 250px;
      height: auto;
      display: inline-block;
      /* float: left; */
      position: relative;
      top: -20px;
      margin-left: 30px;
  }
  }
  @media (min-width: 425px) and (max-width: 767px) {
    .main-section-csd {
      display: flex;
      flex-direction: column;
    }
    .left-side-csd {
      width: 250px;
      height: auto;
      display: inline-block;
      float: left;
      position: relative;
      top: -100px;
      left: 30px;
    }
    .right-side-csd {
      width: 750px;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .main-section-csd2 {
      width: auto;
      margin: 0px auto;
      clear: both;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 50px;
    }
    .main-section-csd {
      width: auto;
      margin: 110px auto;
      clear: both;
      padding-bottom: 0px;
  }
  .ps-csd-new {
    width: 100%;
    height: auto !important;
    padding: 20px;
    border-radius: 35px;
    margin-top: 10px;
}
    .right-side-csd {
      width: auto;
      display: inline-block;
      vertical-align: top;
      float: right;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -50px;
    }
  
    .left-side-csd2 {
      width: auto;
      display: inline-block;
      vertical-align: top;
      height: auto;
      padding-left: 20px;
      margin-top: 50px;
      margin-left: 0px;
      padding-right: 20px;
    }
    /* .bik-wrapper-conatiner-mobile {
      -webkit-text-stroke: 1px #000;
      color: #fff;
      font-size: 3rem;
      font-weight: 600;
      line-height: 1.4;
      text-shadow: 1px 1px #b8bfd6;
      top: -210px;
      left: 40px;
      position: relative;
      z-index: 181;
      width: 300px;
  } */
    .right-side-csd2 {
      width: 250px;
      height: auto;
      display: inline-block;
      position: sticky;
      top: 100px;
      margin-left: 30px;
    }
    .main-section-csd2 {
      width: auto;
      margin: -150px auto;
      clear: both;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 50px;
      padding-top: 50px;
  }
    .third-section-pscsd {
      margin-top: 100px;
  }
  .new-poi-ps-csd{
    padding-bottom: 130px;
  }
  }
  @media (min-width: 375px) and (max-width: 424px) {
      .main-section-csd {
          display: flex;
          flex-direction: column;
        }
        .left-side-csd {
          width: 250px;
          height: auto;
          display: inline-block;
          float: left;
          position: relative;
          top: -100px;
          left: 0px;
      }
        .right-side-csd {
          width: 750px;
          display: inline-block;
          vertical-align: top;
          float: right;
          height: auto;
          padding-left: 20px;
          padding-right: 20px;
        }
      
        .main-section-csd2 {
          width: auto;
          margin: 0px auto;
          clear: both;
          display: flex;
          flex-direction: column-reverse;
          padding-bottom: 50px;
        }
        .main-section-csd {
          width: auto;
          margin: 100px auto;
          clear: both;
          padding-bottom: 0px;
      }
        .right-side-csd {
          width: auto;
          display: inline-block;
          vertical-align: top;
          float: right;
          height: auto;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: -50px;
        }
     
        .left-side-csd2 {
          width: auto;
          display: inline-block;
          vertical-align: top;
          height: auto;
          padding-left: 20px;
          margin-top: 50px;
          margin-left: 0px;
          padding-right: 20px;
        }
        /* .bik-wrapper-conatiner-mobile {
          -webkit-text-stroke: 1px #000;
          color: #fff;
          font-size: 3rem;
          font-weight: 600;
          line-height: 1.4;
          text-shadow: 1px 1px #b8bfd6;
          top: -240px;
          left: 40px;
          position: relative;
          z-index: 181;
          width: 315px;
      } */
        .right-side-csd2 {
          width: 250px;
          height: auto;
          display: inline-block;
          position: sticky;
          top: 100px;
          margin-left: 15px;
        }
        .main-section-csd2 {
          width: auto;
          margin: -150px auto;
          clear: both;
          display: flex;
          flex-direction: column-reverse;
          padding-bottom: 50px;
          padding-top: 50px;
      }
        .third-section-pscsd {
          margin-top: 100px;
      }
      .ps-csd-new {
        width: 100%;
        height: auto !important;
        padding: 20px;
        border-radius: 35px;
        margin-top: 10px;
    }
    .new-poi-ps-csd{
      padding-bottom: 130px;
    }
  }
  @media (min-width: 300px) and (max-width: 374px) {
      .main-section-csd {
          display: flex;
          flex-direction: column;
        }
        .left-side-csd {
          width: 250px;
          height: auto;
          display: inline-block;
          float: left;
          position: relative;
          top: -100px;
          left: 0px;
        }
        .right-side-csd {
          width: 750px;
          display: inline-block;
          vertical-align: top;
          float: right;
          height: auto;
          padding-left: 20px;
          padding-right: 20px;
        }
      
        .main-section-csd2 {
          width: auto;
          margin: 0px auto;
          clear: both;
          display: flex;
          flex-direction: column-reverse;
          padding-bottom: 50px;
        }
        .main-section-csd {
          width: auto;
          margin: 100px auto;
          clear: both;
          padding-bottom: 0px;
      }
        .right-side-csd {
          width: auto;
          display: inline-block;
          vertical-align: top;
          float: right;
          height: auto;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: -50px;
        }
     
        .left-side-csd2 {
          width: auto;
          display: inline-block;
          vertical-align: top;
          height: auto;
          padding-left: 20px;
          margin-top: 50px;
          margin-left: 0px;
          padding-right: 20px;
        }
        /* .bik-wrapper-conatiner-mobile {
          -webkit-text-stroke: 1px #000;
          color: #fff;
          font-size: 3rem;
          font-weight: 600;
          line-height: 1.4;
          text-shadow: 1px 1px #b8bfd6;
          top: -248px;
          left: 40px;
          position: relative;
          z-index: 181;
          width: 275px;
      } */
        .right-side-csd2 {
          width: 250px;
          height: auto;
          display: inline-block;
          position: sticky;
          top: 100px;
          margin-left: 30px;
        }
        .main-section-csd2 {
          width: auto;
          margin: -150px auto;
          clear: both;
          display: flex;
          flex-direction: column-reverse;
          padding-bottom: 50px;
          padding-top: 50px;
      }
        .third-section-pscsd {
          margin-top: 100px;
      }
      .pscsd-img-left-wrapper {
        width: 270px;
    }
    .ps-csd-new {
      width: 100%;
      height: auto !important;
      padding: 20px;
      border-radius: 35px;
      margin-top: 10px;
  }
  .new-poi-ps-csd{
    padding-bottom: 130px;
  }
  }